import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthState} from '../models/signin';
import {SkvData} from '../models/skv-data';
import {Lead, LinkUser} from '../models/user';
import {CustomerDisplay} from "../models/customer-display";
import {VenueConfigSchema} from "../models/venue-config-schema";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
  ) { }

  private post<T>(body, headers?: any): Observable<T> {
    const base_url = environment.base_url;
    const endpointUrl = "api/adm/exp";
    const url = `${base_url}/${endpointUrl}`;
    return this.http.post<T>(url, body, {headers});
  }

  public getUserByLinkToken(linkToken: string): Observable<LinkUser> {
    const body = {link_token: linkToken, action: "get_user_by_link_token"};
    return this.post<LinkUser>(body);
  }

  public linkAccount(linkToken: string, token: string): Observable<LinkUser> {
    const headers = new HttpHeaders().set("Authorization", token);
    const body = {link_token: linkToken, action: "link_account"};
    return this.post<LinkUser>(body, headers);
  }

  public setupUserAccount(token: string): Observable<AuthState> {
    const headers = new HttpHeaders().set("Authorization", token);
    const body = {action: "signin_user"};
    return this.post<AuthState>(body, headers);
  }

  public async newLead(email: string): Promise<any> {
    const body = {email, action: "new_lead"};
    return this.post<any>(body).toPromise();
  }

  public async fetchLead(leadKey: string): Promise<Lead> {
    const body = {lead_key: leadKey, action: "fetch_lead"};
    return this.post<any>(body).toPromise();
  }

  public async updateLead(name: string, userName: string, phone: string, template: string, leadKey: string): Promise<any> {
    const body = {lead_key: leadKey, name, user_name: userName, phone, template, action: "update_lead"};
    return this.post<any>(body).toPromise();
  }

  public createLeadAccount(leadKey: string, token: string): Observable<LinkUser> {
    const body = {action: "create_lead_account", lead_key: leadKey, token};
    return this.post<LinkUser>(body);
  }

  public fetchConfigStructure(): Promise<VenueConfigSchema> {
    const body = {action: "fetch_config_structure"};
    return this.post<VenueConfigSchema>(body).toPromise();
  }

  public fetchSkvData(venueId: number): Promise<SkvData> {
    const body = {venue_id: venueId, action: "fetch_skv_data"};
    return this.post<SkvData>(body).toPromise();
  }

  public signCustomerDisplay(venueId: number, identity: string): Observable<CustomerDisplay> {
    //const headers = new HttpHeaders().set("Authorization", token);
    const body = {action: "signin_customer_display", identity, venue_id: venueId};
    return this.post<CustomerDisplay>(body);
  }

  public async subscribeNewsletter(email: string, list: string): Promise<any> {
    const body = {action: "subscribe_newsletter", email, list};
    return this.post<any>(body).toPromise();
  }

  public async unSubscribeNewsletter(unsubToken: string): Promise<any> {
    const body = {action: "unsubscribe_newsletter", unsub_token: unsubToken};
    return this.post<any>(body).toPromise();
  }

}
