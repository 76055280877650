import { NodeUtils } from "src/app/utils/utils";

export const pageAccounts = {
  title: {sv: "Kontoplan"},
  page_settings: {
    root_path: "reporting"
  },
  desc: {sv: "Ställ in kontoplan"},
  properties: [
    {key: "groups", title: {sv: "Kontoplan försäljning och betalmedel"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        {key: "class", title: {sv: "Klass"}, type: "ic_pm_vat", width: 200 },
        {key: "mews_class", title: {sv: "Mews-klass"}, type: "mews_class_enum", width: 100, optional: true, configCondition: (config): boolean => {
          return !NodeUtils.isNullOrEmpty(config?.mews);
        }},
        {key: "hotsoft_class", title: {sv: "Hotsoft-account"}, type: "string", width: 100, optional: true, configCondition: (config): boolean => {
            return !NodeUtils.isNullOrEmpty(config?.hotsoft);
        }},
        {key: "account", title: {sv: "Konto"}, type: "integer", width: 100 },
        {key: "cat_vat", title: {sv: "Moms"}, type: "enum", width: 100, source: momsDropdown() },
        {key: "tax_code", title: {sv: "Tax-code"}, width: 100, type: "config_enum", config_path: "locale.tax_codes", config_key: "code", hidden: true },
        {key: "exclude_from_booking", title: {sv: "Exclude"}, type: "bool", width: 100 },
        {key: "exclude_vat_from_booking", title: {sv: "Ex. Vat"}, type: "bool", width: 100 },
      ]
    },
    {header: "Inställningar för X/Z/S-rapport"},
    {key: "events_in_zreport", title: {sv: "Summera händelser"}, desc: {sv: "Visa en summering av händelser på z-rapporten, syns ej på x-rapport."}, type: "bool", optional: true},
    {key: "cash_minus_extra_in_sreport", title: {sv: "Visa kontanter minus dricks"}, desc: {sv: "Visar beloppet av total kontant försäljning för en personal under sitt pass minus dricks"}, type: "bool", optional: true},
    {key: "discounts_in_zreport", title: {sv: "Bokför rabatter"}, desc: {sv: "Bokför rabatter separat"}, type: "bool", optional: true},
    {header: "Bokföringsinställningar"},
    {key: "inception_date", title: {sv: "Startdatum"}, desc: {sv: ""}, type: "string", suffix: "datum", optional: true},
    {key: "finacial_end_month", title: {sv: "Slutmånad"}, desc: {sv: ""}, default: 5, type: "integer", suffix: "(1-12)", optional: true},
    {key: "series_name", title: {sv: "Bokföringsserie"}, desc: {sv: ""}, default: 5, type: "string", optional: true},
    {key: "profit_center", title: {sv: "Resultatenhet"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "extend_financial_year", title: {sv: "Förlängt räkenskapsår"}, desc: {sv: ""}, type: "bool", optional: true},
  ],
  specials: ["mews"]
};

function momsDropdown() {
  return [0, 0.06, 0.12, 0.25];
}
