<div>
  <p>
    <mat-toolbar>
      <span>Konton</span>
      <span class="spacer"></span>
      <button mat-button routerLink="../venue-customers-import"><mat-icon>upload</mat-icon> Import</button>
      <button mat-button routerLink="../sync-accounts" *ngIf="cfg?.fortnox?.invoice?.active"><mat-icon>sync</mat-icon> Synk</button>
      <mat-checkbox style="font-size: 14px;" [(ngModel)]="filterUnhandled" color="primary" (change)="filterAccounts()">Obetalda</mat-checkbox>
      <button mat-button (click)="updateCustomer()"><mat-icon>add</mat-icon> Ny</button>
    </mat-toolbar>
  </p>
</div>
<mat-form-field appearance="fill" style="min-width: 200px;">
  <mat-label>Sök konto</mat-label>
  <input matInput (input)="applyFilter($event)" placeholder="Sök efter namn, e-post, etc.">
</mat-form-field>
<div style="padding: 16px; overflow-x: auto;">
  <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells">

    <ng-container matColumnDef="accounting_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Konto id </th>
      <td mat-cell *matCellDef="let element"> {{element.accounting_id}} </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Adress </th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Stad</th>
      <td mat-cell *matCellDef="let element"> {{element.city}} </td>
    </ng-container>

    <ng-container matColumnDef="contact_person">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kontaktperson</th>
      <td mat-cell *matCellDef="let element"> {{element.contact_person}} </td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
      <td mat-cell *matCellDef="let element"> {{element.country}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>E-post</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>key</th>
      <td mat-cell *matCellDef="let element"> {{element.key}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="org_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Org.nummer</th>
      <td mat-cell *matCellDef="let element"> {{element.org_number}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tel</th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="postal_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Postnummer</th>
      <td mat-cell *matCellDef="let element"> {{element.postal_number}} </td>
    </ng-container>

    <ng-container matColumnDef="rowIndex">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Index</th>
      <td mat-cell *matCellDef="let element"> {{element.rowIndex}} </td>
    </ng-container>

    <ng-container matColumnDef="price_list">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Prislista</th>
      <td mat-cell *matCellDef="let element"> {{element.price_list}} </td>
    </ng-container>

<!--    <ng-container matColumnDef="action">-->
<!--      <th mat-header-cell *matHeaderCellDef style="width:50px"></th>-->
<!--      <td mat-cell *matCellDef="let element">-->
<!--        <button mat-button [matMenuTriggerFor]="navigation">Åtgärd <mat-icon>arrow_drop_down</mat-icon></button>-->
<!--        <mat-menu #navigation>-->
<!--          <button mat-menu-item (click)="updateCustomer(element)">Redigera</button>-->
<!--          <mat-divider></mat-divider>-->
<!--          <button mat-menu-item (click)="duplicateCustomer(element)">Skapa kopia</button>-->
<!--          &lt;!&ndash;-->
<!--          <mat-divider></mat-divider>-->
<!--          <button mat-menu-item (click)="deleteCustomer(element)">Ta bort</button>-->
<!--          &ndash;&gt;-->
<!--        </mat-menu>-->
<!--      </td>-->
<!--    </ng-container>-->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
  </table>
</div>
