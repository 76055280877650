import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-confirm-archive-zreports-dialog',
  templateUrl: './confirm-archive-zreports-dialog.component.html',
  styleUrls: ['./confirm-archive-zreports-dialog.component.css']
})
export class ConfirmArchiveZreportsDialogComponent {
  identity: string;
  venueId?: number;
  selectedDate: any;
  code = new FormControl('', [Validators.required]);

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<ConfirmArchiveZreportsDialogComponent>, private auth: AuthService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  ok() {
    this.dialogRef.close(this.code.value);
  }

}
