import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from "rxjs";
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {PosStateService} from "../../../services/pos-state.service";
import {OrderService} from "../../../services/order.service";
import {FireService} from "../../../services/fire.service";
import {PrintDossier} from "../../../models/print-job";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import { AuthService } from 'src/app/services/auth.service';
import { AuthState } from 'src/app/models/signin';
import { SendReceiptDialogComponent } from './send-receipt-dialog/send-receipt-dialog.component';
import { HackUtils } from 'src/app/utils/utils';
import { ReceiptDialogComponent } from './receipt-dialog/receipt-dialog.component';

@Component({
  selector: 'app-receipt-view',
  templateUrl: './receipt-view.component.html',
  styleUrls: ['./receipt-view.component.css']
})
export class ReceiptViewComponent implements OnInit, OnDestroy, OnChanges {

  @Input() receiptKey: string;
  @Input() venueId: number;
  @Input() isSubComponent = false;
  @Input() showActionsBar = false;
  @Input() refundId: number;
  showSpinner = true;
  receiptDossier: PrintDossier;
  inPos = false;
  private subParams: Subscription;
  private table: string;
  private authState: AuthState;
  fortnoxInvoiceNumber: string;
  postActions: any;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private router: Router,
              private dialog: MatDialog, private state: PosStateService, private orderService: OrderService,
              private fire: FireService, public authService: AuthService) { }

  ngOnInit(): void {
    this.authService.currentAuthState().subscribe(auth => {
      this.authState = auth;
    });
    if (!this.isSubComponent) {
      if (this.receiptKey) {
        //Should be set in onChange...
        //this.fetchData();
      } else {
        this.fetchReceiptKeyFromRoute();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("ReceiptViewComponent changes:", changes);
    this.fetchData();
  }

  fetchReceiptKeyFromRoute() {
    this.subParams = this.route.paramMap.subscribe(data => {
      if (!this.verifyRoute(data)) { return; }

      this.inPos = data.get("mode") != null;
      this.venueId = Number(data.get("venue_id"));
      const hasActive = data.get("mode") === "active-bill";
      if (!hasActive) {
        let rk = data.get("receipt_key");
        if (rk == null) { rk = data.get("table"); }
        this.receiptKey = rk;
        this.fetchData();
      } else {
        this.table = data.get("table");
        console.log("Fetch active for table: ", this.table);
        this.fetchData();
      }
    });
  }

  ngOnDestroy(): void {
    this.subParams?.unsubscribe();
  }

  private fetchData() {
    this.receiptDossier = undefined;
    let f;
    if (this.receiptKey && this.receiptKey !== "0") {
      console.log("receiptKey", this.receiptKey);
      f = this.venueService.fetchReceiptDossier(this.receiptKey);
    } else if (this.venueId && this.table){
      f = this.venueService.fetchActiveBillDossier(this.venueId, this.table);
    }
    if (f) {
      this.showSpinner = true;
      f.then(res => {
        console.log(res);
        this.receiptDossier = res;
        this.refundId = res.refund_of_id != null ? res.refund_of_id : this.refundId;
        this.fortnoxInvoiceNumber = res.fortnox_invoice_number;
        this.postActions = res.post_actions;
      }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => this.showSpinner = false );
    }
  }

  private verifyRoute(data: ParamMap) {
    const mode = data.get("mode");
    if (mode != null) {
      return mode === "receipt" || mode === "active-bill";
    }
    return true;
  }

  sendReceipt() {
    const dialogRef = this.dialog.open(SendReceiptDialogComponent, HackUtils.DLG({
      data: {
        venueId: this.venueId,
        receiptKey: this.receiptKey,
        title: "Skicka kvitto",
      }
    }));
  }

  openReceiptDialog() {
    const dialogRef = this.dialog.open(ReceiptDialogComponent, HackUtils.DLG({
      data: {
        receiptKey: this.refundId,
        venueId: this.venueId,
        isSubComponent: true
      }
    }));
  }

  isSuperAdmin(): boolean {
    return AuthService.isSuperAdmin(this.authState);
  }

  previewFortnox() {
    this.venueService.previewFortnoxPDF(this.venueId.toString(), this.fortnoxInvoiceNumber, `invoice-${this.fortnoxInvoiceNumber}.pdf`).subscribe(r => {

    });
  }

  sendLinks() {
    const dialogRef = this.dialog.open(SendReceiptDialogComponent, HackUtils.DLG({
      data: {
        venueId: this.venueId,
        receiptKey: this.receiptKey,
        title: "Skicka länkar till presentkort",
        mailType: "gift-card-links"
      }
    }));
  }
}
