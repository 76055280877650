/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import {Injectable} from '@angular/core';
import {AuthorizedHttpService} from "./authorized-http-service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Venue} from "../models/venue-list";
import {LocationCode, VenueDashboard} from "../models/venue-dashboard";
import {CoreReceiptListResult, ExecuteResult, MarkAsUnpaidResult, QueryReceiptResult} from "../models/query-receipt";
import utils from '../common/utils';
import {AccountUser, Admin, QueryUserResult, StaffRoleSchema, User} from "../models/user";
import {PublishRequest, PublishResponse} from "../components/catalog/menu-models/PublishModels";
import {Observable} from "rxjs";
import {QueryZReportResult, Zreport, ZreportRequest} from "../models/zreport";
import {ItemRef, PatchResult} from "../models/order";
import {ConfigFragment, SettingHistory} from "../models/settings-page";
import {EditableOrder} from "../models/editable-order";
import {HackUtils, NodeUtils} from "../utils/utils";
import {CreateDiscountCodeRequest, DiscountCode, DiscountCodeResult} from "../models/discount-code";
import {PrintDossier} from "../models/print-job";
import {KpiStats} from "../models/kpi-stats";
import {Ticket} from "../models/venue-ticket";
import {SlotDay} from "../models/slot";
import {FPVersion} from "../models/floorplan";
import {SyncAccountResult, VenueCustomer, VenueCustomerResult} from "../models/venue-customers";
import {Prepaid} from "../models/prepaid";
import {PrepaidData} from '../models/prepaid-data';
import {
  QueryBasicRequest,
  QueryCheckinsResponse,
  QueryCustomerDataResponse,
  QueryDayTimeArticleListRequest,
  QueryDayTimeArticleListResponse,
  QueryEventsRequest,
  QueryEventsResponse,
  QueryHourlyDataResponse,
  QueryMenuSalesDataRequest,
  QueryMenuSalesDataResponse,
  QuerySalesDataRequest,
  QuerySalesReportResponse,
  QuerySalesTreeDataResponse,
  QueryStaffClassicSummaryResponse,
  QueryStaffDataResponse, QueryTimeSeriesDataResponse,
  QueryVenueTransactionsRequest,
  QueryZReportSummaryResponse,
} from "../models/reporting";
import {ApiToken} from "../models/api-token";
import {FortnoxCodeResponse, FortnoxSummary} from "../models/fortnox";
import {VenueCron} from "../models/venue-cron";
import {VenueMessage} from "../models/venue_message";
import { AdyenSetup } from '../models/adyen-legal-entity';
import {Device, RegUnit} from "../models/reg-unit";
import {QueryVenueTransactionsResult, TransactionDetails} from '../models/venue-transactions';
import {MenuItem} from "../models/menu-structure";
import {SoftpayTerminal, SoftpayTerminalResponse} from '../models/softpay';
import {LoyaltyCard} from '../models/loyalty';
import { FbBot, FbBotRequest } from '../models/fb-bots';
import {HNChain} from "../models/chain";
import { CreateVenueRequest, CreateVenueResponse, Venue as VenueInfo } from '../models/venue';
import {Booking, BookingRequest, BookingResponse} from '../models/booking';
import { SmartTable, EditSmartTableRequest } from '../models/smart-table';
import {FetchSubsResponse} from "../models/subscriber";
import {MewsAccountingCategoriesResponse, MewsServicesResponse} from '../models/mews';

export class QueryReceiptRequest {
  // Required
  venue_id: string;
  limit: number;
  offset: number;

  // Optional
  start_date: string; // Example format "2020-01-01"
  end_date: string;
  filter: string;
  sort_order: string;
  staff_id: string;
  account_key: string;
}

const API_URL = "api/adm/exp";
@Injectable({
  providedIn: 'root'
})
export class VenueService extends AuthorizedHttpService {

  public async getVenueList(customColumns: string[], showInactive: boolean): Promise<Venue[]> {
    const body = { action: "fetch_venues", custom_columns: undefined, show_inactive: showInactive};
    if (customColumns.length > 0) {
      body.custom_columns = customColumns.map<string>(c => c.trim()).join(",");
    }
    return this.post<Venue[]>(API_URL, body);
  }

  public async getVenueDashboard(venueId: string): Promise<VenueDashboard> {
    const body = {venue_id: venueId, action: "venue_dashboard"};
    return this.post<VenueDashboard>(API_URL, body);
  }

  public async queryReceipts(request: QueryReceiptRequest): Promise<QueryReceiptResult> {
    const requestWithAction = utils.appendActionToObject(request, "query_receipts_v2");
    return this.post<QueryReceiptResult>(API_URL, requestWithAction);
  }

  public async fetchAccountEntires(venueId: string, account_key: string, state: string): Promise<QueryReceiptResult> {
    const body = {venue_id: venueId, account_key, action: "fetch_account_entries", state};
    return this.post<QueryReceiptResult>(API_URL, body);
  }

  public async fetchAccountReceipts(venueId: string, account_key: string): Promise<CoreReceiptListResult> {
    const body = {venue_id: venueId, account_key, action: "fetch_account_receipts"};
    return this.post<CoreReceiptListResult>(API_URL, body);
  }

  public queryVenueTransactions(request: QueryVenueTransactionsRequest): Promise<QueryVenueTransactionsResult[]> {
    const requestWithAction = utils.appendActionToObject(request, "query_venue_transactions");
    return this.post<QueryVenueTransactionsResult[]>(API_URL, requestWithAction);
  }

  public exportQueryReceipts(request: QueryReceiptRequest, exportFormat: string, filename: string): Observable<any> {
    const requestWithAction = utils.appendActionToObject(request, "export_query_receipts");
    requestWithAction.export = exportFormat;
    return this.downloadFileP(API_URL, requestWithAction, filename);
  }

  public async queryZReports(request: QueryReceiptRequest): Promise<QueryZReportResult> {
    const requestWithAction = utils.appendActionToObject(request, "query_zreports");
    return this.post<QueryZReportResult>(API_URL, requestWithAction);
  }

  public async getCheckinReport(venueId: string, checkinId: number): Promise<any> {
    const requestWithAction = utils.appendActionToObject({venue_id: venueId, checkin_id: checkinId}, "get_checkin_report");
    return this.post<any>(API_URL, requestWithAction);
  }

  public async paginateZReports(request: ZreportRequest): Promise<QueryZReportResult> {
    const requestWithAction = utils.appendActionToObject(request, "query_zreports");
    return this.post<QueryZReportResult>(API_URL, requestWithAction);
  }

  public async fetchZReport(venueId: number, zreportKey: string): Promise<Zreport> {
    const body = {venue_id: venueId, key: zreportKey, action: "fetch_zreport"};
    return this.post<Zreport>(API_URL, body);
  }

  public async deleteZReport(venueId: number, zreportKey: string): Promise<Zreport> {
    const body = {venue_id: venueId, key: zreportKey, action: "delete_zreport"};
    return this.post<Zreport>(API_URL, body);
  }

  public async queryPrepaidTx(request: QuerySalesDataRequest): Promise<QuerySalesReportResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_prepaid_tx");
    return this.post<QuerySalesReportResponse>(API_URL, requestWithAction);
  }

  public async querySalesTreeData(request: QuerySalesDataRequest): Promise<QuerySalesTreeDataResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_sales_tree_data");
    requestWithAction["v"] = "2";
    return this.post<QuerySalesTreeDataResponse>(API_URL, requestWithAction);
  }

  public async queryHourlyData(request: QuerySalesDataRequest): Promise<QueryHourlyDataResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_hourly_data");
    return this.post<QueryHourlyDataResponse>(API_URL, requestWithAction);
  }

  public async querySalesPerDay(request: QuerySalesDataRequest): Promise<QuerySalesReportResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_sales_per_day");
    return this.post<QuerySalesReportResponse>(API_URL, requestWithAction);
  }

  public async queryTimeSeriesData(request: QuerySalesDataRequest): Promise<QueryTimeSeriesDataResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_time_series_data");
    return this.post<QueryTimeSeriesDataResponse>(API_URL, requestWithAction);
  }

  public async queryCustomerData(request: QuerySalesDataRequest): Promise<QueryCustomerDataResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_customer_data");
    return this.post<QueryCustomerDataResponse>(API_URL, requestWithAction);
  }

  public async queryStaffSummary(request: QuerySalesDataRequest): Promise<QueryStaffDataResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_staff_summary");
    return this.post<QueryStaffDataResponse>(API_URL, requestWithAction);
  }

  public async queryClassicStaffSummary(request: QuerySalesDataRequest): Promise<QueryStaffClassicSummaryResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_classic_staff_summary");
    return this.post<QueryStaffClassicSummaryResponse>(API_URL, requestWithAction);
  }

  public queryZReportSummary(request: QuerySalesDataRequest): Promise<QueryZReportSummaryResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_zreport_summary");
    return this.post<QueryZReportSummaryResponse>(API_URL, requestWithAction);
  }

  public async queryCheckins(request: QueryBasicRequest): Promise<QueryCheckinsResponse[]> {
    const requestWithAction = utils.appendActionToObject(request, "query_checkins");
    return this.post<QueryCheckinsResponse[]>(API_URL, requestWithAction);
  }

  public queryAndDownloadSieFile(request: QuerySalesDataRequest, filename: string): Observable<any> {
    const requestWithAction = utils.appendActionToObject(request, "query_and_download_sie");
    return this.downloadFileP(API_URL, requestWithAction, filename);
  }

  public downloadPDF(venueId: number, key: string, filename: string): Observable<any> {
    const body = {key, action: "zreport_pdf", venue_id: venueId};
    return this.downloadFileP(API_URL, body, filename);
  }

  public downloadSIE(venueId: number, key: string, filename: string): Observable<any> {
    const body = {key, action: "zreport_sie", venue_id: venueId};
    return this.downloadFileP(API_URL, body, filename);
  }

  public fetchZReportHtml(venueId: number, key: string): Promise<any> {
    const body = {key, action: "zreport_html", venue_id: venueId};
    return this.post<any>(API_URL, body);
  }

  public postZReportToFortnox(venueId: string, zreport_key: string): Promise<any> {
    const body = {zreport_key, action: "post_zreport_to_fortnox", venue_id: venueId};
    return this.post(API_URL, body);
  }

  public fetchPaymentStaffReportHtml(venueId: number, key: string): Promise<any> {
    const body = {key, action: "payment_staff_report_html", venue_id: venueId};
    return this.post<any>(API_URL, body);
  }

  public downloadPaymentStaffReportPDF(venueId: number, key: string, filename: string): Observable<any> {
    const body = {key, action: "payment_staff_report_pdf", venue_id: venueId};
    return this.downloadFileP(API_URL, body, filename);
  }

  public async fetchXReportHtml(venueId: number): Promise<any> {
    const body = {venue_id: venueId, action: "xreport_html"};
    return this.post<any>(API_URL, body);
  }

  public async refreshTable(venueId: number, table: string): Promise<QueryReceiptResult> {
    const body = {venue_id: venueId, table, action: "refresh_table"};
    return this.post<QueryReceiptResult>(API_URL, body );
  }

  public async closeSession(venueId: number, table: string): Promise<QueryReceiptResult> {
    const body = {venue_id: venueId, table, action: "close_session"};
    return this.post<QueryReceiptResult>(API_URL, body );
  }

  public async sendPaymentLink(venueId: number, table: string, address: string): Promise<QueryReceiptResult> {
    const body = {venue_id: venueId, table, action: "send_payment_link", address};
    return this.post<QueryReceiptResult>(API_URL, body );
  }

  public async forwardTable(venueId: number, table: string, phase: number, itemRefs: ItemRef[]): Promise<QueryReceiptResult> {
    const body = {venue_id: venueId, table, phase, item_refs: itemRefs, action: "forward_table"};
    return this.post<QueryReceiptResult>(API_URL, body );
  }

  public async fetchReceiptDossier(receiptKey: string): Promise<PrintDossier> {
    const body = {receipt_key: receiptKey, action: "fetch_receipt_dossier"};
    return this.post<PrintDossier>(API_URL, body );
  }

  public async getTransaction(venueId: string, transactionKey: string): Promise<TransactionDetails> {
    const body = {venue_id: venueId, key: transactionKey, action: "get_transaction"};
    return this.post<TransactionDetails>(API_URL, body);
  }

  public async sendReceipt(venueId: number, receiptKey: string, address: string, mailType: string = undefined): Promise<void> {
    const body = {venue_id: venueId, receipt_key: receiptKey, action: "send_receipt", address, "mail_type": mailType};
    return this.post<void>(API_URL, body);
  }

  public async fetchActiveBillDossier(venueId: number, table: string): Promise<PrintDossier> {
    const body = {venue_id: venueId, table, action: "fetch_active_bill_dossier"};
    return this.post<PrintDossier>(API_URL, body );
  }

  public async voidTable(venueId: number, table: string): Promise<void> {
    const body = {venue_id: venueId, table, action: "void_table"};
    return this.post<void>(API_URL, body );
  }

  public async markUnpaid(venueId: number, table: string): Promise<MarkAsUnpaidResult> {
    const body = {venue_id: venueId, table, action: "mark_unpaid"};
    return this.post<MarkAsUnpaidResult>(API_URL, body );
  }

  public async executeActiveBill(venueId: number, table: string): Promise<ExecuteResult> {
    const body = {venue_id: venueId, table, action: "execute_active"};
    return this.post<ExecuteResult>(API_URL, body );
  }

  public async payBill(receiptKey: string, terminal?: string): Promise<ExecuteResult> {
    const body = { receipt_key: receiptKey, terminal, action: "pay_bill"};
    return this.post<ExecuteResult>(API_URL, body );
  }

  public async fetchLocationCode(venueId: number, table: string): Promise<LocationCode> {
    const body = {venue_id: venueId, table, action: "fetch_lc"};
    return this.post<LocationCode>(API_URL, body );
  }

  public async fetchAllLocationCodes(venueId: number): Promise<LocationCode[]> {
    const body = {venue_id: venueId, action: "fetch_all_lc"};
    return this.post<LocationCode[]>(API_URL, body );
  }

  public async exploreReceipt(query: string): Promise<any> {
    const body = {query, action: "explore_receipt"};
    return this.post<LocationCode>(API_URL, body );
  }

  public async copyReceipt(receiptKey: string, dryRun: boolean, runTasks: boolean, recalculatePrice: boolean, fieldsToEdit: any): Promise<any> {
    const body = {
      receipt_key: receiptKey,
      action: "copy_receipt",
      dry_run: dryRun,
      run_tasks: runTasks,
      recalculate_price: recalculatePrice,
      fields_to_edit: fieldsToEdit};
    return this.post<any>(API_URL, body);
  }

  public async fixReceipt(receiptKey: string, commit: boolean): Promise<any> {
    const body = {receipt_key: receiptKey, action: "fix_receipt", commit};
    return this.post<LocationCode>(API_URL, body );
  }

  public async fixReceiptCommands(receiptKey: string, commands: string, commit: boolean): Promise<any> {
    const body = {receipt_key: receiptKey, action: "fix_receipt_commands", commands, commit};
    return this.post<LocationCode>(API_URL, body );
  }

  public async sendSimpleEmail(email: string, list_name: string, subject: string, body: string, preview: boolean, imgStye: string, sender: string): Promise<any> {
    const bdy = {email, list_name, action: "send_simple_email", subject, body, preview, image_style: imgStye, sender};
    return this.post<any>(API_URL, bdy );
  }

  public async queryEmail(email: string, venueId: number): Promise<QueryUserResult> {
    const body = {email, venue_id: venueId, action: "query_email"};
    return this.post<QueryUserResult>(API_URL, body );
  }

  public async createNewUser(firstName: string, lastName: string, email: string, roles: string[], venueId?: number): Promise<void> {
    const body = {venue_id: venueId, first_name: firstName, last_name: lastName, email, roles: roles.join(","), action: "create_user"};
    return this.post<void>(API_URL, body );
  }

  public async updateAdminUser(firstName: string, lastName: string, email: string, venueId?: number): Promise<void> {
    const body: any = {first_name: firstName, last_name: lastName, email, action: "update_user"};
    if (venueId) {
      body.venue_id = venueId;
    }
    return this.post<void>(API_URL, body );
  }

  public async resendInvite(email: string, venueId?: number): Promise<void> {
    const body = {venue_id: venueId, email, action: "resend_invite"};
    return this.post<void>(API_URL, body );
  }

  public async updateRole(email: string, roles: string[], venueId?: number): Promise<void> {
    const body = {venue_id: venueId, email, roles: roles.join(","), action: "update_role"};
    return this.post<void>(API_URL, body );
  }

  public async fetchAdminUsers(venueId?: number): Promise<Admin[]> {
    const body = {venue_id: venueId, action: "fetch_admins"};
    console.log("fetchAdminUsers", body);
    return this.post<Admin[]>(API_URL, body );
  }

  public async fetchAllAdminUsers(): Promise<AccountUser[]> {
    const body = {action: "fetch_all_admins"};
    return this.post<AccountUser[]>(API_URL, body );
  }

  public async fetchApiTokens(): Promise<ApiToken[]> {
    const body = {action: "fetch_apitokens"};
    return this.post<ApiToken[]>(API_URL, body);
  }

  public async fetchChains(): Promise<HNChain[]> {
    const body = {action: "fetch_chains"};
    return this.post<HNChain[]>(API_URL, body);
  }

  public async fetchApiToken(token: string): Promise<ApiToken> {
    const body = {action: "fetch_apitoken", token};
    return this.post<ApiToken>(API_URL, body);
  }

  public async createNewApiToken(name: string, email: string, phone: string): Promise<void> {
    const body = {name, email, phone, action: "new_apitoken"};
    return this.post<void>(API_URL, body);
  }

  public async fetchChain(chainKey: string): Promise<HNChain> {
    const body = {action: "fetch_chain", chain_key: chainKey};
    return this.post<HNChain>(API_URL, body);
  }

  public async createNewChain(name: string, email: string, path: string, types: string): Promise<void> {
    const body = {name, email, path, types, action: "new_chain"};
    return this.post<void>(API_URL, body);
  }

  public async updateApiToken(apiToken: ApiToken): Promise<ApiToken> {
    const body = {token: apiToken.token, name: apiToken.name, email: apiToken.email, roles: apiToken.roles, action: "update_apitoken"};
    return this.post<ApiToken>(API_URL, body);
  }

  public async updateChain(hnchain: HNChain): Promise<HNChain> {
    const body = {chain_key: hnchain.chain_key, name: hnchain.name, venues: hnchain.venues, types: hnchain.chain_types, action: "update_chain"};
    return this.post<HNChain>(API_URL, body);
  }

  public async fetchRegunits(filter: string): Promise<RegUnit[]> {
    const body = {action: "fetch_regunits", filter};
    return this.post<RegUnit[]>(API_URL, body);
  }

  public exportSelectedRegUnits(reqUnitKeys: string[], filename: string): Observable<any> {
    const body = {reg_unit_keys: reqUnitKeys, action: "export_regunits"};
    return this.downloadFileP(API_URL, body, filename);
  }

  public updateSelectedRegUnits(reqUnitKeys: string[], state: string, filter: string): Promise<RegUnit[]> {
    const body = {reg_unit_keys: reqUnitKeys, action: "update_regunits", state, filter};
    return this.post<RegUnit[]>(API_URL, body);
  }

  public createNewRegUnits(filter: string): Promise<RegUnit[]> {
    const body = {action: "create_new_regunits", filter};
    return this.post<RegUnit[]>(API_URL, body);
  }

  public setDeviceRequnitState(venueId: number, device: Device, state: string): Promise<RegUnit> {
    const body = {venue_id: venueId, action: "set_device_regunit_state", device_key: device.key, state};
    return this.post<RegUnit>(API_URL, body);
  }

  public async linkAccount(linkToken: string, venueId?: number): Promise<User> {
    const body = {venue_id: venueId, link_token: linkToken, action: "link_account"};
    return this.post<User>(API_URL, body );
  }

  public async checkVenueId(venueId: number): Promise<void> {
    const body = {venue_id: venueId, action: "cvi"};
    return this.post<void>(API_URL, body);
  }

  public patchSettings(venueId: number, rootPath: string, anchorPath: string, bdy: any, diff: any): Promise<PatchResult> {
    const body = {venue_id: venueId, root_path: rootPath, anchor_path: anchorPath, body: bdy, diff, action: "patch_settings"};
    return this.post<PatchResult>(API_URL, body);
  }

  public fetchSettingsHistory(venueId: number, rootPath: string): Promise<SettingHistory[]> {
    const body = {venue_id: venueId, root_path: rootPath, action: "setting_history"};
    return this.post<SettingHistory[]>(API_URL, body);
  }

  public async fetchFragmentVersion(venueId: number, fragmentKey: string): Promise<ConfigFragment> {
    const body = {venue_id: venueId, fragment_key: fragmentKey, action: "fetch_fragment_version"};
    return this.post<ConfigFragment>(API_URL, body);
  }

  public async restoreSettingsVersion(venueId: number, fragmentKey: string): Promise<ConfigFragment> {
    const body = {venue_id: venueId, fragment_key: fragmentKey, action: "restore_settings_version"};
    return this.post<ConfigFragment>(API_URL, body);
  }

  public saveFloorplan(venueId: number, bdy: any, diff: any): Promise<PatchResult> {
    const body = {venue_id: venueId, body: bdy, diff, action: "save_floorplan"};
    return this.post<PatchResult>(API_URL, body);
  }

  public fetchFloorplanHistory(venueId: number): Promise<FPVersion[]> {
    const body = {venue_id: venueId, action: "floorplan_history"};
    return this.post<FPVersion[]>(API_URL, body);
  }

  public async fetchFloorplanVersion(venueId: number, versionKey: string): Promise<FPVersion> {
    const body = {venue_id: venueId, version_key: versionKey, action: "fetch_floorplan_version"};
    return this.post<FPVersion>(API_URL, body);
  }

  public async restoreFloorplanVersion(venueId: number, versionKey: string): Promise<FPVersion> {
    const body = {venue_id: venueId, version_key: versionKey, action: "restore_floorplan_version"};
    return this.post<FPVersion>(API_URL, body);
  }

  public async updateVenueCustomer(venueId: number, customer: VenueCustomer, chainPath: string): Promise<VenueCustomer> {
    const body = {venue_id: venueId, customer, chain_path: chainPath, action: "update_venue_customer"};
    return this.post<VenueCustomer>(API_URL, body);
  }

  public async deleteVenueCustomer(venueId: number, key: string, chainPath: string): Promise<VenueCustomer> {
    const body = {venue_id: venueId, key, chain_path: chainPath, action: "delete_venue_customer"};
    return this.post<VenueCustomer>(API_URL, body);
  }

  public async fetchVenueCustomers(venueId: number, chainPath: string, filter: string): Promise<VenueCustomerResult> {
    const body = {venue_id: venueId, chain_path: chainPath, action: "fetch_venue_customers", filter};
    return this.post<VenueCustomerResult>(API_URL, body);
  }

  // Deprecated. Use editAccount instead
  public async updateVenueCustomers(venueId: number, chainPath: string, customers: VenueCustomer[]): Promise<VenueCustomerResult> {
    const body = {venue_id: venueId, chain_path: chainPath, action: "update_venue_customers", customers};
    return this.post<VenueCustomerResult>(API_URL, body);
  }

  public async editAccount(venueId: number, customer: VenueCustomer): Promise<VenueCustomer> {
    const body = {venue_id: venueId, ...customer, action: "edit_account"};
    return this.post<VenueCustomer>(API_URL, body);
  }

  public async fetchAccount(venueId: number, account_key: string): Promise<VenueCustomer> {
    const body = {venue_id: venueId, account_key, action: "fetch_account"};
    return this.post<VenueCustomer>(API_URL, body);
  }

  public async syncAccounts(venueId: number, preview: boolean, direction: string): Promise<SyncAccountResult> {
    const body = {venue_id: venueId, action: "sync_accounts", preview, direction};
    return this.post<SyncAccountResult>(API_URL, body);
  }

  public async updateSlots(venueId: number, slots: any[], namespace: string): Promise<SlotDay[]> {
    const body = {venue_id: venueId, action: "update_slots", slots, namespace};
    return this.post<SlotDay[]>(API_URL, body);
  }

  public async fetchKPIStats(): Promise<KpiStats> {
    const body = {action: "fetch_kpi_stats"};
    return this.post<KpiStats>(API_URL, body);
  }

  public async fetchDiscountCodes(venueId: number, cursor: string): Promise<DiscountCodeResult> {
    const body = {venue_id: venueId, action: "fetch_discount_codes", cursor};
    return this.post<DiscountCodeResult>(API_URL, body);
  }

  public async createDiscountCode(venueId: number, createCode: CreateDiscountCodeRequest): Promise<DiscountCodeResult> {
    const body = Object.assign({venue_id: venueId, action: "create_discount_code"}, createCode);
    return this.post<DiscountCodeResult>(API_URL, body);
  }

  public async queryTickets(venueId: number, active: boolean): Promise<Ticket[]> {
    const body = {action: "query_tickets", active};
    return this.post<Ticket[]>(API_URL, body);
  }

  public async resolveTicket(ticket_key: string): Promise<void> {
    const body = {ticket_key, action: "resolve_ticket"};
    return this.post<void>(API_URL, body);
  }

  public async closeTicket(ticket_key: string): Promise<void> {
    const body = {ticket_key, action: "close_ticket"};
    return this.post<void>(API_URL, body);
  }

  public async openTicket(ticket_key: string): Promise<Ticket> {
    const body = {ticket_key, action: "open_ticket"};
    return this.post<Ticket>(API_URL, body);
  }

  public async loadTicket(ticket_key: string): Promise<Ticket> {
    const body = {ticket_key, action: "load_ticket"};
    return this.post<Ticket>(API_URL, body);
  }

  public async fetchSlots(venueId: number, namespace: string): Promise<SlotDay[]> {
    const body = {venue_id: venueId, action: "fetch_slots", namespace};
    return this.post<SlotDay[]>(API_URL, body);
  }

  public async fetchSlotdaySummary(venueId: number, namespace: string, date: string): Promise<any> {
    const body = {venue_id: venueId, action: "fetch_slot_day_summary", namespace, date};
    return this.post<any>(API_URL, body);
  }

  public async createNextZReport(venueId: number, date: string): Promise<any> {
    const body = {venue_id: venueId, action: "create_next_zreport", date};
    return this.post<any>(API_URL, body);
  }

  public async archiveZReports(venueId: number, verification: string): Promise<any> {
    const body = {venue_id: venueId, action: "disconnect_zreports", verification};
    return this.post<any>(API_URL, body);
  }

  public sendOrder(venueId: number, order: EditableOrder): Promise<void> {
    const items = NodeUtils.deepcopyWithJSON(order.items);
    // Map/convert from firebase model to expected server order item
    for (const it of items) {
      delete it.orderKey;
      delete it.cachedIsPaid;
      delete it.userId;
      delete it.customized;
      it.price = `${it.price}`;
    }
    const body = {venue_id: venueId, items, table: order.table, client_id: order.clientId, action: "send_order"};
    return this.post<void>(API_URL, body);
  }

  public async fetchStaff(venueId: number): Promise<Admin[]> {
    const body = {venue_id: venueId, action: "fetch_staff"};
    return this.post<Admin[]>(API_URL, body );
  }

  public async queryStaffIdent(ident: string, venueId: number): Promise<QueryUserResult> {
    const body = {identity: ident, venue_id: venueId, action: "query_staff"};
    return this.post<QueryUserResult>(API_URL, body );
  }

  public async createNewStaff(firstName: string, lastName: string, identity: string, venueId: number, pin: string, email: string, phone: string): Promise<void> {
    const body = {venue_id: venueId, first_name: firstName, last_name: lastName, identity, pin, email, phone, action: "create_staff"};
    return this.post<void>(API_URL, body );
  }

  public async updateStaffPin(identity: string, venueId: number, pin: string): Promise<void> {
    const body = {venue_id: venueId, identity, pin, action: "set_pin"};
    return this.post<void>(API_URL, body );
  }

  public async removeStaff(identity: string, venueId: number): Promise<void> {
    const body = {venue_id: venueId, identity, action: "remove_staff"};
    return this.post<void>(API_URL, body );
  }

  public async editStaff(firstName: string, lastName: string, identity: string, venueId: number, email: string, phone: string, roles: string[]): Promise<void> {
    const body = {venue_id: venueId, first_name: firstName, last_name: lastName, identity, email, phone, roles, action: "edit_staff"};
    return this.post<void>(API_URL, body );
  }

  public async fetchRoleSchema(venueId: number): Promise<StaffRoleSchema[]> {
    const body = {venue_id: venueId, action: "get_role_schema"};
    return this.post<StaffRoleSchema[]>(API_URL, body);
  }

  public publishMenu(venueId: number, menuId: string, pr: PublishRequest): Observable<PublishResponse> {
    const body = {venue_id: venueId, menu_id: menuId, menu_data: pr, action: "publish_menu"};
    return this.postO<PublishResponse>(API_URL, body );
  }

  public fetchPrepaids(venueId: number): Promise<Prepaid[]> {
    const body = {venue_id: venueId, action: "prepaids"};
    return this.post<Prepaid[]>(API_URL, body);
  }

  public fetchPrepaidData(venueId: number, key: string): Promise<PrepaidData> {
    const body = {venue_id: venueId, action: "fetch_prepaid_data", key};
    return this.post<PrepaidData>(API_URL, body);
  }

  public voidPrepaidCard(venueId: number, key: string): Promise<PrepaidData> {
    const body = {venue_id: venueId, action: "void_prepaid_card", key};
    return this.post<PrepaidData>(API_URL, body);
  }

  public voidMultiPrepaidCards(venueId: number, prepaidKeys: string[]): Promise<void> {
    const body = {venue_id: venueId, prepaid_keys: prepaidKeys, action: "void_multi_prepaid_cards"};
    return this.post<void>(API_URL, body);
  }

  public editPrepaidIdentity(venueId: number, key: string, old_identity: string, new_identity: string): Promise<PrepaidData> {
    const body = {venue_id: venueId, action: "edit_prepaid_identity", key, old_identity, new_identity};
    return this.post<PrepaidData>(API_URL, body);
  }

  public processAuthCode(venueId: number, code: string): Promise<FortnoxCodeResponse> {
    const body = {venue_id: venueId, action: "process_fortnox_auth_code", code};
    return this.post<FortnoxCodeResponse>(API_URL, body);
  }

  public fetchFortnoxSummary(venueId: number): Promise<FortnoxSummary> {
    const body = {venue_id: venueId, action: "fetch_fortnox_summary"};
    return this.post<FortnoxSummary>(API_URL, body);
  }

  public getAdyenSetup(venueId: number): Promise<AdyenSetup> {
    const body = {venue_id: venueId, action: "get_adyen_setup"};
    return this.post<AdyenSetup>(API_URL, body);
  }

  public fetchVenueCrons(venueId: number): Promise<VenueCron[]> {
    const body = {venue_id: venueId, action: "fetch_venue_crons"};
    return this.post<VenueCron[]>(API_URL, body);
  }

  public runVenueCron(venueId: number, key: string): Promise<VenueCron[]> {
    const body = {venue_id: venueId, action: "run_venue_cron", key};
    return this.post<VenueCron[]>(API_URL, body);
  }

  public fetchVenueMessages(): Promise<VenueMessage[]> {
    const body = {action: "fetch_venue_messages"};
    return this.post<VenueMessage[]>(API_URL, body);
  }

  public createNewVenueMessage(title: string, message: string): Promise<void> {
    const body = {title, message, action: "new_venue_message"};
    return this.post<void>(API_URL, body);
  }

  public unpublishVenueMessage(key: string): Promise<void> {
    const body = {key, action: "unpublish_venue_message"};
    return this.post<void>(API_URL, body);
  }

  public consumeVouchers(venueId: number, voucherIds: number[]): Promise<void> {
    const body = {venue_id: venueId, action: "consume_vouchers", voucher_ids: voucherIds};
    return this.post<void>(API_URL, body);
  }

  public queryEvents(request: QueryEventsRequest): Promise<QueryEventsResponse> {
    const body = utils.appendActionToObject(request, "query_events");
    return this.post<QueryEventsResponse>(API_URL, body);
  }

  public simulateFilter(venueId: number, filter: string, params: {}): Promise<MenuItem[]> {
    const body = {venue_id: venueId, action: "simulate_filter", filter, params};
    return this.post<MenuItem[]>(API_URL, body);
  }

  public simulateFilterOrderDossiers(venueId: number, filter: string): Promise<PrintDossier[]> {
    const body = {venue_id: venueId, action: "simulate_filter_orders", filter};
    return this.post<PrintDossier[]>(API_URL, body);
  }

  public async fetchDevices(venueId?: number): Promise<Device[]> {
    const body = {venue_id: venueId, action: "fetch_devices"};
    return this.post<Device[]>(API_URL, body);
  }

  public async fetchDevice(venueId: number, deviceKey: string): Promise<Device> {
    const body = {venue_id: venueId, device_key: deviceKey, action: "fetch_device"};
    return this.post<Device>(API_URL, body);
  }

  public async updateDevice(venueId: number, device: Device): Promise<Device> {
    const body = {venue_id: venueId, device, action: "update_device"};
    return this.post<Device>(API_URL, body);
  }

  public async updateDeviceVenue(deviceKey: string, venueId: number): Promise<Device> {
    const body = {device_key: deviceKey, venue_id: venueId, action: "update_device_venue"};
    return this.post<Device>(API_URL, body);
  }

  public async fetchFbBots(): Promise<FbBot[]> {
    const body = {action: "fetch_fb_bots"};
    return this.post<FbBot[]>(API_URL, body);
  }

  public async getFbBot(id: number): Promise<FbBot> {
    const body = {id, action: "get_fb_bot"};
    return this.post<FbBot>(API_URL, body);
  }

  public async editFbBot(bot: FbBotRequest): Promise<FbBot> {
    const body = {...bot, action: "edit_fb_bot"};
    return this.post<FbBot>(API_URL, body);
  }

  public async executeBotAction(id: number, bot_action: string, value?: string, type?: string): Promise<any> {
    const body = {id, bot_action, value, type, action: "execute_bot_action"};
    return this.post<any>(API_URL, body);
  }

  public async fetchSoftpayTerminals(venueId: number): Promise<SoftpayTerminalResponse> {
    const body = {venue_id: venueId, action: "fetch_softpay_terminals"};
    return this.post<SoftpayTerminalResponse>(API_URL, body);
  }

  public async createSoftpayTerminal(venueId: number, deviceKey: string): Promise<SoftpayTerminal> {
    const body = {venue_id: venueId, device_key: deviceKey, action: "create_softpay_terminal"};
    return this.post<SoftpayTerminal>(API_URL, body);
  }

  public async fetchSoftpayTerminalDetails(venueId: number, deviceKey: string): Promise<any> { // TODO: type
    const body = {venue_id: venueId, device_key: deviceKey, action: "fetch_softpay_terminal_details"};
    return this.post<any>(API_URL, body);
  }

  public async queryDiscountedItems(request: QueryBasicRequest): Promise<any> {
      const body = utils.appendActionToObject(request, "query_discounted_items");
      return this.post<any>(API_URL, body);
  }

  public async revokeDeviceAccess(venueId: number, deviceKey: string) {
    const body = {venue_id: venueId, device_key: deviceKey, action: "revoke_device_access"};
    return this.post<Device>(API_URL, body);
  }

  public async fetchVenueAuthToken(venueId: number): Promise<any> {
    const body = {venue_id: venueId, action: "fetch_venue_auth_token"};
    return this.post<any>(API_URL, body);
  }

  public async fetchLoyaltyCards(venueId: number): Promise<LoyaltyCard[]> {
    const body = {venue_id: venueId, action: "fetch_loyalty_cards"};
    return this.post<LoyaltyCard[]>(API_URL, body);
  }

  public async fetchLoyaltyCard(venueId: number, cardKey: string): Promise<LoyaltyCard> {
    const body = {venue_id: venueId, card_key: cardKey, action: "fetch_loyalty_card"};
    return this.post<LoyaltyCard>(API_URL, body);
  }

  public async updateLoyaltyCard(venueId: number, loyaltyCard: LoyaltyCard): Promise<LoyaltyCard> {
    const body = {venue_id: venueId, card: loyaltyCard, action: "update_loyalty_card"};
    return this.post<LoyaltyCard>(API_URL, body);
  }

  public async importLoyaltyCards(venueId: number, cards: any[]): Promise<any> {
    const body = {venue_id: venueId, action: "import_loyalty_cards", cards};
    return this.post<any>(API_URL, body);
  }

  public async rawWorkflow(venueId: number): Promise<any> {
    const body = {venue_id: venueId, action: "raw_workflow"};
    return this.post<any>(API_URL, body);
  }

  public async createVenue(newVenue: CreateVenueRequest): Promise<CreateVenueResponse> {
    const body = {...newVenue, action: "create_venue"};
    return this.post<CreateVenueResponse>(API_URL, body);
  }

  public async getVenueInfo(venueId: number): Promise<VenueInfo> {
    const body = {venue_id: venueId, action: "get_venue_info"};
    return this.post<VenueInfo>(API_URL, body);
  }

  public async updateVenue(venueId: number, venue: VenueInfo ): Promise<any> {
    const body = {venue_id: venueId, ...venue, action: "update_venue"};
    return this.post<VenueInfo>(API_URL, body);
  }

  public async queryBookings(venue_id: number, request: BookingRequest): Promise<BookingResponse> {
    const body = {venue_id, ...request, action: "query_bookings"};
    return this.post<BookingResponse>(API_URL, body);
  }

  public async fetchBookingInfo(venue_id: number, booking_key: string): Promise<Booking> {
    const body = {venue_id, booking_key, action: "fetch_booking_info"};
    return this.post<Booking>(API_URL, body);
  }

  public exportBookings(venue_id: number, request: BookingRequest, file_type: string, filename: string): Observable<any> {
    const body = {venue_id, ...request, action: "export_bookings", file_type};
    return this.downloadFileP(API_URL, body, filename);
  }

  public async fetchSmartTables(venue_id: number): Promise<SmartTable[]> {
    const body = {venue_id, action: "fetch_smart_tables"};
    return this.post<SmartTable[]>(API_URL, body);
  }

  public async getSmartTable(venue_id: number, key: string): Promise<SmartTable> {
    const body = {venue_id, key, action: "get_smart_table"};
    return this.post<SmartTable>(API_URL, body);
  }

  public async editSmartTable(venue_id: number, smartTable: EditSmartTableRequest): Promise<SmartTable> {
    const body = {venue_id, ...smartTable, action: "edit_smart_table"};
    return this.post<SmartTable>(API_URL, body);
  }

  public async deleteSmartTable(venue_id: number, key: string): Promise<any> {
    const body = {venue_id, key, action: "delete_smart_table"};
    return this.post<any>(API_URL, body);
  }

  public async createSSOToken(): Promise<any> {
    const body = {action: "create_sso_token"};
    return this.post<any>(API_URL, body);
  }

  public async fetchSubscribers(listName: string): Promise<FetchSubsResponse> {
    const body = {action: "fetch_subscribers", list_name: listName};
    return this.post<FetchSubsResponse>(API_URL, body);
  }

  public async addAdminsAsSubscribers(listName: string): Promise<any> {
    const body = {action: "add_admins_as_subscribers", list_name: listName};
    return this.post<any>(API_URL, body );
  }

  public async cleanupSubscribers(listName: string): Promise<any> {
    const body = {action: "cleanup_subscribers", list_name: listName};
    return this.post<any>(API_URL, body );
  }

  public async fetchDynamicImageLink(storageUrl: string, width: number): Promise<any> {
    const body = {action: "fetch_dynamic_image_link", storage_url: storageUrl, width};
    return this.post<any>(API_URL, body);
  }

  public async setupVivaStore(venue_id: number): Promise<any> {
    const body = {action: "setup_viva_store", venue_id};
    return this.post<any>(API_URL, body);
  }

  public async startVivaOnboarding(venue_id: number) {
    const body = {action: "start_viva_onboarding", venue_id};
    return this.post<any>(API_URL, body);
  }

  public async queryMenuSalesData(venue_id: number, request: QueryMenuSalesDataRequest): Promise<QueryMenuSalesDataResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_top_list");
    return this.post<QueryMenuSalesDataResponse>(API_URL, requestWithAction);
  }

  public async queryDayTimeArticleList(venue_id: string, request: QueryDayTimeArticleListRequest): Promise<QueryDayTimeArticleListResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_day_time_article_list");
    return this.post<QueryDayTimeArticleListResponse>(API_URL, requestWithAction);
  }

  public async queryReceiptArticleRows(venue_id: string, request: QueryDayTimeArticleListRequest): Promise<QueryDayTimeArticleListResponse> {
    const requestWithAction = utils.appendActionToObject(request, "query_receipt_article_rows");
    return this.post<QueryDayTimeArticleListResponse>(API_URL, requestWithAction);
  }

  public async mewsGetAccountingCategories(venue_id: number): Promise<MewsAccountingCategoriesResponse> {
    const body = {action: "mews_get_accounting_categories", venue_id};
    return this.post<MewsAccountingCategoriesResponse>(API_URL, body);
  }

  public async fetchMewsServices(venue_id: number): Promise<MewsServicesResponse> {
    const body = {action: "mews_fetch_services", venue_id};
    return this.post<MewsServicesResponse>(API_URL, body);
  }

  public async processAccountEntries(venueId: string, receiptKeys: string[], method: string, invoiceParams: any) {
    const body = {venue_id: venueId, receipt_keys: receiptKeys, action: "process_account_entries", method, parms: invoiceParams};
    return this.post<any>(API_URL, body);
  }

  public processAccountEntriesDownloadPDF(venueId: string, receiptKeys: string[], method: string, invoiceParams: any, filename: string): Observable<any> {
    const body = {venue_id: venueId, receipt_keys: receiptKeys, action: "process_account_entries", method, parms: invoiceParams, preview: "pdf"};
    return this.downloadFileP(API_URL, body, filename);
  }

  public previewFortnoxPDF(venueId: string, invcoieNumber: string, filename: string): Observable<any> {
    const body = {venue_id: venueId, invoice_number: invcoieNumber, action: "preview_fortnox_invoice"};
    return this.downloadFileP(API_URL, body, filename);
  }

  public async doMenuMagic(venueId: number, itemName: string) {
    const body = {venue_id: venueId, action: "do_menu_magic", item_name: itemName};
    return this.post<any>(API_URL, body);
  }

}
