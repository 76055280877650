<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button routerLink="/venue/{{venueId}}/floorplan-history" *ngIf="version!=null"><mat-icon>arrow_back_ios</mat-icon></button>
      <button mat-raised-button [matMenuTriggerFor]="menuSection">Sektion: {{selectedSection?.name}}<mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuSection="matMenu">
        <button mat-menu-item *ngFor="let section of selectableSections(currentData?.sections)" (click)="selectSection(section)">{{section.name}}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="menuHandleSection" *ngIf="currentData?.sections?.length>1 && version==null">Hantera sektioner</button>
        <button mat-menu-item (click)="addSection()" *ngIf="currentData?.sections?.length===1 && version==null">Ny sektion</button>
        <button mat-menu-item *ngIf="currentData?.sections?.length===1 && version==null" (click)="renameSection()">Byt namn på {{selectedSection?.name}}</button>
        <mat-divider></mat-divider>
        <button mat-menu-item *ngIf="currentData?.sections?.length===1 && version==null" (click)="deleteSection()">Ta bort {{selectedSection?.name}}</button>
      </mat-menu>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item (click)="addSection()">Ny sektion</button>
        <button mat-menu-item (click)="renameSection()">Byt namn på {{selectedSection?.name}}</button>
        <button mat-menu-item (click)="reorderSections()">Byt ordning sektioner</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="deleteSection()">Ta bort {{selectedSection?.name}}</button>
      </mat-menu>

      <span class="menu-divider" *ngIf="version==null"></span>
      <button mat-button *ngIf="version==null" (click)="undo()"><mat-icon>undo</mat-icon></button>
      <button mat-button *ngIf="version==null" (click)="redo()"><mat-icon>redo</mat-icon></button>
      <span class="menu-divider"></span>
      <button mat-raised-button *ngIf="version==null" (click)="addTable('rect')" class="mnu-btn"><mat-icon>crop_5_4</mat-icon> Bord</button>
      <button mat-raised-button *ngIf="version==null" (click)="addTable('circle')" class="mnu-btn"><mat-icon>circle</mat-icon> Bord</button>
      <button mat-raised-button *ngIf="version==null" (click)="toggleLine()" class="mnu-btn" [color]="activeLineMode"><mat-icon>edit</mat-icon> Penna</button>

      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer" section="support" path="floorplan"></app-wiki-info-link>

      <button mat-button routerLink="/venue/{{venueId}}/floorplan-history">
        <mat-icon>history</mat-icon> Historik
      </button>

      <button mat-button (click)="restoreVersion()" *ngIf="version!=null && !sameAsCurrent">
        <mat-icon>settings_backup_restore</mat-icon> Återställ
      </button>

      <button mat-button (click)="save()" *ngIf="version==null">
        <mat-icon>save</mat-icon> Spara
      </button>
    </mat-toolbar>
  </p>
  <div *ngIf="showErrorBanner" class="error-banner">
    <mat-icon>error</mat-icon>
    <span>
      Duplicate tables found! The following tables have duplicates:
      <ul>
        <li *ngFor="let duplicate of duplicateTables">
          <strong>{{ duplicate.duplicates.join(', ') }}</strong> in section {{ duplicate.sectionData.name }}
        </li>
      </ul>
    </span>
    <button mat-button (click)="dismissErrorBanner()">Dismiss</button>
  </div>
</div>

<div class="version-panel" *ngIf="floorplanVersion">
  <div>VERSION</div>
  <div>{{floorplanVersion.key}}</div>
  <div>{{floorplanVersion.date}}</div>
  <div>{{floorplanVersion.user_name}}</div>
</div>

<div class="diff-panel" *ngIf="sameAsCurrent">
  <b *ngIf="!restored">This is same as current published version</b>
  <b *ngIf="restored">This version has been restored</b>
  <div *ngIf="restored" style="margin-top: 16px;">
    <button mat-flat-button routerLink="/venue/{{venueId}}/floorplan-editor" color="primary">Return to restored version</button>
  </div>
</div>

<div *ngIf="errorMessage" class="error-panel">
  <pre>{{errorMessage}}</pre>
  <div class="error-close" (click)="closeError()"><mat-icon>close</mat-icon></div>
</div>

<div class="master">
  <div id="container" #container [ngClass]="{'pen':version==null,'dispen':version!=null}"></div>
  <div id="detail">
    <div class="header">Bordsinfo</div>
    <div class="det-cont" *ngIf="info">
      <mat-form-field class="det-field" appearance="outline">
        <mat-label>Namn</mat-label>
        <input matInput [(ngModel)]="info.name" [formControl]="tableName" (keyup)="changeName()" (focus)="focusIn()" (focusout)="focusOut()" [disabled]="version||hasLocCode">
        <div matSuffix *ngIf="hasLocCode" [matTooltip]="HAS_LOC_CODE_WARNING_TEXT">
          <mat-icon class="det-icon">warning</mat-icon>
        </div>
      </mat-form-field>
      <mat-form-field class="det-field" appearance="outline">
        <mat-label>Adress</mat-label>
        <input matInput [(ngModel)]="info.address" (keyup)="changeAddress()" (focus)="focusIn()" (focusout)="focusOut()" [disabled]="version||hasLocCode">
        <div matSuffix *ngIf="hasLocCode" [matTooltip]="HAS_LOC_CODE_WARNING_TEXT">
          <mat-icon class="det-icon">warning</mat-icon>
        </div>
      </mat-form-field>
      <div class="det-field det-padding" *ngIf="locCode">
        <div>
          <span>Webbkod: <span class="det-frame"><b>{{locCode}}</b></span></span>
        </div>
      </div>
      <div *ngIf="!(isRect && isCircle)">
        <div class="det-field">
          <span>Bordstyp</span>
        </div>
        <div class="det-padding">
          <button mat-flat-button class="dtl-btn" (click)="changeShape('rect')" [ngClass]="{'btn-active': isRect}"><mat-icon>crop_5_4</mat-icon></button>
          <button mat-flat-button class="dtl-btn" (click)="changeShape('circle')" [ngClass]="{'btn-active': isCircle}"><mat-icon>circle</mat-icon></button>
        </div>
      </div>
      <mat-form-field class="det-field" appearance="outline">
        <mat-label>Kortprefix (frivillig)</mat-label>
        <input matInput [(ngModel)]="info.aliasPrefix" (keyup)="changeAlias()" (focus)="focusIn()" (focusout)="focusOut()" [disabled]="version">
      </mat-form-field>
      <mat-form-field class="det-field" appearance="outline">
        <mat-label>Area (frivillig)</mat-label>
        <input matInput [(ngModel)]="info.area" (keyup)="changeArea()" (focus)="focusIn()" (focusout)="focusOut()" [disabled]="version">
      </mat-form-field>
      <mat-form-field class="det-field" appearance="outline">
        <mat-label>Stationer (frivillig)</mat-label>
        <input matInput [(ngModel)]="info.stations" (keyup)="changeStation()" (focus)="focusIn()" (focusout)="focusOut()" [disabled]="version">
      </mat-form-field>
      <mat-form-field class="det-field" *ngIf="resourceTypes && resourceTypes.length > 0" appearance="outline">
        <mat-label>Resurs</mat-label>
        <mat-select [(ngModel)]="info.resourceType" (selectionChange)="changeResource()" (focus)="focusIn()" (focusout)="focusOut()" [disabled]="version">
          <mat-option [value]="''">Av</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let resourceType of resourceTypes" [value]="resourceType">
            {{resourceType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="det-settings">
        <mat-button-toggle-group name="subDetailMenu" [(ngModel)]="subDetailMenu" style="margin-bottom: 8px;" (change)="subDetailMenuChanged()">
          <mat-button-toggle value="none">Alias</mat-button-toggle>
          <mat-button-toggle value="area">Area</mat-button-toggle>
          <mat-button-toggle value="station">Stations</mat-button-toggle>
        </mat-button-toggle-group>
        <div style="margin-left:-16px;margin-right: -32px;" *ngIf="subDetailMenu=='station'">
          <app-data-form #dfstations [schema]="dfsStations" [root]="stationList" (dataChanged)="collectStationData()" (selected)="focusIn()" (deselected)="focusOut()"></app-data-form>
        </div>
        <div *ngIf="subDetailMenu=='area'">
          <div *ngFor="let c of coloringList" style="padding: 8px 0;">
              <span style="display:inline-block;width:24px;height:24px;margin-right: 8px; border-radius: 16px;" [style.backgroundColor]="c.color">&nbsp;</span>{{c.name}}
          </div>
        </div>
        <div *ngIf="subDetailMenu=='none'">
          <mat-slide-toggle [(ngModel)]="showAlias" (ngModelChange)="toggleShowAlias()" color="primary" style="padding: 8px;">Visa kortnamn</mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="diff-panel" *ngIf="detailedDiffToCurrent">
  <b>Changes to current published version</b>
  <ngx-json-viewer [json]="detailedDiffToCurrent"></ngx-json-viewer>
</div>

<div class="diff-panel" *ngIf="floorplanVersion">
  <b>Changes this version did</b>
  <ngx-json-viewer [json]="floorplanVersion.diff"></ngx-json-viewer>
</div>
