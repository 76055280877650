export const pageLoyaltySettings = {
  title: {sv: "Lojalitetsprogram"},
  page_settings: {
    root_path: "loyalty",
  },
  desc: {sv: "Hantera lojalitetsprogram"},
  properties: [
    {key: "active", title: {sv: "Aktiverat"}, desc: {sv: ""}, type: "bool", optional: false, default: false},
    {key: "programs", title: {sv: "Lojalitetsprogram"}, desc: {sv: ""}, type: "table", optional: false,
      columns: [
        {key: "active", title: {sv: "Aktiverat"}, desc: {sv: ""}, type: "bool", optional: false, default: false},
        {key: "id", title: {sv: "ID"}, desc: {sv: ""}, type: "string", optional: false},
        {key: "name", title: {sv: "Namn"}, desc: {sv: ""}, type: "string", optional: false},
        {key: "description", title: {sv: "Beskrivning"}, desc: {sv: ""}, type: "string", optional: true},
        {key: "magstripe_regex", title: {sv: "Magnetremsa"}, desc: {sv: "Beskriver innehållet på magnetremsa"}, type: "string", optional: true},
        {key: "discount_program", title: {sv: "Rabattprogram"}, type: "config_enum", optional: true, config_path: "discount_programs", config_key: "id" },
      ]
    },
  ]
};
