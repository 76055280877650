<div class="receipts-container">

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Personal</mat-label>
        <mat-select [(value)]="staffId">
          <mat-option [value]="null">Ingen</mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let s of staff" [value]="s.identity">
            {{s.first_name}} {{s.last_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="filter" placeholder="Filter">
        <button matSuffix mat-icon-button aria-label="Clear" (click)="addFilter()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="fetchReceipts()" color="primary">SEARCH</button>
      <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="menuExport" *ngIf="fullCount">Exportera {{fullCount}} kvitton</button>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="export('csv')" *ngIf="fullCount">Kvittolista (csv)</button>
        <button mat-menu-item (click)="export('hogia')" *ngIf="fullCount">Fakturaunderlag för Hogia</button>
      </mat-menu>

    </div>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" (matSortChange)="sortChanged($event)" matSortActive="pay_date" matSortDirection="desc" matSortDisableClear="true">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="toggleSelectAll($event)" [checked]="isAllSelected()" color="primary"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelection(row)" [checked]="isSelected(row)" color="primary">
            </mat-checkbox>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="small-value">{{ totalReceiptsCount }}</div><span class="small-info">selected</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="serial_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial Number</th>
          <td mat-cell *matCellDef="let element">{{ element.serial_number }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="kind">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Kind</th>
          <td mat-cell *matCellDef="let element"> {{ element.kind }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount</th>
          <td mat-cell *matCellDef="let element"> {{ element.amount | moneySuff : null : "kr"}} </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="small-value">{{ totalAmount }}</div><span class="small-info">amount sum</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="sales">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sales</th>
          <td mat-cell *matCellDef="let element"> {{ element.sales | moneySuff : null : "kr"}} </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="small-value">{{ totalSales }}</div><span class="small-info">sales sum</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="tip">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip</th>
          <td mat-cell *matCellDef="let element"> {{ element.tip | moneySuff : null : "kr"}} </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="small-value">{{ totalTip }}</div><span class="small-info">tip sum</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="vat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> vat</th>
          <td mat-cell *matCellDef="let element"> {{ element.vat | number : '1.2' }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="staff_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Personal</th>
          <td mat-cell *matCellDef="let element"> {{ element.staff_name }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="table">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>table</th>
          <td mat-cell *matCellDef="let element">{{ element.table }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="method">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> method</th>
          <td mat-cell *matCellDef="let element"> {{ element.method }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="pay_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> pay date</th>
          <td mat-cell *matCellDef="let element"> {{ element.pay_date |date:'medium' }} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [class.selected-row]="selectedRow === row.receipt_id"></tr>
        <tr mat-footer-row *matFooterRowDef="totalReceiptsCount > 0 ? displayedColumns : []"></tr>

      </table>

      <div class="summary-paginator-container">
        <mat-paginator *ngIf="dataSource" [length]="getTotalSize()" [pageSize]="30"
                       [pageSizeOptions]="[15, 30, 50, 100, 500, 2000]"
                       (page)="updateReceipts()"></mat-paginator>
      </div>
    </div>
    <div *ngIf="receiptKey" class="generic-detail">
      <div class="lr-detail-container">
        <app-receipt-view [venueId]="venueIdNum" [receiptKey]="receiptKey" [isSubComponent]="false" [showActionsBar]="true" [refundId]="refundId"></app-receipt-view>
      </div>
    </div>

  </div>

</div>
