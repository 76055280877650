import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import {VenueListComponent} from "./components/venue-list/venue-list.component";
import { ZreportsComponent } from './components/zreports/zreports.component';
import { ClientRecordsComponent } from './components/client-records/client-records.component';
import { NewSettlementComponent } from './components/new-settlement/new-settlement.component';
import { SettlementsComponent } from './components/settlements/settlements.component';
import { PayoutBatchesComponent } from './components/payout-batches/payout-batches.component';
import { BankTransactionsComponent } from './components/bank-transactions/bank-transactions.component';
import {DeviceStatusComponent} from "./components/device-status/device-status.component";
import {ClientListComponent} from "./components/client-list/client-list.component";
import {ReceiptListComponent} from "./components/receipt-list/receipt-list.component";
import {TermtxsComponent} from "./components/debug/termtxs/termtxs.component";
import {PrintJobListComponent} from "./components/print-job-list/print-job-list.component";
import {EmbeddedMenuComponent} from "./components/embedded-menu/embedded-menu.component";
import {ConfigViewComponent} from "./components/debug/config-view/config-view.component";
import {FloorplanComponent} from "./components/floorplan/floorplan.component";
import {KdsBongListComponent} from "./components/kds-bong-list/kds-bong-list.component";
import {EmbeddedPdfsComponent} from "./components/embedded-pdfs/embedded-pdfs.component";
import {OrderListComponent} from "./components/order-list/order-list.component";
import {UserListComponent} from "./components/user-list/user-list.component";
import { LinkAccountComponent } from './components/link-account/link-account.component';
import { MainContentComponent } from './components/main-content/main-content.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthGuard } from './guards/auth.guard';
import { VenueUserListComponent } from './components/user-list/venue-user-list/venue-user-list.component';
import { LiveDashboardComponent } from './components/live-dashboard/live-dashboard.component';
import {EventListComponent} from "./components/event-list/event-list.component";
import {MyAccountComponent} from "./components/my-account/my-account.component";
import {StaffListComponent} from "./components/staff-list/staff-list.component";
import {MenuDashComponent} from "./components/catalog/menu-dash/menu-dash.component";
import {MenuTabComponent} from "./components/catalog/menu-tab/menu-tab.component";
import {MenuOptionsTabComponent} from "./components/catalog/menu-options-tab/menu-options-tab.component";
import {MenuPushpagesTabComponent} from "./components/catalog/menu-pushpages-tab/menu-pushpages-tab.component";
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {TableProbeComponent} from "./components/table-probe/table-probe.component";
import {OrderListActivityComponent} from "./components/order-list/order-list-activity/order-list-activity.component";
import {EventListActivityComponent} from "./components/event-list/event-list-activity/event-list-activity.component";
import {PrintJobListActivityComponent} from "./components/print-job-list/print-job-list-activity/print-job-list-activity.component";
import {OrderingPadComponent} from "./components/pos/ordering-pad/ordering-pad.component";
import {SettingsComponent} from "./components/settings/settings/settings.component";
import {SettingsDeactivateGuard} from "./guards/settings-deactivate.guard";
import {SettingsHistoryListComponent} from "./components/settings/settings-history-list/settings-history-list.component";
import {PosComponent} from "./components/pos/pos/pos.component";
import {FloorplanActivityComponent} from "./components/floorplan/floorplan-activity/floorplan-activity.component";
import {DiscountCodeListComponent} from "./components/discount-code-list/discount-code-list.component";
import {LiveReceiptListActivityComponent} from "./components/live-receipt-list/live-receipt-list-activity/live-receipt-list-activity.component";
import {ReceiptExplorerComponent} from "./components/admin/receipt-explorer/receipt-explorer.component";
import { InvoicesComponent } from './components/invoices/invoices.component';
import { BillingComponent } from './components/billing/billing.component';
import {KpiDashboardComponent} from "./components/kpi/kpi-dashboard/kpi-dashboard.component";
import {TicketListComponent} from "./components/ticket-list/ticket-list.component";
import {SlotCalendarComponent} from "./components/booking/slot-calendar/slot-calendar.component";
import {FloorplanEditorComponent} from "./components/floorplan-editor/floorplan-editor.component";
import {FloorplanHistoryListComponent} from "./components/floorplan-editor/floorplan-history-list/floorplan-history-list.component";
import {MenuHistoryListComponent} from "./components/catalog/menu-history-list/menu-history-list.component";
import {MenuVersionComponent} from "./components/catalog/menu-version/menu-version.component";
import {VenueCustomersComponent} from "./components/settings/venue-customers/venue-customers.component";
import {ImageBankComponent} from "./components/image-bank/image-bank.component";
import {VenueComponent} from "./components/venue/venue.component";
import {MenuTabSettingsComponent} from "./components/catalog/menu-tab-settings/menu-tab-settings.component";
import {PrepaidListComponent} from "./components/prepaid-list/prepaid-list.component";
import {CardBalanceComponent} from './components/reports/card-balance/card-balance.component';
import {ReportDashComponent} from "./components/reports/report-dash/report-dash.component";
import {ReportTreeComponent} from "./components/reports/report-tree/report-tree.component";
import {ReportHourlyComponent} from "./components/reports/report-hourly/report-hourly.component";
import {ReportCustomerComponent} from "./components/reports/report-customer/report-customer.component";
import {ReportStaffComponent} from './components/reports/report-staff/report-staff.component';
import {ReportZsummaryComponent} from "./components/reports/report-zsummary/report-zsummary.component";
import {EditSlotsComponent} from "./components/booking/edit-slots/edit-slots.component";
import {ApiTokenListComponent} from "./components/admin/api-token-list/api-token-list.component";
import {SlotDayComponent} from "./components/booking/slot-day/slot-day.component";
import {FortnoxComponent} from "./components/integration/fortnox/fortnox.component";
import {TokenEndpointSettingsComponent} from "./components/admin/api-token-list/token-endpoint-settings/token-endpoint-settings.component";
import {LandingComponent} from "./components/start-page/landing/landing.component";
import {LandingStartComponent} from "./components/start-page/landing-start/landing-start.component";
import {LandingNextComponent} from "./components/start-page/landing-next/landing-next.component";
import {LandingGoComponent} from "./components/start-page/landing-go/landing-go.component";
import {BreezeFrameComponent} from "./components/breeze-frame/breeze-frame.component";
import {VenueCronsComponent} from "./components/debug/venue-crons/venue-crons.component";
import {VenueMessageListComponent} from "./components/admin/venue-message-list/venue-message-list.component";
import { AdyenComponent } from './components/integration/adyen/adyen.component';
import {VoucherListComponent} from "./components/voucher-list/voucher-list.component";
import {ReportTimeseriesComponent} from "./components/reports/report-timeseries/report-timeseries.component";
import {ReportDailyComponent} from './components/reports/report-daily/report-daily.component';
import { SkvComponent } from './components/skv/skv.component';
import {RegunitListComponent} from "./components/admin/regunit-list/regunit-list.component";
import {VenueTransactionsComponent} from './components/reports/venue-transactions/venue-transactions.component';
import {ReportEventsComponent} from './components/reports/report-events/report-events.component';
import {WikiDocumentEditorComponent} from "./components/heywiki/wiki-document-editor/wiki-document-editor.component";
import {WikiDocDisplayComponent} from "./components/heywiki/wiki-doc-display/wiki-doc-display.component";
import {WikiImagesComponent} from "./components/heywiki/wiki-images/wiki-images.component";
import {ReportClassicStaffSummaryComponent} from './components/reports/report-classic-staff-summary/report-classic-staff-summary.component';
import {FilterBuilderComponent} from "./components/debug/filter-builder/filter-builder.component";
import {VenueCcuComponent} from "./components/integration/venue-ccu/venue-ccu.component";
import {DeviceListComponent} from "./components/device/device-list/device-list.component";
import {DeviceEditorComponent} from "./components/device/device-editor/device-editor.component";
import {ReportCheckinsComponent} from './components/reports/report-checkins/report-checkins.component';
import { ReportDiscountedItemsComponent } from './components/reports/report-discounted-items/report-discounted-items.component';
import {RegTemplateComponent} from "./components/device/reg-template/reg-template.component";
import {MiniTicketsComponent} from './components/admin/mini-tickets/mini-tickets.component';
import { OrdersFrameComponent } from './orders-frame/orders-frame.component';
import { SoftpayTerminalsComponent } from './components/softpay/softpay-terminals/softpay-terminals.component';
import { SoftpayTerminalDetailsComponent } from './components/softpay/softpay-terminal-details/softpay-terminal-details.component';
import {XreportComponent} from "./components/zreports/xreport/xreport.component";
import {LoyaltyListComponent} from './components/loyalty/loyalty-list/loyalty-list.component';
import {LoyaltyEditorComponent} from './components/loyalty/loyalty-editor/loyalty-editor.component';
import {LoyaltyCardImportComponent} from "./components/loyalty/loyalty-card-import/loyalty-card-import.component";
import { SuperDeviceListComponent } from './components/device/super-device-list/super-device-list.component';
import { FbBotListComponent } from './components/admin/fb-bots/fb-bot-list/fb-bot-list.component';
import { EditFbBotComponent } from './components/admin/fb-bots/edit-fb-bot/edit-fb-bot.component';
import {ChainListComponent} from "./components/admin/chain-list/chain-list.component";
import {ChainSettingsComponent} from "./components/admin/chain-list/chain-settings/chain-settings.component";
import {WorkflowEditorComponent} from "./components/settings/workflow-editor/workflow-editor.component";
import {SimpleEmailEditorComponent} from "./components/admin/simple-email-editor/simple-email-editor.component";
import { VenueEditorComponent } from './components/settings/venue-editor/venue-editor.component';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { VenueCustomersListComponent } from './components/settings/venue-customers/venue-customers-list/venue-customers-list.component';
import { EditVenueCustomerComponent } from './components/settings/venue-customers/edit-venue-customer/edit-venue-customer.component';
import { SmartTableListComponent } from './components/smart-tables/smart-table-list/smart-table-list.component';
import { EditSmartTableComponent } from './components/smart-tables/edit-smart-table/edit-smart-table.component';
import {SubscribeComponent} from "./components/subscribe/subscribe.component";
import {SubscriberListComponent} from "./components/admin/subscriber-list/subscriber-list.component";
import {WikiDbComponent} from "./components/admin/wiki-db/wiki-db.component";
import {FilterEditorComponent} from "./components/filter-editor/filter-editor.component";
import { IcTreeViewComponent } from './components/ic-tree-view/ic-tree-view.component';
import { ReportMenuSalesComponent } from './components/reports/report-menu-sales/report-menu-sales.component';
import { ReportSalesDatetimeComponent } from './components/reports/report-sales-datetime/report-sales-datetime.component';
import {ChainDeviceStatusComponent} from "./components/device-status/chain-device-status/chain-device-status.component";
import { AdminAccountsListComponent } from './components/admin/admin-accounts-list/admin-accounts-list.component';
import {AccountComponent} from "./components/account/account.component";
import { OpeningHoursComponent } from './components/opening-hours/opening-hours.component';
import {MewsServicesComponent} from "./components/integration/mews-services/mews-services.component";
import {SyncAccountsComponent} from "./components/account/sync-accounts/sync-accounts.component";
import {ReportReceiptItemRowsComponent} from "./components/reports/report-receipt-item-rows/report-receipt-item-rows.component";

const routes: Routes = [
  {
    path: "", component: MainContentComponent, canActivate: [AuthGuard],
    children: [
      { path: "venue/:venue_id", component: VenueComponent, canActivate: [AuthGuard],
        children: [
          { path: "dashboard", component: LiveDashboardComponent, canActivate: [AuthGuard] },
          { path: "zreports", component: ZreportsComponent, canActivate: [AuthGuard] },
          { path: "zreports/:key", component: ZreportsComponent, canActivate: [AuthGuard] },
          { path: "xreport", component: XreportComponent, canActivate: [AuthGuard] },
          { path: "device-status", component: DeviceStatusComponent, canActivate: [AuthGuard] },
          { path: "chain-device-status", component: ChainDeviceStatusComponent, canActivate: [AuthGuard] },
          { path: "receipt-list", component: ReceiptListComponent, canActivate: [AuthGuard] },
          { path: "receipt-list/:receipt_key", component: ReceiptListComponent, canActivate: [AuthGuard] },
          { path: "order-list", component: OrderListActivityComponent, canActivate: [AuthGuard] },
          { path: "prepaid-list", component: PrepaidListComponent, canActivate: [AuthGuard] },
          { path: "card-balance", component: CardBalanceComponent, canActivate: [AuthGuard] },

          { path: "live-receipt-list/:receipt_key", component: LiveReceiptListActivityComponent, canActivate: [AuthGuard] },
          { path: "event-list", component: EventListComponent, canActivate: [AuthGuard] },
          { path: "term-txs", component: TermtxsComponent, canActivate: [AuthGuard] },
          { path: "venue-crons", component: VenueCronsComponent, canActivate: [AuthGuard] },
          { path: "filter-builder", component: FilterBuilderComponent, canActivate: [AuthGuard] },
          { path: "filter-editor", component: FilterEditorComponent, canActivate: [AuthGuard] },
          { path: "print-jobs", component: PrintJobListComponent, canActivate: [AuthGuard] },
          { path: "kds-bongs", component: KdsBongListComponent, canActivate: [AuthGuard] },
          { path: "embedded-menu", component: EmbeddedMenuComponent, canActivate: [AuthGuard] },
          { path: "embedded-pdfs", component: EmbeddedPdfsComponent, canActivate: [AuthGuard] },
          { path: "raw-config", component: ConfigViewComponent, canActivate: [AuthGuard] },
          { path: "floorplan", component: FloorplanComponent, canActivate: [AuthGuard] },
          { path: "skv", component: SkvComponent, canActivate: [AuthGuard] },
          { path: "probe/:table", component: TableProbeComponent, canActivate: [AuthGuard] },

          { path: "admins", component: UserListComponent, canActivate: [AuthGuard] },
          { path: "staff", component: StaffListComponent, canActivate: [AuthGuard] },
          { path: "devices", component: DeviceListComponent, canActivate: [AuthGuard] },
          { path: "devices/:device_key", component: DeviceEditorComponent, canActivate: [AuthGuard] },
          { path: "register-device/:device_key", component: RegTemplateComponent, canActivate: [AuthGuard] },
          { path: "adyen", component: AdyenComponent, canActivate: [AuthGuard] },
          { path: "softpay-terminals", component: SoftpayTerminalsComponent, canActivate: [AuthGuard] },
          { path: "softpay-terminals/:key", component: SoftpayTerminalDetailsComponent, canActivate: [AuthGuard] },
          { path: "loyalty", component: LoyaltyListComponent, canActivate: [AuthGuard] },
          { path: "loyalty/:card_key", component: LoyaltyEditorComponent, canActivate: [AuthGuard] },
          { path: "loyalty-import", component: LoyaltyCardImportComponent, canActivate: [AuthGuard] },
          { path: "smart-tables", component: SmartTableListComponent, canActivate: [AuthGuard]},
          { path: "smart-tables/:key", component: EditSmartTableComponent, canActivate: [AuthGuard], canDeactivate: [SettingsDeactivateGuard]},

          { path: "discount-codes", component: DiscountCodeListComponent, canActivate: [AuthGuard] },
          { path: "pos/:mode/:table", component: PosComponent, canActivate: [AuthGuard] },
          { path: "settings/:page", component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [SettingsDeactivateGuard] },
          { path: "settings-history/:page", component: SettingsHistoryListComponent, canActivate: [AuthGuard] },
          { path: "venue-customers-import", component: VenueCustomersComponent, canActivate: [AuthGuard], canDeactivate: [SettingsDeactivateGuard] },
          { path: "venue-customers", component: VenueCustomersListComponent, canActivate: [AuthGuard] },
          { path: "venue-customers/:key", component: EditVenueCustomerComponent, canActivate: [AuthGuard], canDeactivate: [SettingsDeactivateGuard] },
          { path: "workflow-editor", component: WorkflowEditorComponent, canActivate: [AuthGuard], canDeactivate: [SettingsDeactivateGuard] },
          { path: "venue-info", component: VenueEditorComponent, canActivate: [AuthGuard] },
          { path: "account/:account_id", component: AccountComponent, canActivate: [AuthGuard] },
          { path: "sync-accounts", component: SyncAccountsComponent, canActivate: [AuthGuard] },

          { path: "tickets", component: TicketListComponent, canActivate: [AuthGuard] },
          { path: "booking-list", component: BookingListComponent, canActivate: [AuthGuard] },
          { path: "booking-list/:booking_id", component: BookingListComponent, canActivate: [AuthGuard] },
          { path: "slot-calendar", component: SlotCalendarComponent, canActivate: [AuthGuard] },
          { path: "slot-day/:ns/:date", component: SlotDayComponent, canActivate: [AuthGuard] },
          { path: "edit-slots", component: EditSlotsComponent, canActivate: [AuthGuard] },
          { path: "floorplan-editor", component: FloorplanEditorComponent, canActivate: [AuthGuard], canDeactivate: [SettingsDeactivateGuard] },
          { path: "floorplan-history", component: FloorplanHistoryListComponent, canActivate: [AuthGuard] },
          { path: "images", component: ImageBankComponent, canActivate: [AuthGuard] },
          { path: "vouchers", component: VoucherListComponent, canActivate: [AuthGuard] },

          { path: "reports/dash", component: ReportDashComponent, canActivate: [AuthGuard] },
          { path: "reports/tree", component: ReportTreeComponent, canActivate: [AuthGuard] },
          { path: "reports/hourly", component: ReportHourlyComponent, canActivate: [AuthGuard] },
          { path: "reports/daily", component: ReportDailyComponent, canActivate: [AuthGuard] },
          { path: "reports/sales-datetime", component: ReportSalesDatetimeComponent, canActivate: [AuthGuard] },
          { path: "reports/receipt-item-rows", component: ReportReceiptItemRowsComponent, canActivate: [AuthGuard] },
          { path: "reports/ts", component: ReportTimeseriesComponent, canActivate: [AuthGuard] },
          { path: "reports/customers", component: ReportCustomerComponent, canActivate: [AuthGuard] },
          { path: "reports/staff", component: ReportStaffComponent, canActivate: [AuthGuard] },
          { path: "reports/staff-classic", component: ReportClassicStaffSummaryComponent, canActivate: [AuthGuard] },
          { path: "reports/zsummary", component: ReportZsummaryComponent, canActivate: [AuthGuard] },
          { path: "reports/venue-transactions", component: VenueTransactionsComponent, canActivate: [AuthGuard] },
          { path: "reports/venue-transactions/:key", component: VenueTransactionsComponent, canActivate: [AuthGuard] },
          { path: "reports/events", component: ReportEventsComponent, canActivate: [AuthGuard] },
          { path: "reports/discounted-items", component: ReportDiscountedItemsComponent, canActivate: [AuthGuard] },
          { path: "reports/checkins", component: ReportCheckinsComponent, canActivate: [AuthGuard] },
          { path: "reports/menu-sales", component: ReportMenuSalesComponent, canActivate: [AuthGuard]},
          { path: "fortnox", component: FortnoxComponent, canActivate: [AuthGuard] },
          { path: "breeze", component: BreezeFrameComponent, canActivate: [AuthGuard] },
          { path: "venue-ccu", component: VenueCcuComponent, canActivate: [AuthGuard] },

          { path: "icclasses", component: IcTreeViewComponent, canActivate: [AuthGuard]},
          { path: "opening-hours", component: OpeningHoursComponent, canActivate: [AuthGuard]},

          { path: "mews-services", component: MewsServicesComponent, canActivate: [AuthGuard]},
        ],
      },
      { path: "client-records", component: ClientRecordsComponent, canActivate: [AuthGuard] },
      { path: "new-settlement", component: NewSettlementComponent, canActivate: [AuthGuard] },
      { path: "settlements", component: SettlementsComponent, canActivate: [AuthGuard] },
      { path: "payout-batches", component: PayoutBatchesComponent, canActivate: [AuthGuard] },
      { path: "bank-transactions", component: BankTransactionsComponent, canActivate: [AuthGuard] },
      { path: "venues", component: VenueListComponent, canActivate: [AuthGuard] },
      { path: "clients", component: ClientListComponent, canActivate: [AuthGuard] },
      { path: "admin-accounts", component: AdminAccountsListComponent, canActivate: [AuthGuard] },
      { path: "admins", component: UserListComponent, canActivate: [AuthGuard] },
      { path: "account", component: MyAccountComponent, canActivate: [AuthGuard] },
      { path: "invoices", component: InvoicesComponent, canActivate: [AuthGuard] },
      { path: "billing", component: BillingComponent, canActivate: [AuthGuard] },
      { path: "kpi/dashboard", component: KpiDashboardComponent, canActivate: [AuthGuard] },
      { path: "tickets", component: TicketListComponent, canActivate: [AuthGuard] },
      { path: "mini-tickets", component: MiniTicketsComponent, canActivate: [AuthGuard] },
      { path: "tokens", component: ApiTokenListComponent, canActivate: [AuthGuard] },
      { path: "tokens/:token", component: TokenEndpointSettingsComponent },
      { path: "receipt-explorer", component: ReceiptExplorerComponent, canActivate: [AuthGuard] },
      { path: "venue-messages", component: VenueMessageListComponent, canActivate: [AuthGuard] },
      { path: "regunit-list", component: RegunitListComponent, canActivate: [AuthGuard] },
      { path: "edit-wiki/:section/:path", component: WikiDocumentEditorComponent, canActivate: [AuthGuard] },
      { path: "wiki-images", component: WikiImagesComponent, canActivate: [AuthGuard] },
      { path: "fb-bots", component: FbBotListComponent, canActivate: [AuthGuard]},
      { path: "fb-bots/bot/:id", component: EditFbBotComponent, canActivate: [AuthGuard]},
      { path: "email-editor", component: SimpleEmailEditorComponent, canActivate: [AuthGuard]},
      { path: "subscriber-list/:listname", component: SubscriberListComponent, canActivate: [AuthGuard]},
      { path: "wiki-db", component: WikiDbComponent, canActivate: [AuthGuard]},

      { path: "admin-devices", component: SuperDeviceListComponent, canActivate: [AuthGuard] },
      { path: "admin-devices/:venue_id/devices/:device_key", component: DeviceEditorComponent, canActivate: [AuthGuard] },
      { path: "chains", component: ChainListComponent, canActivate: [AuthGuard] },
      { path: "chains/:chain_key", component: ChainSettingsComponent },
      { path: "doc/:section/:path", component: WikiDocDisplayComponent, canActivate: [AuthGuard] },
    ],
  },
  {
    path: "catalog/:venue_id", component: MenuDashComponent, canActivate: [AuthGuard],
    children: [
      { path: "tab/:tab_id", component: MenuTabComponent, canActivate: [AuthGuard] },
      { path: "tab-settings/:tab_id", component: MenuTabSettingsComponent, canActivate: [AuthGuard], canDeactivate: [SettingsDeactivateGuard] },
      { path: "options_tab", component: MenuOptionsTabComponent, canActivate: [AuthGuard] },
      { path: "pushpages_tab", component: MenuPushpagesTabComponent, canActivate: [AuthGuard] },
      { path: "history", component: MenuHistoryListComponent, canActivate: [AuthGuard] },
      { path: "version/:version", component: MenuVersionComponent, canActivate: [AuthGuard] },
      { path: "images", component: ImageBankComponent, canActivate: [AuthGuard] },
      { path: "settings/:page", component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [SettingsDeactivateGuard] },
    ],
  },
  { path: "registration/:link_token", component: LinkAccountComponent },
  { path: "signin", component: SignInComponent },
  { path: "reset", component: ResetPasswordComponent },
  { path: "change-password", component: ChangePasswordComponent },
  { path: "welcome", component: LandingComponent,
    children: [
      { path: "start", component: LandingStartComponent },
      { path: "next/:lead_key", component: LandingNextComponent},
      { path: "go/:lead_key", component: LandingGoComponent},
    ]
  },
  { path: "skv/:venue_id", component: SkvComponent},
  { path: "pos/:venue_id", component: BreezeFrameComponent},
  { path: "orders/:venue_id/:identity", component: OrdersFrameComponent},
  { path: "list/:name/:action", component: SubscribeComponent},

];

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration), NgMaterialMultilevelMenuModule, BrowserModule],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppRoutingModule { }
