import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {Subscription, switchMap} from "rxjs";
import * as _ from "lodash";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { VenueCustomer } from 'src/app/models/venue-customers';
import { VenueService } from 'src/app/services/venue.service';
import { SimpleDialogComponent } from 'src/app/components/simple-dialog/simple-dialog.component';
import { VenueConfig } from 'src/app/models/venue-config';
import { FireService } from 'src/app/services/fire.service';

@Component({
  selector: 'app-venue-customers-list',
  templateUrl: './venue-customers-list.component.html',
  styleUrls: ['./venue-customers-list.component.css']
})
export class VenueCustomersListComponent implements OnInit, OnDestroy {
  venueId: number;
  venueCustomers: VenueCustomer[];
  errorMessage: string;
  loadedWithoutErrors = false;
  dataSource: MatTableDataSource<VenueCustomer>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    "org_number", "name", "email", "phone", "contact_person", "address", "postal_number", "city", "country", "accounting_id", "price_list",
  ];

  private sub: Subscription;
  filterUnhandled = false;
  cfg: VenueConfig;

  constructor(private venueService: VenueService, private route: ActivatedRoute,
              private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar, private fire: FireService) { }

  ngOnInit(): void {
    this.filterUnhandled = localStorage.getItem("filter_unhandled_accounts") === "true";
    this.sort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
    this.sub = this.route.paramMap.pipe(
      switchMap(data => {
        this.venueId = Number(data.get("venue_id"));
        return this.fire.observeVenueConfig(this.venueId);
      })
    ).subscribe(cfg => {
      this.cfg = cfg;
      this.fetchCustomers();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private fetchCustomers() {
    const chainPath = undefined;
    const filter = this.filterUnhandled ? "unhandled" : undefined;
    this.venueService.fetchVenueCustomers(this.venueId, chainPath, filter).then(res => {
      console.log("fetchVenueCustomers", res);
      this.dataSource = new MatTableDataSource(res.customers as VenueCustomer[]);
      this.dataSource.sort = this.sort;

      this.venueCustomers = res.customers;
      this.loadedWithoutErrors = true;
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  updateCustomer(customer?: VenueCustomer) {
    if (customer) {
      console.log("updateCustomer", customer);
      this.router.navigateByUrl(`/venue/${this.venueId}/venue-customers/${customer?.key}`);
    } else {
      console.log("Create new customer");
      this.router.navigateByUrl(`/venue/${this.venueId}/venue-customers/new:`);
    }
  }

  duplicateCustomer(customer: VenueCustomer) {
    console.log("duplicateCustomer", customer);
    this.router.navigateByUrl(`/venue/${this.venueId}/venue-customers/dup:${customer.key}`);
  }

  deleteCustomer(customer: VenueCustomer) {
    console.log("deleteCustomer", customer);
    this.venueService.deleteVenueCustomer(this.venueId, customer.key, undefined).then(() => {
      this.fetchCustomers();
      this.snackBar.open(`Konto ${customer.name} borttaget`, "", { duration: 3000 });
    }
    ).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  select(row) {
    this.router.navigateByUrl(`/venue/${this.venueId}/account/${row.key}`);
  }

  filterAccounts() {
    console.log("filterAccounts", this.filterUnhandled);
    localStorage.setItem("filter_unhandled_accounts", this.filterUnhandled.toString());
    this.fetchCustomers();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
