<div class="detail-container">
  <div *ngIf="inPos" class="detail-toolbar payment-toolbar"></div>
  <div *ngIf="showActionsBar" class="toolbar">
    <button mat-button (click)="sendReceipt()"><mat-icon class="icon">outgoing_mail</mat-icon>Skicka</button>
    <button mat-button *ngIf="postActions" (click)="sendLinks()"><mat-icon class="icon">outgoing_mail</mat-icon>Länkar</button>
    <button mat-button *ngIf="fortnoxInvoiceNumber" (click)="previewFortnox()"><mat-icon class="icon">picture_as_pdf</mat-icon>Faktura</button>
    <button mat-button *ngIf="!isSubComponent && refundId" (click)="openReceiptDialog()"><mat-icon>receipt_long</mat-icon>Originalkvitto</button>
    <a mat-button *ngIf="isSuperAdmin()" [routerLink]="['/receipt-explorer']" [queryParams]="{query: receiptKey}">Explorer</a>
  </div>
  <div class="detail-body" style="position: relative; padding-bottom: 60px;">
    <app-dossier-viewer *ngIf="receiptDossier" [printInstructions]="receiptDossier.instructions"></app-dossier-viewer>
    <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
  </div>

</div>
