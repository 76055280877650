export const pageHotsoftSettings = {
  title: {sv: "Hotsoft inställningar"},
  page_settings: {
    root_path: "hotsoft"
  },
  desc: {sv: "Inställningar för Hotsoft integration"},
  properties: [
    {key: "active", title: {sv: "Aktiv"}, desc: {sv: ""}, type: "bool", optional: false},
    {key: "host", title: {sv: "Host"}, desc: {sv: ""}, type: "string", optional: false},
    {key: "port", title: {sv: "Port"}, desc: {sv: ""}, type: "integer", optional: false},
    {key: "require_charge_slip", title: {sv: "Tvinga förnota vid debitering"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "default_cost_center", title: {sv: "Default cost center"}, desc: {sv: ""}, type: "string", optional: true},
  ]
};
