export const pageMewsSettings = {
  title: {sv: "Mews inställningar"},
  page_settings: {
    root_path: "mews"
  },
  desc: {sv: "Inställningar för MEWS integration"},
  properties: [
    {key: "client_token", title: {sv: "Client Token"}, desc: {sv: ""}, type: "string", optional: false},
    {key: "access_token", title: {sv: "Access Token"}, desc: {sv: ""}, type: "string", optional: false},
    {key: "service_id", title: {sv: "Service Id"}, desc: {sv: "Service id som skall användas vid rumsdebitering"}, type: "string", optional: true},
    {key: "order_aggregate_item_id", title: {sv: "Order aggregate id"}, desc: {sv: "Anges om man vill slåihop alla artiklar för rumsdebitering till en enda post. Ange produktens id som står menyn."}, type: "string", optional: true},
    {key: "outlet_id", title: {sv: "Outlet Id"}, desc: {sv: "Anges om man avser att skicka alla externa betalningar som outlet bills till mews"}, type: "string", optional: true},
    {key: "require_charge_slip", title: {sv: "Tvinga förnota vid debitering"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "environment", title: {sv: "Environment"}, desc: {sv: "Går att 'göra override' på mews environment. Sätt till demo för mews demo miljö."}, type: "enum", optional: true, values: [
        {key: "demo", title: {sv: "Demo miljö"}},
        {key: "production", title: {sv: "Produktions miljö"}},
      ]},
  ]
};
