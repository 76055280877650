export const pageInhouseSettings = {
  title: { sv: "Inhouse" },
  page_settings: {
    root_path: "inhouse"
  },
  desc: { sv: "Inställningar för Inhouse" },
  properties: [
    { key: "anon_orderable", title: { sv: "Tillåt beställningar utan inlogg" }, desc: { sv: "Förenklar beställning för gäster, kan dock göra det lite svårare att hitta en beställning i t.ex. KDS:en. Gäller både öppen och stängd nota." }, type: "bool", optional: true },
    { key: "anon_orderable_closed_bill", title: { sv: "Tillåt beställningar utan inlogg för stängda notor" }, desc: { sv: "Anonyma beställningar endast för stängd nota. Var noga med att INTE klicka i checkboxen ovan." }, type: "bool", optional: true },
    { key: "active", title: { sv: "Aktivt" }, desc: { sv: "Anger om inhouse skall vara aktiverat" }, type: "bool", optional: true },
    { key: "dont_show_time_inhouse", title: { sv: "Dölj tid" }, desc: { sv: "Dölj tid för inhouse" }, type: "bool" },
    { key: "expected_delivery_time", title: { sv: "Förväntad leveranstid" }, desc: { sv: "Förväntad leveranstid i minuter. Obs! Ändringar görs i appen" }, type: "integer", suffix: "min", hidden: true },
    { key: "kitchen_time", title: { sv: "Kökets öppettider" }, desc: { sv: "" }, type: "table", optional: true,
      columns: [
        { key: "day", title: { sv: "Dag" }, type: "integer[]", width: 100 },
        { key: "start", title: { sv: "Öppning" }, type: "integer", width: 100 },
        { key: "stop", title: { sv: "Stängning" }, type: "integer", width: 100 },
      ]
    },
    { key: "show_order_number", title: { sv: "Visa ordernummer bordsbeställning" }, desc: { sv: "Visa ordernummer i Inhouse" }, type: "bool", optional: true },
    { key: "show_order_number_dpp", title: { sv: "Visa ordernummer kassapunkt" }, desc: { sv: "Visa ordernummer i direktförsäljning" }, type: "bool", optional: true },
    { key: "skip_check", title: { sv: "Hoppa över kontroll" }, desc: { sv: "Hoppa över kontroll" }, type: "bool" },
    { key: "time_to_leave", title: { sv: "Aktivera \"Dags att lämna\"" }, desc: { sv: "" }, type: "bool", optional: true },
    { key: "waitresses", title: { sv: "Servitriser" }, desc: { sv: "Obs! Ändringar görs i appen" }, type: "string[]", hidden: true },

    { header: "Text" },
    { key: "text.title", title: { sv: "Text titel" }, desc: { sv: "" }, type: "string", optional: true },
    { key: "text.desc", title: { sv: "Text beskrivning" }, desc: { sv: "" }, type: "string", optional: true },
    { key: "menu_filter_tag", title: { sv: "Artikel filtrerings tag (inhouse)" }, desc: { sv: "" }, type: "string", optional: true },
    { key: "show_prep_time", title: { sv: "Visa tillagningstid" }, desc: { sv: "" }, type: "bool", optional: true },
    { key: "rescan_after_minutes", title: { sv: "Omskanning av bord" }, desc: { sv: "Kräver omskanning av bord efter angiven tid" }, type: "integer", suffix: "min", optional: true },
  ]
};
