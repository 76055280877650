import { Injectable } from '@angular/core';
import {BehaviorSubject, from, Observable, of, ReplaySubject, Subscription} from "rxjs";
import {filter, first, map} from "rxjs/operators";
import { ApiService } from './api.service';
import { VenueConfigSchema } from '../models/venue-config-schema';

@Injectable({
  providedIn: 'root'
})
export class SchemaService {
  private schema: VenueConfigSchema | null = null;

  constructor(private api: ApiService) {}

  fetchSchema(): Observable<VenueConfigSchema> {
    if (this.schema) {
      // Return cached schema as an observable if already loaded
      return of(this.schema);
    }

    // Use from() to convert the Promise to an Observable
    return from(this.api.fetchConfigStructure()).pipe(
      map((response) => {
        console.log('Fetched schema:', response);
        // Optionally filter the schema and cache it
        this.schema = this.filterSchema(response);
        return this.schema;
      })
    );
  }

  // Method to filter out certain properties from the schema
  private filterSchema(schema: VenueConfigSchema): VenueConfigSchema {
    // Example: remove a property named "deprecated"
    const filteredSchema: VenueConfigSchema = {};

    Object.keys(schema).forEach((key) => {
      if (key !== 'deprecated') {
        filteredSchema[key] = schema[key];
      }
    });

    return filteredSchema;
  }

}
