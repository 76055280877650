<h2 mat-dialog-title>Arkivera Z-rapporter</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Verifikationskod</mat-label>
      <input matInput [formControl]="code" required>
    </mat-form-field>
    <div>
      <p>Detta kan göras när en venue byter org-nummer och ccu</p>
      <p>Arkiverar alla befintliga z-rapporter. GÅR INTE ATT ÅTERSTÄLLA</p>
      <p>Ange verifikationskoden ovan som extra säkerhet.</p>
    </div>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 15px); left: calc(50% - 15px);" [diameter]="30" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="ok()">Ok</button>
</mat-dialog-actions>

