<div>
  <p>
    <mat-toolbar>
      <span>Mews services</span>
      <span class="spacer"></span>
<!--      <app-wiki-info-link title="Lär dig mer" section="support" path="admins"></app-wiki-info-link>-->
<!--      <button mat-button (click)="addPerson()">-->
<!--        <mat-icon>person_add</mat-icon> Lägg till-->
<!--      </button>-->
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.Id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.Name}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.Type}} </td>
    </ng-container>

    <ng-container matColumnDef="is_active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.IsActive}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

