import {Component, OnDestroy, OnInit} from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute} from "@angular/router";
import Handsontable from "handsontable";
import {UntypedFormControl, Validators} from "@angular/forms";
import {ArrayUtils, NodeUtils} from "../../../utils/utils";
import {ConfigFragment, SettingsAction, SettingsPage, SettingsProperty, SettingsPropertyColumn} from "../../../models/settings-page";
import {FireService} from "../../../services/fire.service";
import {VenueConfig} from "../../../models/venue-config";
import {combineLatest, Subscription} from "rxjs";
import {HotTableRegisterer} from "@handsontable/angular";
import {detailedDiff, diff} from "deep-object-diff";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {getPagePaths, getSettingsPage} from "../pages/setting-pages";
import Utils from "../../../common/utils";
import {environment} from "../../../../environments/environment";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth.service';
import { MewsAccountingCategoriesResponse } from 'src/app/models/mews';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
  venueId: string;
  sc: SettingsPage;
  uiReady = false;
  errorMessage: string;
  page: string;
  version: string;
  fragment: ConfigFragment;
  detailedDiffToCurrent: object;
  sameAsCurrent = false;

  private hotRegisterer = new HotTableRegisterer();
  private config: VenueConfig;
  private sub: Subscription;
  private sourceData: any;
  private hasBeenBuilt = false;
  private paramSub: Subscription;
  private requiredFieldEmpty = false;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private fire: FireService, private snackBar: MatSnackBar,
              private dialog: MatDialog, private sanitizer: DomSanitizer, private auth: AuthService) { }

  hotSettings = {};
  formControls = {};
  booleans = {};
  booleansSource = {};
  enums = {};
  restored = false;
  links = new Map<string, {url: string, safeUrl: SafeResourceUrl}>();
  isSuperAdmin = false;
  isVault = false;
  backLink: string;
  mewsAccounts: MewsAccountingCategoriesResponse;

  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      event.preventDefault();
      this.save();
    }
  }

  ngOnInit(): void {
    this.paramSub = combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
      ]
    ).subscribe(res => {
      const param = res[0];
      const query = res[1];
      this.venueId = param.get("venue_id");
      this.page = param.get("page");
      this.version = query.get("version");
      this.sc = getSettingsPage(this.page);

      if (this.page === "workflow") {
        this.backLink = `/venue/${this.venueId}/workflow-editor`;
      } else {
        this.backLink = `/venue/${this.venueId}/settings/${this.page}`;
      }

      this.uiReady = false;
      this.hasBeenBuilt = false;
      this.sub?.unsubscribe();
      this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);

      if (this.sc == null) {
        console.log(`Unknown settings page: ${this.page}`);
        return;
      }
      this.beginObserving();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.paramSub?.unsubscribe();
  }

  private beginObserving() {
    this.sub = this.fire.observeVenueConfig(Number(this.venueId)).subscribe(cfg => {
      this.isVault = cfg.chain != null;

      const processBuildUI = () => {
        if (this.shouldProcessMews()) {
          this.getMewsAccounts().then(ma => {
            this.mewsAccounts = ma;
            this.buildUI();
          })
          .catch(e => {
            SimpleDialogComponent.showErr(this.dialog, e);
            this.buildUI();
          });
        } else {
          this.buildUI();
        }
      };

      if (this.version) {
        this.venueService.fetchFragmentVersion(Number(this.venueId), this.version).then( f => {
          this.fragment = f;
          this.config = this.patchRootWithVersionData(cfg, f);
          processBuildUI();
        });
      } else {
        this.fragment = null;
        this.version = null;
        this.detailedDiffToCurrent = null;
        this.sameAsCurrent = false;
        this.config = cfg;
        processBuildUI();
      }
    });
  }

  private shouldProcessMews(): boolean {
    return this.sc.specials?.includes("mews") && !NodeUtils.isNullOrEmpty(this.config?.mews);
  }

  private buildUI() {
    if (this.hasBeenBuilt && this.hasChanges()) {
      console.log("UI already loaded and has changes, ignore any incoming changes...");
      return;
    }
    const sourceData = {};
    const root = NodeUtils.get(this.config, this.sc.page_settings.root_path);
    // TODO, save a copy of the data in root
    // TODO, then compare this data against incoming data
    // TODO, if diff and hasBeenBuilt is false => ok to redraw (meaning we or someone else has changed the current settings)

    const formProps = this.sc.properties.filter( prop => ['string', 'integer', 'text_field'].includes(prop.type));
    for (const formProp of formProps) {
      const src = NodeUtils.get(root, formProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, formProp.key, src);
      }
      const validators = [];
      if (!formProp.optional) {
        validators.push(Validators.required);
      }
      const state = NodeUtils.get(root, formProp.key);
      const fc = new UntypedFormControl({value: state, disabled: this.version != null}, validators);
      this.formControls[formProp.key] = fc;
    }

    const boolProps = this.sc.properties.filter( prop => prop.type === "bool");
    for (const boolProp of boolProps) {
      const src = NodeUtils.get(root, boolProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, boolProp.key, src);
        this.booleans[boolProp.key] = src;
        this.booleansSource[boolProp.key] = src;
      }
    }

    const tableProps = this.sc.properties.filter( prop => prop.type === "table");
    for (const tableProp of tableProps) {
      let src;
      let table;
      if (tableProp.convert_from_tree) {
        src = this.createSourceForTreeData(tableProp, root);
        table = this.convertFromTree(tableProp, root);
      } else {
        src = this.createSourceForTableData(tableProp, root);
        table = root;
      }
      NodeUtils.set(sourceData, tableProp.key, src);
      this.setupHandsontableSettings(tableProp, table);
      if (tableProp.table_links && src) {
        src.forEach(link => {
          const url = `${environment.passe_url}/lg/${this.venueId}/${link.identity}`;
          const srUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.links.set(link.identity, {url, safeUrl: srUrl});
        });
      }
    }

    const listProps = this.sc.properties.filter( prop => prop.type?.endsWith("[]") );
    for (const listProp of listProps) {
      const src = NodeUtils.get(root, listProp.key);
      NodeUtils.set(sourceData, listProp.key, src);
      this.setupHandsontableSettingsForList(listProp, src);
    }

    const enumProps = this.sc.properties.filter( prop => prop.type === "enum");
    for (const enumProp of enumProps) {
      const src = NodeUtils.get(root, enumProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, enumProp.key, src);
        this.enums[enumProp.key] = src;
      }
    }

    console.log("sourceData", sourceData);
    this.sourceData = sourceData;
    this.uiReady = true;
    this.hasBeenBuilt = true;
  }

  private convertFromTree(prop: SettingsProperty, tree: any): any {
    const keyName = prop.convert_from_tree;
    const table = [];
    const root = NodeUtils.get(tree, prop.key);
    if (root == null ) { return null; }
    for (const [key, child] of Object.entries(root)) {
      child[keyName] = key;
      table.push(child);
    }
    const rr = {};
    rr[prop.key] = table;
    return rr;
  }

  private createSourceForTreeData(prop: SettingsProperty, tree: {} ) {
    const srcTree = {};
    const inputRows = NodeUtils.get(tree, prop.key);
    if (inputRows == null ) { return null; }
    for (const [key, row] of Object.entries(inputRows)) {
      const srcRow = {};
      for (const col of prop.columns) {
        const srcCell = NodeUtils.get(row, col.key);
        if (srcCell != null) {
          srcRow[col.key] = srcCell;
        }
      }
      if (!NodeUtils.isNullOrEmpty(srcRow)) {
        srcTree[key] = srcRow;
      }
    }
    return srcTree;
  }

  private createSourceForTableData(prop: SettingsProperty, table: {} ) {
    const srcRows = [];
    const inputRows = NodeUtils.get(table, prop.key);
    if (inputRows == null ) { return []; }
    for (const row of inputRows) {
      const srcRow = {};
      for (const col of prop.columns) {
        const srcCell = NodeUtils.get(row, col.key);
        if (srcCell != null) {
          srcRow[col.key] = srcCell;
        }
      }
      if (!NodeUtils.isNullOrEmpty(srcRow)) {
        srcRows.push(srcRow);
      }
    }
    return srcRows;
  }

  private convertToTree(prop: SettingsProperty, rows: any[]) {
    return rows;

    if (prop.convert_from_tree) {
      const keyName = prop.convert_from_tree;
      const tree = {};
      // const root = NodeUtils.get(tree, prop.key);
      // console.log("root", root);
      for (const rowOrg of rows) {
        const row = NodeUtils.deepcopyWithJSON(rowOrg);
        const k = row[keyName];
        row[keyName] = undefined;
        tree[k] = row;
      }
      return tree;
    } else {
      return rows;
    }
  }

  save() {
    this.requiredFieldEmpty = false;
    console.log("Save...");

    const data = this.collectData();

    console.log("sourceData", this.sourceData);
    console.log("newData", data);
    const d = diff(this.sourceData, data);
    console.log("diff", d);
    if (NodeUtils.isNullOrEmptyObject(d)) {
      if (this.requiredFieldEmpty) {
        this.snackBar.open("Please fill out required fields.", "", {duration: 3000});
      } else {
        this.snackBar.open("No changes. Nothing saved.", "", {duration: 3000});
      }
    } else {
      const dialogRef = SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Saving...", showProgress: true, cancelButton: "Close"});
      const dd = detailedDiff(this.sourceData, data);
      console.log("ddiff", dd);
      const paths = getPagePaths(this.sc.page_settings);
      let patchData;
      if (this.sc.page_settings.root_anchor != null) {
        patchData = data[this.sc.page_settings.root_anchor];
      } else {
        patchData = data;
      }
      this.hasBeenBuilt = false;
      this.venueService.patchSettings(Number(this.venueId), paths.rootPath, paths.anchorPath, patchData, dd).then( res => {
        console.log(res);
        dialogRef.close();
        this.errorMessage = null;
      }).catch(err => {
        console.log(err);
        dialogRef.close();
        this.hasBeenBuilt = true;
        this.errorMessage = err.error.message;
      });
    }
  }

  restoreVersion() {
    const dialogRef = SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Restoring version...", showProgress: true, cancelButton: "Close"});
    this.venueService.restoreSettingsVersion(Number(this.venueId), this.version).then( res => {
      console.log(res);
      this.restored = true;
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => dialogRef.close());
  }

  public hasChanges(): boolean {
    const data = this.collectData();
    if (data == null) { return false; }
    const d = diff(this.sourceData, data);
    console.log("Diif", d);
    return !NodeUtils.isNullOrEmptyObject(d);
  }

  private collectData(): any {
    const data = {};
    for (const prop of this.sc.properties) {
      if (prop.type === "table") {
        const tableData = this.collectTableData(prop, data);
        console.log("tableData", tableData);
        NodeUtils.setOrRemove(data, prop.key, tableData);
      } else if (prop.type?.endsWith("[]")){
        const listData = this.isPropHidden(prop) ? this.sourceData[prop.key] : this.collectListData(prop, data);
        NodeUtils.setOrRemove(data, prop.key, listData);
      } else if (['string', 'integer', 'text_field'].includes(prop.type)){
        const fc = this.formControls[prop.key];
        if (!fc.valid && !prop.default) {
          console.log("Not valid", fc);
          fc.markAsTouched();
          this.requiredFieldEmpty = true;
          return;
        }
        if (!fc.value && !prop.optional && prop.default) {
          const v = this.getValueForType(prop.default, prop.type);
          this.formControls[prop.key].setValue(v);
          NodeUtils.setOrRemove(data, prop.key, v);
        } else {
          const v = this.getValueForType(fc.value, prop.type);
          NodeUtils.setOrRemove(data, prop.key, v);
        }
      } else if ( prop.type === "bool"){
        const v = this.booleans[prop.key];
        if (v === true) {
          NodeUtils.set(data, prop.key, v);
        } else if (v === false) {
          const sourceData = this.booleansSource[prop.key];
          if (sourceData == null && prop.optional) {
            NodeUtils.remove(data, prop.key);
          } else {
            NodeUtils.set(data, prop.key, v);
          }
        }
      } else if (prop.type === "enum") {
        const v = this.enums[prop.key];
        if (prop.optional) {
          NodeUtils.setOrRemove(data, prop.key, v);
        } else {
          NodeUtils.set(data, prop.key, v);
        }
      }
    }
    return data;
  }

  private collectTableData(prop: SettingsProperty, data: {}) {
    const hotInstance = this.hotRegisterer.getInstance(prop.key);
    if (hotInstance == null) { return null; }
    const rows = [];
    for (let i = 0; i < hotInstance.countRows(); i++) {
      const row = {};
      for (const col of prop.columns) {
        let v = hotInstance.getDataAtRowProp(i, col.key);
        // Ensure cell value is of type boolean if column type is bool (pasted values are interpreted as strings)
        if (typeof v === "string" && !Utils.isStringNullOrWhitespace(v) && col.type === "bool") {
          v = v.toLowerCase() === "true";
        }
        if (!Utils.isStringNullOrWhitespace(v)) {
          let vt;
          if (['integer[]', 'string[]'].includes(col.type)){
            vt = this.getValueForType(v, col.type);
          } else {
            vt = v;
          }
          //console.log(`row ${i} ${col.key} = ${vt}`);
          row[col.key] = vt;
        }
      }
      if (!NodeUtils.isNullOrEmpty(row)) {
        // Fill in any provided default values on empty cells for this row
        for (const column of prop.columns) {
          if (Utils.isStringNullOrWhitespace(row[column.key]) && !Utils.isStringNullOrWhitespace(column.default)) {
            row[column.key] = column.default;
          }
        }
        rows.push(row);
      }
    }

    const tableData = this.convertToTree(prop, rows);
    return tableData;
  }

  private collectListData(prop: SettingsProperty, data: {}) {
    const hotInstance = this.hotRegisterer.getInstance(prop.key);
    if (hotInstance == null) { return null; }
    const row = hotInstance.getData()[0];
    return row.filter( d => !Utils.isStringNullOrWhitespace(d) );
  }

  getInputType(prop: any) {
    if (prop.type != null) {
      switch (prop.type) {
        case "string":
          if (prop.pwdField === true && prop.hidePwd === true) {
            return "password";
          }
          return "text";
        case "float":
        case "integer":
          return "number";
        case "text_field":
          return "text";
        case "bool":
          return "checkbox";
        case "datetime":
          return "datetime-local";
      }
    }
    return "text";
  }

  setupForHOTType(prop: SettingsPropertyColumn | SettingsProperty, c: any) {
    let type = "text";
    if (prop.type != null) {
      switch (prop.type) {
        case "integer[]":
        case "string[]":
        case "string":
          type = "text";
          break;
        case "integer":
        case "float":
          type = "numeric";
          break;
        case "bool":
          type = "checkbox";
          c.className = "htCenter";
          break;
        case "enum":
          type = "dropdown";
          // @ts-ignore
          c.source = prop.source;
          c.trimDropdown = false;
          break;
        case "config_enum":
          type = "dropdown";
          // @ts-ignore
          c.source = this.fetchFromConfig(prop.config_path, prop.config_key);
          break;
        case "ic_pm_vat":
          type = "dropdown";
          c.source = this.IC_PM_VAT_dropdown();
          break;
        case "date":
          type = "date";
          c.dateFormat = 'YYYY-MM-DD';
          c.correctFormat = true;
          c.datePickerConfig = {
            firstDay: 1,
          };
          break;
        case "time":
          type = "time";
          c.timeFormat = 'HH:mm';
          c.correctFormat = true;
          break;
        case "mews_class_enum":
          type = "dropdown";
          c.editor = this.mewsAccounts == null ? "text" : "select";
          c.selectOptions = this.mewsAccounts;
          c.renderer = this.mewsAccountsRenderer;
          break;
      }
    }
    c.type = type;
  }

  getValueForType(val: string, type: string) {
    if (val != null) {
      switch (type) {
        case "string":
          if (val === "") {
            return undefined;
          }
          return val;
        case "string[]":
          if (val === "") {
            return undefined;
          }
          try {
            return val.split(",");
          } catch (e) {
            return undefined;
          }
        case "integer":
          if (val === "") {
            return undefined;
          }
          return Number(val);
        case "integer[]":
          if (val === "") {
            return undefined;
          }
          try {
            return val.split(",").map( v => Number(v) );
          } catch (e) {
            return undefined;
          }
      }
    }
    return val;
  }

  private setupHandsontableSettings(prop: SettingsProperty, sourceDataRoot: any) {
    const that: SettingsComponent = this;

    // Prepare columns
    const cols = [];
    for (const col of prop.columns) {
      if (col.configCondition && !col.configCondition(this.config)) {
        continue;
      }
      const c = {data: col.key, title: col.title.sv, width: col.width};
      this.setupForHOTType(col, c);
      cols.push(c);
    }

    // Prepare rows (values)
    let data;
    if (sourceDataRoot && !NodeUtils.isNullOrEmpty(sourceDataRoot[prop.key])) {
      data = [];
      const source = sourceDataRoot[prop.key];
      if (source != null) {
        for (const row of source) {
          const result = {};
          for (const rprop in row) {
            if (Array.isArray(row[rprop])) {
              result[rprop] = row[rprop].join(",");
            } else {
              result[rprop] = row[rprop];
            }
          }
          data.push(result);
        }
      }
      const addRows = prop.additional_rows ?? 3;
      for (let i = 0; i < addRows; i++) {
        data.push({});
      }
    } else {
      data = undefined;
    }

    const set = {
      licenseKey: environment.handson_key,
      data,
      startRows: 5,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: true,
      // stretchH: 'all',
      manualColumnResize: true,
      columns: cols,
      contextMenu: undefined,
      trimDropdown: false,
    };

    if (this.version == null) {
      set.contextMenu = {
        items: {
          insert_row_above: {
            name() {
              return '<b>Infoga rad(er) ovanför ↑↑</b>';
            },
            callback(key, selection, clickEvent) {
              const startRow = selection[0].start.row;
              const endRow = selection[0].end.row;
              const numRows = endRow - startRow + 1;
              that.insertRow(startRow, numRows, this, true);
            }
          },
          insert_row_below: {
            name() {
              return '<b>Infoga rad(er) nedanför ↓↓</b>';
            },
            callback(key, selection, clickEvent) {
              const startRow = selection[0].start.row;
              const endRow = selection[0].end.row;
              const numRows = endRow - startRow + 1;
              that.insertRow(endRow, numRows, this, false);
            }
          },
          separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
          clear_row: {
            name() {
              return 'Rensa rad(er)';
            },
            callback(key, selection, clickEvent) {
              const startRow = selection[0].start.row;
              const endRow = selection[0].end.row;
              const numRows = endRow - startRow + 1;
              that.clearRow(startRow, numRows, this);
            }
          },
          delete_row: {
            name() {
              return 'Ta bort rad(er)';
            },
            callback(key, selection, clickEvent) {
              const startRow = selection[0].start.row;
              const endRow = selection[0].end.row;
              const numRows = endRow - startRow + 1;
              that.deleteRow(startRow, numRows, this);
            }
          },
        }
      };
    } else {
      set.contextMenu = false;
      // @ts-ignore
      set.readOnly = true;
      // @ts-ignore
      set.disableVisualSelection = true;
      set.manualColumnResize = false;
    }

    this.hotSettings[prop.key] = set;
  }

  private insertRow(rowIndex: number, numRows: number, hot: Handsontable, above: boolean) {
    for (let i = 0; i < numRows; i++) {
      hot.alter('insert_row', rowIndex + (above ? 0 : 1), 1);
    }
  }

  private clearRow(rowIndex: number, numRows: number, hot: Handsontable) {
    for (let i = 0; i < numRows; i++) {
      const row = rowIndex + i;
      hot.getDataAtRow(row).forEach((cellData, colIndex) => {
        hot.setDataAtCell(row, colIndex, '');
      });
    }
  }

  private deleteRow(rowIndex: number, numRows: number, hot: Handsontable) {
    for (let i = 0; i < numRows; i++) {
      hot.alter('remove_row', rowIndex, 1);
    }
  }

  private setupHandsontableSettingsForList(prop: SettingsProperty, src: any) {
    const that: SettingsComponent = this;
    if (src != null) {
      for (let i = 0; i < 3; i++) {
        src.push("");
      }
    } else {
      src = ["", "", ""];
    }
    const data = [src];

    const set = {
      licenseKey: environment.handson_key,
      data,
      startRows: 1,
      maxRows: 1,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: false,
      manualColumnResize: true,
      contextMenu: undefined,
      colWidths: 200
    };

    if (this.version == null) {
        set.contextMenu = ["col_right", '---------', 'undo', 'redo'];
        set.colWidths = 200;
    } else {
      set.contextMenu = false;
      // @ts-ignore
      set.readOnly = true;
      // @ts-ignore
      set.disableVisualSelection = true;
      set.manualColumnResize = false;
    }

    this.hotSettings[prop.key] = set;
  }

  private IC_PM_VAT_dropdown() {
    const that = this;
    return (query, process) => {

      const ic = ArrayUtils.flattenICTree(this.config.ic, []).sort();
      const pm = Object.keys(this.config.payment.manual_payment_methods);
      if(this.config.swish?.active === true ) {
        pm.push("swish");
      }
      const vat = ["vat06", "vat12", "vat25"];
      const all = [...ic, ...pm.sort(), ...vat];
      process(all);
    };
  }

  private fetchFromConfig(path: string, key: string) {
    const sourceData = [];
    const data = NodeUtils.get(this.config, path);

    if (Array.isArray(data)) {
      data.forEach((item: any) => {
        sourceData.push(item[key]);
      });
    }
    return sourceData;
  }

  private getMewsAccounts(): Promise<any> {
    if (this.mewsAccounts) {
      return Promise.resolve(this.mewsAccounts);
    }
    return new Promise((resolve, reject) => {
      this.venueService.mewsGetAccountingCategories(Number(this.venueId)).then(res => {
        const mewsAccounts = res.AccountingCategories.reduce((acc, curr) => {
          acc[curr.Id] = curr.Name;
          return acc;
        }, {});
        const entries = Object.entries(mewsAccounts as { [key: string]: string });
        entries.sort((a, b) => a[1].localeCompare(b[1]));
        const sortedOptions = entries.reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
        resolve(sortedOptions);
      }).catch(error => reject(error));
    });
  }

  mewsAccountsRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    if (this.mewsAccounts == null) {
      td.innerHTML = value;
      td.classList.add("htDimmed");
      return;
    }
    if (value != null && this.mewsAccounts[value] != null) {
      td.innerHTML = this.mewsAccounts[value];
    } else {
      td.innerHTML = "";
    }
    if (this.version != null) {
      td.classList.add("htDimmed");
    }
  };

  closeError() {
    this.errorMessage = null;
  }

  private patchRootWithVersionData(cfg: VenueConfig, fragment: ConfigFragment) {
    console.log(cfg);
    console.log(fragment);
    const original = NodeUtils.deepcopyWithJSON(cfg);
    NodeUtils.set(cfg, fragment.root_path, fragment.data, false);
    this.sameAsCurrent = NodeUtils.isNullOrEmptyObject(diff(original, cfg));
    if (!this.sameAsCurrent) {
      this.detailedDiffToCurrent = detailedDiff(original, cfg);
    }
    return cfg;
  }

  getSanitizedUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  fetchVenueAuthToken() {
    this.venueService.fetchVenueAuthToken(Number(this.venueId)).then( token => {
      console.log("fetchVenueAuthToken", token);
      SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Publiceringsnyckel", message: token.token, cancelButton: "Stäng"});
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  isVisible(prop: SettingsProperty) {
    return this.isSuperAdmin || !this.isPropHidden(prop);
  }

  isOnlyForAdmin(prop: SettingsProperty) {
    return this.isSuperAdmin && this.isPropHidden(prop);
  }

  isPropHidden(prop: SettingsProperty) {
    return prop.hidden || (this.isVault && prop.hiddenForChain);
  }

  runCustomAction(action: SettingsAction) {
    console.log("runCustomAction", action);
    if (action.key === "setup-store-action") {
      this.customActionSetupStore();
    } else if (action.key === "start-onboarding") {
      this.customActionStartOnboarding();
    }
  }

  private customActionSetupStore() {
    this.venueService.setupVivaStore(Number(this.venueId)).then( res => {
      console.log("setupStore", res);
      SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Butiksuppsättning klar", message: "Glöm inte att klistra in venue-id i source code :)", cancelButton: "Stäng"});
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  private customActionStartOnboarding() {
    this.venueService.startVivaOnboarding(Number(this.venueId)).then( res => {
      console.log("startOnboarding", res);
      const ds = {title: `Kontoskapande påbörjat`, message: `Connected account: ${res.accountId} OBS! Spara innan du går vidare kan behövas`, cancelButton: "Avbryt", positiveButton: "Dirigera till Viva"};
      SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
        if (r) {
          if (environment.production) {
            const rd = res.invitation.redirectUrl;
            window.location.replace(rd);
          } else {
            SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Dirigera fungerar ej i demo", message: `Öppna https://demo.vivapayments.com/en/signup och mata in: ${res.accountId}`, cancelButton: "Stäng"});
          }
        }
      });
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }
}
