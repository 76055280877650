<div class="report-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div>
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="fetchDayTimeArticleList()" color="primary">SÖK</button>
      <div class="action-buttons" *ngIf="!isMobile">
        <button mat-stroked-button (click)="clearFilters()">RENSA</button>
      </div>
      <span class="spacer"></span>
      <button mat-button (click)="export('csv')" *ngIf="!isMobile" [disabled]="!canExport"><mat-icon>download</mat-icon> Export (CSV)</button>
      <button mat-stroked-button *ngIf="isMobile" class="dropdown-button" #reportsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuHandleSection"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="export('csv')">CSV-format</button>
      </mat-menu>
    </div>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" class="clicktable table-cells">

        <div *ngFor="let column of response?.data.headers;">
          <ng-container *ngIf="['datekey'].includes(column.type)" [matColumnDef]="column.id">
            <th mat-header-cell *matHeaderCellDef> {{column.title}} </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element[column.id]}}
            </td>
          </ng-container>

          <ng-container *ngIf="['sek'].includes(column.type)" [matColumnDef]="column.id">
            <th mat-header-cell *matHeaderCellDef> {{column.title}} </th>
            <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element[column.id] | moneySuff:null:'kr':2}} </td>
          </ng-container>

          <ng-container *ngIf="['vat'].includes(column.type)" [matColumnDef]="column.id">
            <th mat-header-cell *matHeaderCellDef> {{column.title}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column.id]}}% </td>
          </ng-container>

          <ng-container *ngIf="['any', 'name', 'time', 'ic', 'rounded_2dec', 'empty_string', ''].includes(column.type)" [matColumnDef]="column.id">
            <th mat-header-cell *matHeaderCellDef> {{column.title}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column.id]}} </td>
          </ng-container>

          <ng-container *ngIf="['datetime'].includes(column.type)" [matColumnDef]="column.id">
            <th mat-header-cell *matHeaderCellDef> {{column.title}} </th>
            <td mat-cell *matCellDef="let element"> {{element[column.id] |date:'medium' }} </td>
          </ng-container>

        </div>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let rowIndex = index" (click)="selectRow(row, rowIndex)" [class.selected-row]="rowIndex === selectedRowIndex"></tr>
      </table>

      <div class="no-data" *ngIf="noData">Perioden innehåller ingen försäljning</div>
    </div>
  </div>
</div>

