import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { FireService } from "../../services/fire.service";
import { ClientStatus } from "../../models/client-status";
import { MatSort, Sort } from "@angular/material/sort";
import { DeviceStateClient, DeviceStateIsOld, DeviceStateStatus, DeviceStatusIconAndColor } from "../../models/device-state";
import { BehaviorSubject, Observable, Subscription, combineLatest } from "rxjs";
import { switchMap, throttleTime } from 'rxjs/operators';


@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientListComponent implements OnInit, OnDestroy, AfterViewInit {
  private sub: Subscription;
  hideOldSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  hideOfflineSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showOnlyIminVersionSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  toggleViewVersion: boolean = false;
  versionSource = new MatTableDataSource<{ version: string; devices: any; }>();
  versionColumns: string[] = ['version', 'devices'];

  toggleViewName: boolean = false;
  nameSource = new MatTableDataSource<{ deviceName: string; devices: any; }>();
  nameColumns: string[] = ['deviceName', 'devices'];

  constructor(private fire: FireService, private cd: ChangeDetectorRef) { }

  displayedColumns = ["name", "venueId", "appVersionName", "pingLastHourAverage", "pingLastHourSuccessPercentage", "role", "updated", 
    "deviceName", "serialNo", "iminVersion", "batteryOptimization", "payStation", "newUi", "deviceType"];
  dataSource: MatTableDataSource<ClientStatus> = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    const state$ = combineLatest([
      this.hideOldSource,
      this.hideOfflineSource,
      this.showOnlyIminVersionSource
    ])
    let first = true;
    this.sub = state$.pipe(
      switchMap(([hideOld, hideOffline, showOnlyIminVersion]) => {
        first = true;
        console.log("hideOld", hideOld)
        if (showOnlyIminVersion) {
          this.displayedColumns = ["name", "venueId", "appVersionName", "role", "deviceName", "iminVersion", "serialNo", "batteryOptimization", "payStation"];
        } else {
          this.displayedColumns = ["name", "venueId", "appVersionName", "pingLastHourAverage", "pingLastHourSuccessPercentage", 
            "role", "updated", "deviceName", "batteryOptimization", "payStation", "newUi", "deviceType"];
        }
        this.cd.detectChanges(); 
        return this.fire.observeAllClientStatuses(hideOld, this.decorateClientStatus).pipe(
          throttleTime(4000)
        )
      })
    ).subscribe(clients => {
      let filtered = this.hideOfflineSource.value ? clients.filter(cl => cl.clientStatus !== DeviceStateStatus.Offline) : clients;
      if (this.showOnlyIminVersionSource.value) {
        filtered = filtered.filter(cl => cl.iminVersion);
      }
      // Show app version and device counts in the console
      if (first) {
        first = false;
        const grouped = filtered.reduce((acc, cur) => {
          const key = cur.appVersionName;
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += 1;
          return acc;
        }, {});

        const sortedGrouped = Object.entries(grouped)
          .sort((a: any, b: any) => b[0].localeCompare(a[0]))
          .map(([version, devices]) => ({
            version,
            devices
          }));
        this.versionSource.data = sortedGrouped;

        const groupedName = filtered.reduce((acc, cur) => {
          const key = cur.deviceName;
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += 1;
          return acc;
        }, {});


        const sortedGroupedName = Object.entries(groupedName)
          .map(([deviceName, devices]) => ({
            deviceName,
            devices
          }))
          .sort((a: any, b: any) => b.devices - a.devices);

        this.nameSource.data = sortedGroupedName;

        const transformed = sortedGrouped.reduce((acc, { version, ...x }) => { acc[version] = x; return acc }, {})
        console.table(transformed);
        console.log(`Displaying ${filtered.length} clients`);
      }
      //      this.dataSource.sort = this.sort;
      console.log("DATA: ", this.dataSource.data);
      this.dataSource.data = filtered.length > 0 ? filtered : [];
      this.cd.detectChanges();
    })
  }



  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;

    // Set default sort
    const sortState: Sort = { active: 'name', direction: 'asc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }
  private decorateClientStatus(cl: ClientStatus): ClientStatus {
    const clientStatus = DeviceStateClient.extractStatus(cl.status, cl.lastOk);

    const iac = DeviceStatusIconAndColor(clientStatus);
    cl.icon = iac.icon;
    cl.iconColor = iac.color;
    cl.clientStatus = clientStatus;
    return cl;
  }

  toggleViewVersions() {
    this.toggleViewVersion = !this.toggleViewVersion;
    if (this.toggleViewName){
      this.toggleViewName = false;
    }
  }

  toggleViewVNames() {
    this.toggleViewName = !this.toggleViewName;
    if (this.toggleViewVersion){
      this.toggleViewVersion = false;
    }
  }

  isVersionOutdated(version: string): boolean {
    if (!version) {
      return false;
    }
    const lastPart = version.split('_').pop();
    if (!lastPart) {
      return true;
    }
    const normalizedDate = lastPart.length === 6 ? '20' + lastPart : lastPart;
    const referenceDate = '20231024';
    return normalizedDate <= referenceDate;
  }

  getTotalDevices(): number {
    return this.versionSource.data.reduce((acc, cur) => acc + cur.devices, 0);
  }

  countByProperty(property: keyof ClientStatus, value?: any): number {
    if (value !== undefined) {
      return this.dataSource.data.filter(item => item[property] === value).length;
    } else {
      return this.dataSource.data.filter(item => item[property] === true).length;
    }
  }

  getUniqueVersionCount(): number {
    const uniqueVersions = new Set(this.versionSource.data.map(item => item.version));
    return uniqueVersions.size;
  }

  trackById(index: number, item: ClientStatus): string {
    return item.id;
  }
}
