import {Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MewsService} from "../../../models/mews";

@Component({
  selector: 'app-mews-services',
  templateUrl: './mews-services.component.html',
  styleUrls: ['./mews-services.component.css']
})
export class MewsServicesComponent {
  private venueId?: number;
  displayedColumns = ["id", "name", "type", "is_active"];
  dataSource: MatTableDataSource<MewsService>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  title: string;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.sort.sort(({ id: 'first_name', start: 'asc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      const vn = data.get("venue_id");
      this.venueId = Number(vn);
      this.fetchServcies();
    });
  }

  private fetchServcies() {
    this.venueService.fetchMewsServices(this.venueId).then(r => {
      console.log("fetchMewsServices", r);
      this.dataSource = new MatTableDataSource(r.Services as MewsService[]);
      this.dataSource.sort = this.sort;
    });
  }

}
