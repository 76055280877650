export const pageFeatures = {
  title: {sv: "Venue features (endast synlig för Heynow admins)"},
  page_settings: {
    root_path: "features"
  },
  desc: {sv: "Konfigurera features för venue (endast synlig för Heynow admins)"},
  properties: [
    {key: "collins_version", title: { sv: "Välj collins version" }, desc: { sv: "Väljer collins version för venuen" }, type: "enum", optional: true,
      values: [
        { key: "collins", title: { sv: "Standard collins" } },
        { key: "next", title: { sv: "Nya collins (2025)" } },
      ]
    },
    {key: "google_pay_mode", title: {sv: "Apple och Google pay läge"}, desc: {sv: 'Visas i self service som default. Ange "disabled" för att inaktivera'}, type: "string", optional: true},
    {key: "payment_link_enabled", title: {sv: "Använd betallänkar"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "hide_payment_link_on_prebill", title: {sv: "Dölj betallänk på förnota"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "use_mini_split", title: {sv: "Använd minisplit för stängda notor"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "void_bongs_enabled", title: {sv: "Skriv ut minus bong"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "print_bongs_important", title: {sv: "Spara printbongar längre"}, desc: {sv: "Printbongar rensas efter en vecka istället för ett dygn, och skrivs ut i kassa oavsett ålder"}, type: "bool", optional: true},
    {key: "show_priced_attribute_on_receipt", title: {sv: "Visa prissatta attribute på kvitto"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "use_modern_forward_bongs", title: {sv: "Använd moderna frambongar"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "sort_receipt_by_alcohol", title: {sv: "Sortera kvitto på alkohol grupperat"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "auto_count", title: {sv: "Auto guest count"}, desc: {sv: "AI baserad auto guest count, estimerar antal gäster på ett bord baserat på den totala obetalda notan"}, type: "bool", optional: true},
    {key: "use_breeze", title: {sv: "Använd Breeze"}, desc: {sv: "Byt så att kunden kommer åt breeze istället för gamla webkassan"}, type: "bool", optional: true},
    {key: "use_tele_login", title: {sv: "Använd tele login"}, desc: {sv: "Gör det möjligt för gäster att logga in med telefonnummer"}, type: "bool", optional: true},

    {header: "Kassapunkt"},
    {key: "create_discount_codes", title: {sv: "Aktivera rabattkoder"}, desc: {sv: "Anger om rabattkoder kan appliceras i kassapunkt"}, type: "bool", optional: true},
    {key: "presend_bong_dpp", title: {sv: "För-bonga i kassapunkt"}, desc: {sv: "Ger möjlighet att skicka bongar innan betalning görs i kassapunkt"}, type: "bool", optional: true},
    {key: "hold_bong_dpp", title: {sv: "Håll bong i kassapunkt"}, desc: {sv: "Ta betalt av ett helt sällskap en och en i kassapunkt och släpp bongen efteråt"}, type: "bool", optional: true},


    {header: "Experimentella"},

    {key: "huge_orders_cut_off", title: {sv: "Artikel-gräns stora beställningar"}, desc: {sv: "Lägger antal på en artikel istället för många artiklar utan antal"}, type: "integer", optional: true},
    {key: "show_partial_pay_button", title: {sv: "Aktivera delbetalning för terminal"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "spread_printjobs", title: {sv: "Låt andra plattor än hovmaster skriva ut bongar"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "trust_paid_order", title: {sv: "Trust paid order (not used yet)"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "disable_booth_settle", title: {sv: "Stäng av Booth dagsavslut"}, desc: {sv: ""}, type: "bool", optional: true},

    {header: "Gamla (ej längre använda?)"},
    {key: "show_change_table_dpp", title: {sv: "show_change_table_dpp"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "use_local_tts_gets", title: {sv: "use_local_tts_gets"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "use_sync_dpp", title: {sv: "use_sync_dpp"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "use_inverted_qr", title: {sv: "use_inverted_qr"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "steward_proxy", title: {sv: "steward_proxy"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "hide_call_for_waitress", title: {sv: "hide_call_for_waitress"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "app_customerlist", title: {sv: "app_customerlist"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "printers_synchronized_connect", title: {sv: "printers_synchronized_connect"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "enable_receipt_dossiers", title: {sv: "enable_receipt_dossiers"}, desc: {sv: "Unused as of v1.12.47"}, type: "bool", optional: true},
    {key: "top_up_card", title: {sv: "top_up_card"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "printer_discovery", title: {sv: "printer_discovery"}, desc: {sv: "off, discover_only, on"}, type: "string", optional: true},
    {key: "app_daily_restart_time", title: {sv: "app_daily_restart_time"}, desc: {sv: ""}, type: "string", optional: true},

  ]
};
