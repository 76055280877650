import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FireService } from "../../services/fire.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { Subscription } from 'rxjs';
import { VenueConfig } from "../../models/venue-config";
import { SettingSearchService } from 'src/app/services/setting-search.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header-card',
  templateUrl: './header-card.component.html',
  styleUrls: ['./header-card.component.css']
})
export class HeaderCardComponent implements OnInit, OnDestroy {
  @Input()
  venueId: string;
  cfg: VenueConfig;
  isSuperAdmin: any;
  private sub: Subscription;
  isVault = false;
  filteredPages = [];
  searchTerms = new Subject<string>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  isSearchEmpty = true;
  useBreeze = false;

  constructor(private fire: FireService, private route: ActivatedRoute, private auth: AuthService, private settingSearch: SettingSearchService,
              private titleService: Title, private router: Router
  ) { }

  ngOnInit(): void {
    this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);
    this.route.paramMap.subscribe(data => {
      console.log("route data:", data);
      this.venueId = data.get("venue_id");
      this.beginObserve();
    });

    this.searchTerms.pipe(
      debounceTime(300)
    ).subscribe(term => {
      this.filterPages(term);
    });
  }

  private beginObserve() {
    this.sub = this.fire.observeVenueConfig(Number(this.venueId)).subscribe(cfg => {
      this.cfg = cfg;
      this.isVault = cfg.chain != null;
      this.titleService.setTitle(`${this.cfg?.venue?.name ? this.cfg.venue.name + ' - Heynow Admin' : 'Heynow Admin'}`);
      this.useBreeze = this.cfg?.features?.use_breeze ?? false;
    });
  }

  filterPages(searchTerm: string): void {
    console.log("TERM: ", searchTerm);
    this.isSearchEmpty = searchTerm.trim() === '';
    if (searchTerm.trim() === '') {
      this.filteredPages = [];
      this.trigger?.closeMenu();
    } else {
      this.filteredPages = this.settingSearch.searchPages(searchTerm).map(page => ({
        title: page.title,
        matches: page.matches,
        routerLink: page.routerLink
      }));
      this.openMenu();
    }
  }

  navigate(event: MouseEvent, url: string) {
    event.preventDefault();
    this.router.navigateByUrl(url);
  }

  openMenu(): void {
    if (this.trigger && this.filteredPages.length > 0) {
      this.trigger?.openMenu();
    }
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  handleFocus(): void {
    if (!this.isSearchEmpty && this.filteredPages.length > 0) {
      this.trigger?.openMenu();
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.titleService.setTitle("Heynow Admin");
  }

  hasPerm(perm: string): boolean {
    return this.auth.hasPermission(this.venueId, perm);
  }
}
