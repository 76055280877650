import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VenueService } from "../../services/venue.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Venue } from "../../models/venue-list";
import { HackUtils } from "../../utils/utils";
import { ActivatedRoute, Router } from '@angular/router';
import { FireService } from 'src/app/services/fire.service';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import Utils from 'src/app/common/utils';
import { MatDialog } from '@angular/material/dialog';
import { CreateVenueDialogComponent } from '../dialogs/create-venue-dialog/create-venue-dialog.component';
import { HeywikiService } from 'src/app/services/heywiki.service';
import { SchemaService } from 'src/app/services/schema.service';
import { VenueConfigSchema } from 'src/app/models/venue-config-schema';

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  styleUrls: ['./venue-list.component.css']
})
export class VenueListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  private filterInput$ = new Subject<string>();
  filter: string;
  filteredSuggestions: string[] = [];
  private schema: VenueConfigSchema | null = null;

  customColumns: string[] = [];

  get customColumnsInput(): string {
    return this.customColumns.join(', ');
  }

  set customColumnsInput(value: string) {
    this.customColumns = value.split(',').map(s => s.trim());
  }

  baseColumns = ["name", "venue_id", "created"];
  displayedColumns = this.baseColumns;
  receiptCount: number;
  grandTotal: number;
  dataSource: MatTableDataSource<Venue>;
  sales = new Map<string, number>();
  viewingMap = false;
  showToday = false;
  showInactive = false;
  zoom = 4.8;
  center = new google.maps.LatLng({ lat: 62.38583179, lng: 16.321998712 }); // center of Sweden
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    maxZoom: 70,
    minZoom: 1,
  };
  markers = [];
  private salesObs: Subscription;

  isMobile: boolean;

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute,
    private router: Router,
    private fire: FireService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private heywiki: HeywikiService,
    private schemaService: SchemaService,
  ) { }

  trackByIndex(index: number, item: string): number {
    return index;
  }
  ngOnInit(): void {
    this.isMobile = HackUtils.isMobile();
    if (this.isMobile) {
      this.baseColumns = ["name", "venue_id"];
    } else {
      this.baseColumns = ["name", "venue_id", "created"];
    }
    this.displayedColumns = this.baseColumns;

    this.schemaService.fetchSchema().subscribe(schema => {
      this.schema = schema;
      console.log("Schema fetched", schema);
    });

    this.dataSource = new MatTableDataSource([] as Venue[]);
    this.dataSource.filterPredicate = (data, filter) => {
      const needle = Utils.sanitizeToLowerAlphaNumeric(filter);
      for (const prop of this.displayedColumns) {
        const v = this.getColumnValue(data, prop);

        if (v && Utils.sanitizeToLowerAlphaNumeric(v.toString().toLowerCase()).includes(needle)) {
          return true;
        }
      }
      return false;
    };
    this.dataSource.sortingDataAccessor = (row, property) => {
      return this.getColumnValue(row, property);
    };


    this.route.queryParams.subscribe(params => {
      console.log("params", params);

      const newFilter = params['filter'] ?? "";
      const newColumns = params['cols'] ? params['cols'].split(",") : [];
      const newInactive = params['inactive'] === 'true';


      let reload = false;

      if (newFilter !== this.filter) {
        this.filter = newFilter;
        this.applyFilter();
        reload = true;
      }

      if (!this.areArraysEqual(this.customColumns, newColumns)) {
        this.customColumns = newColumns;
        reload = true;
      }

      if (newInactive !== this.showInactive) {
        this.showInactive = newInactive;
        reload = true;
      }

      if (reload) {
        this.reload();
      }
    });

    this.filterInput$.pipe(
      debounceTime(300), // Adjust time as needed
      distinctUntilChanged()
    ).subscribe(filteredValue => {
      console.log("filterInput$", filteredValue);
      this.filter = filteredValue;
      this.updateURL();
      this.applyFilter();
    });

  }

  onInputChange(): void {
    console.log("onInputChange", this.customColumnsInput);
    if (!this.schema || !this.customColumnsInput) {
      this.filteredSuggestions = [];
      return;
    }

    const input = this.customColumnsInput.toLowerCase();
    const paths: string[] = [];

    const buildPaths = (obj: any, currentPath: string) => {
      Object.keys(obj).forEach(key => {
        // Split on ':' and handle cases where ':' may not be present
        const [name, info] = key.split(':');

        // Filter out nodes that are arrays (indicated by '*' in info)
        const newPath = currentPath ? `${currentPath}.${name}` : name;
        paths.push(newPath);
          if (!info || !info.includes('*')) {

          // Recursively build paths if the property is an object and not an array
          if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            buildPaths(obj[key], newPath);
          }
        }
      });
    };

    buildPaths(this.schema, '');
    this.filteredSuggestions = paths.filter(path => path.toLowerCase().includes(input));
    console.log("Filtered Suggestions:", this.filteredSuggestions); // Log s

  }

  areArraysEqual(arr1: string[], arr2: string[]): boolean {
    return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
  }

  ngOnDestroy(): void {
    this.salesObs?.unsubscribe();
  }

  private updateURL() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        filter: this.filter,
        cols: this.customColumns.join(","),
        inactive: this.showInactive.toString()
      },
      queryParamsHandling: 'merge',
    });
  }

  onFilterChange(value: string): void {
    this.filterInput$.next(value);
  }

  applyFilter() {
    this.dataSource.filter = this.filter.trim().toLowerCase();
    this.markers = this.dataSource.filteredData.filter(d => d.location != null).map(this.venueToMarker);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openTopResult(event: Event) {
    const venues = this.dataSource.filteredData;
    if (venues.length > 0) {
      this.router.navigateByUrl(`venue/${venues[0].venue_id}/dashboard`);
    }
  }

  trackById(index: number, item: Venue): string {
    return item.venue_id;
  }
  applyCustomColumns() {
    console.log("applyCustomColumns", this.customColumnsInput);
    this.customColumns = this.customColumnsInput.split(",").map(s => s.trim());
    this.updateURL();
    this.reload();
  }


  stringify(customValue: any) {
    if (customValue == null) { return ""; }
    if (customValue instanceof Object) {
      return JSON.stringify(customValue);
    }
    return customValue.toString();
  }

  private venueToMarker(venue: Venue): any {
    return {
      position: {
        lat: venue.location?.lat,
        lng: venue.location?.lng,
      },
      label: {
        color: '0xFFe3947e',
        text: venue.name.substring(0, 1),
      },
      title: venue.name,
      info: venue.venue_id,
      options: {
        //animation: google.maps.Animation.DROP,
      },
    };
  }

  reload(): void {
    const currentColumns = [...this.baseColumns];
    if (this.showToday) {
      currentColumns.push("sales");
    }
    this.salesObs?.unsubscribe();
    this.sales.clear();
    this.grandTotal = 0;
    this.receiptCount = 0;
    this.displayedColumns = currentColumns.concat(this.customColumns);
    console.log(this.displayedColumns);
    this.venueService.getVenueList(this.customColumns, this.showInactive).then(data => {
      this.dataSource.data = data;
      console.log(data);
      this.markers = this.dataSource.filteredData.filter(d => d.location != null).map(this.venueToMarker);
      this.salesObs?.unsubscribe();
      if (this.showToday) {
        this.salesObs = this.fire.observeTodaysTotalSales().subscribe(([salesMap, total, count]) => {
          this.grandTotal = total;
          this.receiptCount = count;
          this.sales = salesMap;
          this.changeDetector.detectChanges();
          //          console.log(this.sales);
          this.dataSource.sort = this.dataSource.sort;
        });
      }
    }).catch(e => {
      // const error = e.error as SilversmithApiError;
      console.log("Error", e);
    });
  }

  getSales(venueId: string): number {
    return this.sales.get(venueId) ?? 0;
  }
  private getColumnValue(row: Venue, prop: string): any {
    if (this.customColumns.indexOf(prop) >= 0) {
      return this.stringify(row.custom_values[prop]);
    }
    if (prop === 'sales') {
      return this.getSales(row.venue_id);
    }
    return row[prop];
  }

  openMapInfo(marker: HTMLElement, content) {
    console.log("openMapInfo", marker, content);
    // this.infoContent = content
    // this.info.open(marker)
  }

  salesChanged() {
    this.reload();
  }

  inactiveChanged() {
    this.showInactive = !this.showInactive;
    this.updateURL();
    this.reload();
  }

  addVenue() {
    const dialogRef = this.dialog.open(CreateVenueDialogComponent, HackUtils.DLG({
      data: {
        dialogTitle: "Skapa ny venue"
      },
      disableClose: true
    }));
    dialogRef.afterClosed().subscribe(r => {
      if (r) {
        console.log("Venue successfully created");
        this.router.navigateByUrl(`venue/${r.venue_id}/venue-info`);

        // Create venue notes from template
        const templateSection = "intern-support-wiki";
        const templatePath = "venue-info-template";
        const section = "notes";
        const path = r.venue_id;
        this.heywiki.fetchDoc(templateSection, templatePath).subscribe(md => {
          const markdown = md.markdown;
          this.heywiki.editDoc(section, path, markdown, "Heynow", "email", true).subscribe(v => {
            console.log("Created wiki page for venue", v);
          });
        });

      } else {
        console.log("Cancelled");
      }
    });
  }
}
