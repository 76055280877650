import { Component } from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Location} from "@angular/common";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatTableDataSource} from "@angular/material/table";
import {VenueCustomer} from "../../../models/venue-customers";

@Component({
  selector: 'app-sync-accounts',
  templateUrl: './sync-accounts.component.html',
  styleUrls: ['./sync-accounts.component.css']
})
export class SyncAccountsComponent {
  venueId: number;
  dataSource: MatTableDataSource<VenueCustomer>;

  displayedColumns1 = [
    "org_number", "name", "email", "phone", "contact_person", "address", "postal_number", "city", "country", "accounting_id", "price_list",
  ];
  displayedColumns2 = [
    "org_number", "name", "email", "phone", "contact_person", "address", "postal_number", "city", "country", "accounting_id", "price_list", "diff_params"
  ];

  syncTabs: any[];
  importActive = false;
  exportActive = false;
  message: string;

  constructor(private venueService: VenueService, private route: ActivatedRoute,
              private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar,
              private location: Location) { }

  ngOnInit(): void {
    //this.sort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
    });
  }

  private loadSyncPreview() {
  }

  goBack() {
    this.location.back();
  }

  import_from_fn(preview:boolean) {
    this.message = null;
    this.importActive = false;
    this.exportActive = false;
    this.venueService.syncAccounts(this.venueId, preview, "import_from_fn").then(res => {
      console.log("syncAccounts", res);
      this.syncTabs = [];
      if (preview) {
        if (res.imported_from_fn.created.length > 0) {
          const d = {title: "Nya kunder från Fortnox (kommer att skapa konton i Heynow)", dataSource: new MatTableDataSource(res.imported_from_fn.created as VenueCustomer[]), displayedColumns: this.displayedColumns1};
          this.syncTabs.push(d);
        }
        if (res.imported_from_fn.updated.length > 0) {
          const d = {title: "Uppdaterade kunder i Fortnox (kommer uppdatera konton)", dataSource: new MatTableDataSource(res.imported_from_fn.updated as VenueCustomer[]), displayedColumns: this.displayedColumns2};
          this.syncTabs.push(d);
        }
        const isEmpty = res.imported_from_fn.created.length === 0 && res.imported_from_fn.updated.length === 0;
        this.message = isEmpty ? "Finns ingen data att importera" : null;
        this.importActive = !isEmpty;
      } else {
        const createdCount = res.imported_from_fn.created.length;
        const updatedCount = res.imported_from_fn.updated.length;
        this.message = `Import klar från Fortnox. Skapade kunder: ${createdCount}, Uppdaterade kunder: ${updatedCount}`;
      }
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  export_to_fn(preview:boolean) {
    this.message = null;
    this.importActive = false;
    this.exportActive = false;
    this.venueService.syncAccounts(this.venueId, preview, "export_to_fn").then(res => {
      console.log("syncAccounts", res);
      this.syncTabs = [];
      if (preview) {
        if (res.exported_to_fn.created.length > 0) {
          const d = {title: "Nya konton från Heynow (kommer att skapa kunder i Fortnox)", dataSource: new MatTableDataSource(res.exported_to_fn.created as VenueCustomer[]), displayedColumns: this.displayedColumns1};
          this.syncTabs.push(d);
        }
        if (res.exported_to_fn.updated.length > 0) {
          const d = {title: "Uppdaterade konton (kommer uppdatera kunder i Fortnox)", dataSource: new MatTableDataSource(res.exported_to_fn.updated as VenueCustomer[]), displayedColumns: this.displayedColumns2};
          this.syncTabs.push(d);
        }
        if (res.exported_to_fn.missing.length > 0) {
          const d = {title: "Konton som saknar 'Konto-id' (kommer inte synkas)", dataSource: new MatTableDataSource(res.exported_to_fn.missing as VenueCustomer[]), displayedColumns: this.displayedColumns2};
          this.syncTabs.push(d);
        }
        const isEmpty = res.exported_to_fn.created.length === 0 && res.exported_to_fn.updated.length === 0;
        this.message = isEmpty ? "Finns inget att exportera" : null;
        this.exportActive = !isEmpty;
      } else {
        const createdCount = res.exported_to_fn.created.length;
        const updatedCount = res.exported_to_fn.updated.length;
        this.message = `Export klar till Fortox. Nya konton: ${createdCount}, Uppdaterade konton: ${updatedCount}`;
      }
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));

  }

  cancel_expimp() {
    this.importActive = false;
    this.exportActive = false;
    this.syncTabs = [];
  }
}
