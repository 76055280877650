import {pageSlotting} from "./slot-settings";
import {pageAccounts} from "./account-settings";
import {pageBongTemplate} from "./bong-template-settings";
import {pageGoyadaSettings, pageManualPaymentMethods, pageMicrodebSettings, pageRetain24Settings} from "./payment-methods-settings";
import {pageOrderTags} from "./order-tags-settings";
import { pageDiscountSettings } from "./discount-settings";
import {pageDiscountPrograms} from "./discount-program-settings";
import {SettingsPage} from "../../../models/settings-page";
import {pageMenuSettings} from "./menu-settings";
import {pageReportSettings} from "./report-settings";
import {pageSIE} from "./sie-settings";
import {pageFeatures} from "./features-settings";
import {pageAdmissionSettings} from "./admission-settings";
import {pagePaymentTerminals} from "./payment-terminals-settings";
import {pagePrinters} from "./printers-settings";
import {pageVenueSettings} from "./venue-settings";
import {pageSwishSettings} from "./swish-settings";
import {pageServingSettings} from "./serving-settings";
import {pagePickup} from "./pickup-settings";
import {pageFoodcourtSettings} from "./foodcourt-settings";
import {pageRoomserviceSettings, pageRoomserviceRoomSettings} from "./roomservice-settings";
import {pageTakeawaySettings} from "./takeaway-settings";
import {pageKdsSettings} from "./kds-settings";
import {pageVenueTransactionsSettings} from "./venue-transactions-settings";
import {pageDesquareSettings} from "./desquare-settings";
import {pageCashregSettings} from "./cashreg-settings";
import {pageReceiptSettings} from "./receipt-settings";
import {pageCustomerDisplays} from "./customer-displays-settings";
import {pageSoftpaySettings} from "./softpay-settings";
import {pagePinCodes} from "./pin-code-settings";
import {pageFortnoxSettings} from "./fortnox-settings";
import { pageAssetsSettings, pageBucketSettings, pageContactEmailSettings, pageContactPhoneSettings } from "./general-settings";
import { pageBillingSettings } from "./billing-settings";
import { pageCaspecoSettings } from "./caspeco-settings";
import { pageCardsSettings } from "./cards-settings";
import { pageClusteringSettings } from "./clustering-settings";
import { pageStaffSettings } from "./staff-settings";
import { pageInhouseSettings } from "./inhouse-settings";
import { pageVoucherSettings } from "./voucher-settings";
import { pageBoothSettings } from "./booth-settings";
import { pagePOSSettings } from "./pos-settings";
import {pageResourceSettings} from "./resource-settings";
import { pageLoyaltySettings } from "./loyalty-settings";
import {pageVivaSettings} from "./viva-settings";
import { pageKioskSettings } from "./kiosk-settings";
import {pageWorkflowSettings} from "./workflow-settings";
import { pageForwardingSettings } from "./forwarding-settings";
import { pagePersonalkollen } from "./personalkollen-settings";
import {pageBooking} from "./booking-settings";
import {pageMewsSettings} from "./mews-settings";
import {pageLocaleSettings} from "./locale-settings";
import {pageHotsoftSettings} from "./hotsoft-settings";
import {pageBrandingSettings} from "./branding-settings";

export function getSettingsPage(key = null) {
  const settingsPages = {
    slotting: pageSlotting,
    booking: pageBooking,
    accounts: pageAccounts,
    pin_codes: pagePinCodes,
    bong_template: pageBongTemplate,
    payment_methods: pageManualPaymentMethods,
    payment_terminals: pagePaymentTerminals,
    printers: pagePrinters,
    order_tags: pageOrderTags,
    discount_settings: pageDiscountSettings,
    discount_programs: pageDiscountPrograms,
    menu_settings: pageMenuSettings,
    report_settings: pageReportSettings,
    sie_settings: pageSIE,
    features_settings: pageFeatures,
    admission_settings: pageAdmissionSettings,
    venue_settings: pageVenueSettings,
    locale_settings: pageLocaleSettings,
    swish_settings: pageSwishSettings,
    serving_settings: pageServingSettings,
    pickup_settings: pagePickup,
    foodcourt_settings: pageFoodcourtSettings,
    roomservice_settings: pageRoomserviceSettings,
    roomservice_room_settings: pageRoomserviceRoomSettings,
    takeaway_settings: pageTakeawaySettings,
    kds_settings: pageKdsSettings,
    venue_transactions: pageVenueTransactionsSettings,
    desquare_settings: pageDesquareSettings,
    cashreg_settings: pageCashregSettings,
    receipt_settings: pageReceiptSettings,
    customer_displays: pageCustomerDisplays,
    softpay_settings: pageSoftpaySettings,
    viva_settings: pageVivaSettings,
    fortnox_settings: pageFortnoxSettings,
    assets: pageAssetsSettings,
    bucket: pageBucketSettings,
    billing_settings: pageBillingSettings,
    caspeco_settings: pageCaspecoSettings,
    cards: pageCardsSettings,
    clustering: pageClusteringSettings,
    staff_settings: pageStaffSettings,
    inhouse_settings: pageInhouseSettings,
    contact_phone: pageContactPhoneSettings,
    contact_email: pageContactEmailSettings,
    voucher_settings: pageVoucherSettings,
    booth_settings: pageBoothSettings,
    pos_settings: pagePOSSettings,
    resource_settings: pageResourceSettings,
    loyalty_settings: pageLoyaltySettings,
    kiosk_settings: pageKioskSettings,
    workflow: pageWorkflowSettings,
    forwarding: pageForwardingSettings,
    personalkollen_settings: pagePersonalkollen,
    mews_settings: pageMewsSettings,
    hotsoft_settings: pageHotsoftSettings,
    goyada_settings: pageGoyadaSettings,
    microdeb_settings: pageMicrodebSettings,
    retain24_settings: pageRetain24Settings,
    branding_settings: pageBrandingSettings
  };
  if (key === null) {
    return settingsPages;
  } else {
    return settingsPages[key];
  }
}

export function getPagePaths(pageSettings) {
  let rootPath;
  let anchorPath;
  rootPath = pageSettings.root_path;
  if (pageSettings.root_anchor != null) {
    anchorPath = pageSettings.root_anchor;
    if (rootPath) {
      anchorPath = rootPath + "." + anchorPath;
    }
    rootPath = anchorPath;
  }
  return {rootPath, anchorPath};
}
