import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { VenueService } from 'src/app/services/venue.service';
import {MatSnackBar} from "@angular/material/snack-bar";


export interface SendReceiptDialogData {
  venueId: number;
  receiptKey: string;
  title: string;
  mailType: string;
}

@Component({
  selector: 'app-send-receipt-dialog',
  templateUrl: './send-receipt-dialog.component.html',
  styleUrls: ['./send-receipt-dialog.component.css']
})
export class SendReceiptDialogComponent implements OnInit {
  title: string;
  receiptKey: string;
  venueId: number;
  address: UntypedFormGroup;
  hint: string;
  label: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SendReceiptDialogData, private venueService: VenueService,
              private dialogRef: MatDialogRef<SendReceiptDialogComponent>, fb: UntypedFormBuilder, private snackBar: MatSnackBar)
  {
    this.title = data.title;
    this.receiptKey = data.receiptKey;
    this.venueId = data.venueId;
    if (data.mailType === "gift-card-links") {
      this.hint = "Ange E-postadress dit länkarna till presentkorten skall skickas";
      this.label = "E-post";
    } else {
      this.hint = "Ange E-post eller telefonnummer dit kvittot skall skickas";
      this.label = "E-post/Telefonnummer";
    }
    this.address = fb.group({
      validAddress: [null, Validators.compose([Validators.required, Validators.pattern(/^([+0][0-9\-\s]{9,15}|[A-Za-z](.*)([@]{1})(.{1,})(\.)(.+))$/)])],
    });
  }

  ngOnInit(): void {
  }

  sendReceipt() {
    this.venueService.sendReceipt(this.venueId, this.receiptKey, this.address.value.validAddress, this.data.mailType).then(() => {
      this.dialogRef.close();
    }).catch(error => this.snackBar.open(`Gick inte att skicka`, "Stäng"));
  }

}
