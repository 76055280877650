import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Params, Router} from "@angular/router";
import {QueryReceiptRequest, VenueService} from "../../services/venue.service";
import {QueryReceipt} from "../../models/query-receipt";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {DatePipe} from "@angular/common";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {HackUtils} from "../../utils/utils";
import {MatDialog} from "@angular/material/dialog";
import {AddFilterDialogComponent} from "./add-filter-dialog/add-filter-dialog.component";
import * as moment from 'moment';
import {combineLatest, Subscription} from "rxjs";
import {FireService} from "../../services/fire.service";
import {first} from "rxjs/operators";
import {VenueConfig} from "../../models/venue-config";
import {Admin} from 'src/app/models/user';
import Utils from 'src/app/common/utils';
import {AuthService} from "../../services/auth.service";
import {MoneySuffPipe} from "../../pipes/money-suff.pipe";

@Component({
  selector: 'app-receipt-list',
  templateUrl: './receipt-list.component.html',
  styleUrls: ['./receipt-list.component.css']
})
export class ReceiptListComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;
  private query: string;
  private config: VenueConfig;

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe,
              private dialog: MatDialog, private router: Router, private fire: FireService, public auth: AuthService) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ["select", "pay_date", "serial_number", "kind", "amount", "sales", "tip", "staff_name", "table", "method"];

  @Input() venueId: string;
  venueIdNum: number;
  receiptKey: string;
  searchStartDate: Date;
  searchEndDate: Date;
  filter: string;
  filterList: string[] = [];
  dataSource: MatTableDataSource<QueryReceipt>;
  fullCount: number;
  selectedRow: string;
  staff: Admin[];
  staffId: string;
  refundId: string;
  selectedReceipts: any[] = [];
  totalAmount?: string;
  totalSales?: string;
  totalTip?: string;
  totalReceiptsCount: number = 0;

  ngOnInit(): void {
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(res => {
      const param = res[0];
      const query = res[1];
      const q = query.get("q");
      const venueId = param.get("venue_id");
      this.staffId = query.get("staff_id");
      this.searchStartDate = Date.parse(query.get("start_date")) ? new Date(query.get("start_date")) : null;
      this.searchEndDate = Date.parse(query.get("end_date")) ? new Date(query.get("end_date")) : null;
      const shouldFetch = (this.query !== q && q != null) || this.venueId == null;
      if (this.venueId == null) {
        this.fire.observeVenueConfig(Number(venueId)).pipe(first()).subscribe(cfg => this.config = cfg );
      }
      this.venueId = venueId;
      this.venueIdNum = Number(venueId);
      this.query = q;
      this.getStaff();
      if (shouldFetch && this.query !== "search") {
        this.setupInitialSort(q);
        this.fetchReceipts();
      }
      const rk = param.get("receipt_key");
      this.receiptKey = rk !== "0" ? rk : undefined;
      this.selectedRow = this.receiptKey;
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  updateParams() {
    const queryParams: Params = {};
    if (this.searchStartDate != null) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.staffId != null) {
      queryParams.staff_id = this.staffId;
    }
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  fetchReceipts() {
    this.updateParams();
    const request = this.buildRequest();
    this.venueService.queryReceipts(request).then(r => {
      console.log("queryReceipts", r);
        if (r != null && r.receipts != null && r.receipts[0] != null) {
          this.fullCount = r.receipts[0].full_count != null ? r.receipts[0].full_count : 0;
          this.dataSource = new MatTableDataSource(r.receipts as QueryReceipt[]);
          this.dataSource.sort = this.sort;
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
      }
    );
  }

  private buildRequest() {
    const request = new QueryReceiptRequest();
    request.venue_id = this.venueId;
    request.offset = this.getOffset();
    request.limit = this.paginator != null ? this.paginator.pageSize : 30;

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    if (this.staffId != null) {
      request.staff_id = this.staffId;
    }
    if (this.filter != null) {
      request.filter = this.filter;
    }
    //if (this.sortOrder != null) {
    if (this.sort.active) {
      request.sort_order = `${this.sort.active} ${this.sort.direction}`;
    } else {
      request.sort_order = "pay_date desc";
    }
    return request;
  }

  private getOffset() {
    return this.paginator != null ? this.paginator.pageIndex * this.paginator.pageSize : 0;
  }

  updateReceipts() {
    this.fetchReceipts();
  }

  getTotalSize() {
    return this.fullCount;
  }

  getStaff() {
    this.venueService.fetchStaff(Number(this.venueId)).then(r => {
      this.staff = r;
    });
  }

  clearFilters() {
    this.searchStartDate = null;
    this.searchEndDate = null;
    this.filter = undefined;
    this.filterList = [];
    this.selectedRow = undefined;
    this.staffId = null;
    this.dataSource = new MatTableDataSource([]);
    this.fullCount = 0;
  }

  addFilter() {
    const dialogRef = this.dialog.open(AddFilterDialogComponent, HackUtils.DLG({
      data: { config: this.config }
    }));
    if (this.filter?.trim() === "") { this.filter = null; }
    this.filterList = this.filter?.split(",").map(s => s.trim()) ?? [];
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result) {
        this.filterList.push(result);
        this.filter = this.filterList.join(", ");
      }
    });
  }

  export(format: string) {
    const request = this.buildRequest();
    request.limit = undefined;
    request.offset = undefined;

    const name = this.config.venue.name.replace(/ /g, "_");
    const parts = ["export", format, name];
    if (this.searchStartDate != null) {
      parts.push(moment(this.searchStartDate).format("YYYYMMDD"));
    }
    if (this.searchEndDate != null) {
      parts.push(moment(this.searchEndDate).format("YYYYMMDD"));
    }
    const extenstion = format === "csv" ? "csv" : "txt";
    const fileName = parts.join("-") + "." + extenstion;
    this.venueService.exportQueryReceipts(request, format, fileName).subscribe(res => {
      console.log(res);
    });
  }

  sortChanged($event: any) {
    console.log($event);
    if (this.venueId) {
      this.dataSource = new MatTableDataSource([] as QueryReceipt[]);
      this.fetchReceipts();
    }
  }

  select(row: any) {
    console.log(row);
    this.selectedRow = this.selectedRow === row.receipt_id ? null : row.receipt_id;
    this.refundId = row.refund_of_id;

    const navigationExtras: NavigationExtras = {
      queryParamsHandling: "preserve",
      relativeTo: this.route
    };
    const routePath = this.selectedRow ? `/venue/${this.venueId}/receipt-list/${row.receipt_id}` : `/venue/${this.venueId}/receipt-list/0`;
    this.router.navigate([routePath], navigationExtras);
  }

  private setupInitialSort(q: string) {
    switch (q) {
      case "today":
        this.searchStartDate = moment().toDate();
        break;
      case "yesterday":
        this.searchStartDate = moment().subtract(1, "day").toDate();
        break;
      case "all":
        this.searchEndDate = moment().toDate();
        break;
      case "search":
        break;
    }
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

  isSelected(row: any): boolean {
    return this.selectedReceipts.includes(row);
  }

  toggleSelection(row: any): void {
    const index = this.selectedReceipts.indexOf(row);
    if (index > -1) {
      this.selectedReceipts.splice(index, 1);
    } else {
      this.selectedReceipts.push(row);
    }
    this.calculateTotal();
  }

  toggleSelectAll(event: any): void {
    if (!this.dataSource || !this.dataSource.data) {
      return;
    }

    if (event.checked) {
      this.selectedReceipts = this.dataSource.data.slice();
    } else {
      this.selectedReceipts = [];
    }
    this.calculateTotal();
  }

  isAllSelected(): boolean {
    if (!this.dataSource || !this.dataSource.data) {
      return false;
    }
    return this.selectedReceipts.length === this.dataSource.data.length;
  }

  calculateTotal(): void {
    const totals = this.selectedReceipts.reduce(
      (acc, receipt) => {
        const amount = typeof receipt.amount === 'number' ? receipt.amount : Number(receipt.amount);
        const sales = typeof receipt.sales === 'number' ? receipt.sales : Number(receipt.sales);
        const tip = typeof receipt.tip === 'number' ? receipt.tip : Number(receipt.tip);

        acc.amount += isNaN(amount) ? 0 : amount;
        acc.sales += isNaN(sales) ? 0 : sales;
        acc.tip += isNaN(tip) ? 0 : tip;
        acc.count++;

        return acc;
      },
       { amount: 0, sales: 0, tip: 0, count: 0 }
    );

    const p = new MoneySuffPipe();
    this.totalAmount = p.transform(totals.amount, null, "kr");
    this.totalSales = p.transform(totals.sales, null, "kr");
    this.totalTip = p.transform(totals.tip, null, "kr");
    this.totalReceiptsCount = totals.count;
  }

}
