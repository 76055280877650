<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button (click)="goBack()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Synkronisera konton</span>
      <span class="spacer"></span>
      <button mat-button *ngIf="!importActive && !exportActive" (click)="import_from_fn(true)"><mat-icon>file_download</mat-icon> Importera från Fortnox (förhandsgranska)</button>
      <button mat-button *ngIf="!importActive && !exportActive" (click)="export_to_fn(true)"><mat-icon>file_upload</mat-icon> Exportera till Fortnox (förhandsgranska)</button>
      <button mat-button *ngIf="importActive || exportActive" (click)="cancel_expimp()"><mat-icon>cancel</mat-icon> Avbryt</button>
      <button mat-button *ngIf="importActive" (click)="import_from_fn(false)"><mat-icon>save</mat-icon> Genomför import från Fortnox</button>
      <button mat-button *ngIf="exportActive" (click)="export_to_fn(false)"><mat-icon>save</mat-icon> Genomför export till Fortnox</button>
    </mat-toolbar>
  </p>
</div>

<div *ngIf="message" class="message">
  {{message}}
</div>

<div style="padding: 16px; overflow-x: auto;">
  <div *ngFor="let tab of syncTabs" style="margin-bottom: 32px;">
    <div class="header">{{ tab.title }}</div>
    <table mat-table [dataSource]="tab.dataSource" matSort class="clicktable table-cells">

      <ng-container matColumnDef="accounting_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Konto id </th>
        <td mat-cell *matCellDef="let element"> {{element.accounting_id}} </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Adress </th>
        <td mat-cell *matCellDef="let element"> {{element.address}} </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Stad</th>
        <td mat-cell *matCellDef="let element"> {{element.city}} </td>
      </ng-container>

      <ng-container matColumnDef="contact_person">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kontaktperson</th>
        <td mat-cell *matCellDef="let element"> {{element.contact_person}} </td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
        <td mat-cell *matCellDef="let element"> {{element.country}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>E-post</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>key</th>
        <td mat-cell *matCellDef="let element"> {{element.key}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="org_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Org.nummer</th>
        <td mat-cell *matCellDef="let element"> {{element.org_number}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tel</th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="postal_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Postnummer</th>
        <td mat-cell *matCellDef="let element"> {{element.postal_number}} </td>
      </ng-container>

      <ng-container matColumnDef="rowIndex">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Index</th>
        <td mat-cell *matCellDef="let element"> {{element.rowIndex}} </td>
      </ng-container>

      <ng-container matColumnDef="price_list">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Prislista</th>
        <td mat-cell *matCellDef="let element"> {{element.price_list}} </td>
      </ng-container>

      <ng-container matColumnDef="diff_params">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ändrat</th>
        <td mat-cell *matCellDef="let element"> {{element.diff_params}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tab.displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tab.displayedColumns;"></tr>
    </table>
  </div>
</div>

<div class="sync-warning">
  <div class="warning-content">
    <h3>Viktigt om synkronisering</h3>
    <div class="warning-text">
      <p>
        När du synkroniserar konton mellan Fortnox och Heynow är det viktigt att vara medveten om att systemet inte spårar var den senaste ändringen gjordes. Detta betyder att:
      </p>
      <ul>
        <li>Vid import från Fortnox kommer alla ändringar gjorda i Heynow att skrivas över</li>
        <li>Vid export till Fortnox kommer alla ändringar gjorda i Fortnox att skrivas över</li>
        <li>Du får alltid först se en förhandsgranskning av alla ändringar innan de genomförs</li>
      </ul>
      <p>
        <strong>Rekommendation:</strong> Bestäm i förväg vilket system som ska vara din huvudkälla för kunddata. Gör alla ändringar i det valda systemet och använd sedan synkroniseringen för att uppdatera det andra systemet.
      </p>
    </div>
  </div>
</div>

