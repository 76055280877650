<div>
  <p>
    <mat-toolbar>
      <span>Z-rapporter</span>
      <span class="spacer"></span>
      <button mat-button *ngIf="isHeynowAdmin()" (click)="createZReport()">
        <mat-icon>library_add</mat-icon> Skapa Z-rapport
      </button>
      <button mat-button *ngIf="isHeynowAdmin()" (click)="createZReportDate()">
        <mat-icon>library_add</mat-icon> Skapa för datum
      </button>
      <button mat-button *ngIf="isHeynowAdmin()" (click)="archiveZReports()">
        <mat-icon>archive</mat-icon> Arkivera Z-rapporter
      </button>
    </mat-toolbar>
  </p>
</div>
<div class="generic-mastdet">
  <div class="generic-master">
    <table mat-table [dataSource]="dataSource" matSort class="clicktable">
      <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial Number</th>
        <td mat-cell *matCellDef="let element"> {{element.serial_number}} </td>
      </ng-container>

      <ng-container matColumnDef="date_key">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
        <td mat-cell *matCellDef="let element"> {{element.date_key}} </td>
      </ng-container>

      <ng-container matColumnDef="total_sales">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sales</th>
        <td mat-cell *matCellDef="let element"> {{element.total_sales | moneySuff: "cent"}} </td>
      </ng-container>

      <ng-container matColumnDef="total_tip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dricks</th>
        <td mat-cell *matCellDef="let element"> {{element.total_tip | moneySuff: "cent" }} </td>
      </ng-container>

      <ng-container matColumnDef="receipt_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Kvitton</th>
        <td mat-cell *matCellDef="let element"> {{element.receipt_count}} </td>
      </ng-container>

      <ng-container matColumnDef="goods_sold_count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Goods sold</th>
        <td mat-cell *matCellDef="let element"> {{element.goods_sold_count}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="select(row)" [ngClass]="{'highlight': zreportKey === row.key}"></tr>
    </table>
      <mat-paginator *ngIf="dataSource" [length]="count" [pageSize]="30"
                     [pageSizeOptions]="[15, 30, 50, 100, 500, 2000]"
                     (page)="pageChanged($event)"
                     [pageIndex]="currentPageIndex"
                     >
      </mat-paginator>
    <!--  <mat-paginator [pageSizeOptions]="[100,200]"></mat-paginator>-->
  </div>

  <div *ngIf="zreport" class="generic-detail">
    <div class="lr-detail-container">
      <app-zreport-info [VenueId]="venueId" [zReport]="zreport"></app-zreport-info>
    </div>
  </div>
</div>

