<h2 mat-dialog-title>{{name}}</h2>
<div style="padding: 0px 24px 24px 24px;">
  <div *ngIf="descriptionStyles">Välj en beskrivning</div>
  <mat-radio-group [(ngModel)]="selectedStyle" *ngIf="descriptionStyles">
    <mat-radio-button *ngFor="let style of descriptionStyles" [value]="style" style="display: block;margin: 12px;">
      <strong>{{ style.name }}</strong>
      <div style="max-width: 700px;white-space: normal;">{{ style.text }}</div>
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="!descriptionStyles" style="height: 128px;margin-top: 64px;">
    <mat-spinner style="margin: 0 auto;" diameter="50"></mat-spinner>
    <div style="text-align: center;margin-top: 16px;">Skapar beskrivningsförslag...</div>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button (click)="onConfirm()" color="primary" [disabled]="!selectedStyle">OK</button>
  </div>
</div>
