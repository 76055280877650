<div>
  <p>
    <mat-toolbar>
      <span style="font-size: 16px;" class="tbig">{{cfg?.venue?.name}}</span>
      <a style="padding-left: 0;" [routerLink]="['/venue', venueId, 'dashboard']" mat-button class="tsma">{{cfg?.venue?.name}}</a>
      <span class="spacer"></span>
      <a [routerLink]="['/venue', venueId, 'dashboard']" mat-button class="tbig" *ngIf="hasPerm('dashboard')"><mat-icon class="header-icon">home</mat-icon>Hem</a>
      <a [routerLink]="['/venue', venueId, 'device-status']" mat-button class="tbig" *ngIf="hasPerm('devices') && !isVault"><mat-icon class="header-icon">important_devices</mat-icon>Enheter</a>
      <a [routerLink]="['/venue', venueId, 'chain-device-status']" mat-button class="tbig" *ngIf="isSuperAdmin && isVault"><mat-icon class="header-icon">important_devices</mat-icon>Enheter</a>
      <a [routerLink]="['/venue', venueId, 'pos', 'fp', 'null']" mat-button class="tbig" *ngIf="isSuperAdmin && !isVault && useBreeze"><mat-icon class="header-icon">point_of_sale</mat-icon>Kassa</a>
      <a [routerLink]="useBreeze ? ['/venue', venueId, 'breeze'] : ['/venue', venueId, 'pos', 'fp', 'null']" mat-button class="tbig" *ngIf="hasPerm('pos') && !isVault"><mat-icon class="header-icon">point_of_sale</mat-icon>{{ useBreeze && isSuperAdmin ? 'Breeze (Aktiv)' : 'Kassa' }}</a>
      <a [routerLink]="['/venue', venueId, 'breeze']" mat-button class="tbig" *ngIf="isSuperAdmin && !isVault && !useBreeze"><mat-icon class="header-icon">point_of_sale</mat-icon>Breeze</a>

      <button mat-button [matMenuTriggerFor]="menuReports" *ngIf="hasPerm('reports')"><mat-icon
          class="header-icon">analytics</mat-icon>Rapporter</button>
      <mat-menu #menuReports="matMenu">
        <!--        <button routerLink="/venue/{{venueId}}/reports/dash" mat-menu-item>Dashboard</button>-->
        <button mat-menu-item [matMenuTriggerFor]="menuSalesReports" *ngIf="!isVault">Försäljning</button>
        <button mat-menu-item [matMenuTriggerFor]="menuSalesReports" *ngIf="isVault">Försäljning (beta)</button>
        <button mat-menu-item [matMenuTriggerFor]="menuSalesTime" *ngIf="!isVault">Per timma</button>
        <button mat-menu-item [matMenuTriggerFor]="menuSalesDaily" *ngIf="!isVault">Per dag</button>
        <button mat-menu-item [matMenuTriggerFor]="menuSalesDatetime" *ngIf="!isVault">Per artikel</button>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'customers']" *ngIf="!isVault">Per kund</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'menu-sales']" *ngIf="!isVault">Försäljningstoppen</a>
        <button mat-menu-item [matMenuTriggerFor]="staff" *ngIf="!isVault">Personal</button>
        <button mat-menu-item [matMenuTriggerFor]="other" *ngIf="!isVault">Övrigt</button>
        <mat-divider *ngIf="!isVault"></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="menuZReports">Z-rapporter</button>
      </mat-menu>

      <mat-menu #menuSalesReports="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'tree']" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'tree']" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'tree']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuSalesTime="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuSalesHourly">Per timma</button>
        <button mat-menu-item [matMenuTriggerFor]="menuSalesTS">Per intervall (BETA)</button>
      </mat-menu>

      <mat-menu #menuSalesHourly="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'hourly']" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'hourly']" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'hourly']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuSalesDaily="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'daily']" [queryParams]="{q: 'week'}">1 vecka</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'daily']" [queryParams]="{q: 'month'}">1 månad</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'daily']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuSalesDatetime="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'sales-datetime']" [queryParams]="{q: 'week'}">1 vecka</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'sales-datetime']" [queryParams]="{q: 'month'}">1 månad</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'sales-datetime']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuSalesTS="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'ts']" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'ts']" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'ts']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #other="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'receipt-item-rows']">Detaljerad kvitto-artikelrapport</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'card-balance']">Kortbalans</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'venue-transactions', 0]">Transaktioner</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'events']" [matMenuTriggerFor]="menuStaffEvents">Händelser</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'discounted-items']" [matMenuTriggerFor]="menuDiscountedItems">Rabatterade artiklar</a>
      </mat-menu>

      <mat-menu #staff="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'staff']" [matMenuTriggerFor]="menuStaffImpact">Impact rapport</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'staff-classic']" [matMenuTriggerFor]="menuClassicStaff">Personalrapport</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'checkins']" [matMenuTriggerFor]="menuStaffCheckins">In- och utcheckning</a>
      </mat-menu>

      <mat-menu #menuZReports="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'zreports', 0]">Historik</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'zsummary']">Periodrapport</a>
      </mat-menu>

      <mat-menu #menuClassicStaff="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'staff-classic']" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'staff-classic']" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'staff-classic']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuStaffImpact="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'staff']" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'staff']" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'staff']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuStaffEvents="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'events']" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'events']" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'events']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuDiscountedItems="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'discounted-items']" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'discounted-items']" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'discounted-items']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuStaffCheckins="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'checkins']" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'checkins']" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'reports', 'checkins']" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <button mat-button [matMenuTriggerFor]="menuLive" class="tbig" *ngIf="hasPerm('live') && !isVault">
        <mat-icon class="header-icon">stream</mat-icon>Live
      </button>
      <mat-menu #menuLive="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'live-receipt-list', 0]">Senaste kvitton</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'order-list']">Senaste beställningar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'print-jobs']">Utskrivna bongar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'kds-bongs']">KDS-bongar</a>
      </mat-menu>

      <button mat-button [matMenuTriggerFor]="menuTools" class="tbig" *ngIf="hasPerm('tools') || hasPerm('settings')">
        <mat-icon class="header-icon">construction</mat-icon>Verktyg
      </button>
      <mat-menu #menuTools="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuReceipts" *ngIf="hasPerm('tools') &&!isVault">Kvitton</button>
        <button [matMenuTriggerFor]="menuBookings" mat-menu-item *ngIf="!isVault">Bokningar</button>
        <button [matMenuTriggerFor]="menuIntegrations" mat-menu-item *ngIf="hasPerm('tools') && !isVault">Integrationer</button>
        <button [matMenuTriggerFor]="menuSlotting" mat-menu-item *ngIf="cfg?.takeaway?.slotting?.active && !isVault">Slotting</button>
        <mat-divider></mat-divider>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'admins']" *ngIf="hasPerm('admins')">Admins</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'venue-customers']">Konton</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'images']" *ngIf="hasPerm('tools') && !isVault">Bildbank</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'loyalty']" *ngIf="cfg?.loyalty?.active && !isVault">Lojalitetskort</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'staff']" *ngIf="hasPerm('staff') && !isVault">Personal</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'prepaid-list']" *ngIf="hasPerm('tools') && !isVault">Presentkort</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'discount-codes']" *ngIf="hasPerm('tools') && !isVault">Rabattkoder</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'vouchers']" *ngIf="hasPerm('tools') && !isVault">Vouchers</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'smart-tables']" *ngIf="isSuperAdmin && !isVault">SmartTable&#8482; <mat-icon>admin_panel_settings</mat-icon></a>
      </mat-menu>

      <mat-menu #menuBookings="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'booking-list', 0]" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'booking-list', 0]" [queryParams]="{q: 'tomorrow'}">Imorgon</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'booking-list', 0]" [queryParams]="{q: 'week'}">En vecka</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'booking-list', 0]" [queryParams]="{q: 'month'}">En månad</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'booking-list', 0]" [queryParams]="{q: 'search'}">Sök</a>
      </mat-menu>

      <mat-menu #menuReceipts="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'receipt-list', 0]" [queryParams]="{q: 'search'}">Sök</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'receipt-list', 0]" [queryParams]="{q: 'today'}">Idag</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'receipt-list', 0]" [queryParams]="{q: 'yesterday'}">Igår</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'receipt-list', 0]" [queryParams]="{q: 'all'}">Alla</a>
      </mat-menu>

      <mat-menu #menuSlotting="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'slot-calendar']">Bokningskalender</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'edit-slots']">Redigera slottar</a>
      </mat-menu>

      <mat-menu #menuIntegrations="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuFortnox">Fortnox</button>
        <button mat-menu-item [matMenuTriggerFor]="giftcardintegrations" *ngIf="isSuperAdmin">Presentkort <mat-icon>admin_panel_settings</mat-icon></button>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'adyen']" *ngIf="isSuperAdmin">Adyen <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'caspeco_settings']" *ngIf="isSuperAdmin">Caspeco <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'swish_settings']" *ngIf="isSuperAdmin">Swish <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'desquare_settings']">Desquare (TV-display)</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'venue-ccu']" *ngIf="isSuperAdmin">Infrasec CCU <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'softpay-terminals']" *ngIf="isSuperAdmin">Softpay Terminaler <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'personalkollen_settings']" *ngIf="isSuperAdmin">Personalkollen <mat-icon>admin_panel_settings</mat-icon></a>
        <button mat-menu-item [matMenuTriggerFor]="mewsIntegrations" *ngIf="isSuperAdmin">MEWS <mat-icon>admin_panel_settings</mat-icon></button>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'hotsoft_settings']" *ngIf="isSuperAdmin">Hotsoft <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'viva_settings']" *ngIf="isSuperAdmin">Viva <mat-icon>admin_panel_settings</mat-icon></a>
      </mat-menu>

      <mat-menu #mewsIntegrations="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'mews_settings']" >Inställningar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'mews-services']" >Mews Services</a>
      </mat-menu>

      <mat-menu #giftcardintegrations="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'goyada_settings']" *ngIf="!isVault">Goyada <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'microdeb_settings']" *ngIf="!isVault">Microdeb <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'retain24_settings']" *ngIf="!isVault">Retain24 <mat-icon>admin_panel_settings</mat-icon></a>
      </mat-menu>

      <mat-menu #menuFortnox="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'fortnox']">Status</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'fortnox_settings']" *ngIf="isSuperAdmin">Inställningar <mat-icon>admin_panel_settings</mat-icon></a>
      </mat-menu>

      <button mat-button [matMenuTriggerFor]="menuSmall" class="tsma"><mat-icon class="header-icon">list</mat-icon>Menu</button>
      <mat-menu #menuSmall="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'device-status']" *ngIf="hasPerm('devices')">Enheter</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'pos', 'fp', null]" *ngIf="hasPerm('pos')">Kassa</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'breeze']" *ngIf="isSuperAdmin">Breeze</a>
        <button mat-menu-item [matMenuTriggerFor]="menuLive" *ngIf="hasPerm('live')">Live</button>
        <button mat-menu-item [matMenuTriggerFor]="menuTools" *ngIf="hasPerm('tools') || hasPerm('settings')">Verktyg</button>
        <button mat-menu-item [matMenuTriggerFor]="settingsMenu" *ngIf="hasPerm('settings')">Inställningar</button>
      </mat-menu>

      <button mat-button [matMenuTriggerFor]="settingsMenu" class="tbig" *ngIf="hasPerm('settings')"><mat-icon
        class="header-icon">settings</mat-icon>Inställningar</button>
      <mat-menu #settingsMenu="matMenu">
        <a mat-menu-item [routerLink]="['/catalog', venueId, 'tab', 'food']">Redigera Meny</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'floorplan-editor']" *ngIf="!isVault">Redigera Bordskarta</a>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="general" *ngIf="hasPerm('reports') && !isVault">Allmänt</button>
        <button mat-menu-item [matMenuTriggerFor]="economy" *ngIf="hasPerm('reports')">Ekonomi</button>
        <button mat-menu-item [matMenuTriggerFor]="sales" *ngIf="hasPerm('reports')">Försäljning</button>
        <button mat-menu-item [matMenuTriggerFor]="hardware" *ngIf="hasPerm('reports')">Hårdvara</button>
        <button mat-menu-item [matMenuTriggerFor]="kitchen" *ngIf="hasPerm('reports')">Kök</button>
        <button mat-menu-item [matMenuTriggerFor]="menuServices" *ngIf="hasPerm('reports') && !isVault">Serveringstjänster</button>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="superAdmins" *ngIf="isSuperAdmin">Super Admins <mat-icon>admin_panel_settings</mat-icon></button>
      </mat-menu>

      <mat-menu #general="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="contact">Kontakt</button>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'assets']">Logotyper</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'report_settings']">Tidsintervall för rapport</a>
      </mat-menu>

      <mat-menu #contact="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'contact_email']">E-post</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'contact_phone']">Telefon</a>
      </mat-menu>

      <mat-menu #superAdmins="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'embedded-pdfs']" *ngIf="isSuperAdmin">Bordskoder QR/PDF</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'filter-editor']">Filter Editor (beta) <mat-icon>new_releases</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'bucket']" *ngIf="isSuperAdmin">Bucket</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'clustering']" *ngIf="isSuperAdmin">Clustering</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'features_settings']" *ngIf="isSuperAdmin">Features</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'billing_settings']" *ngIf="isSuperAdmin">Fakturering</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'cashreg_settings']" *ngIf="isSuperAdmin">Kassaregister</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'staff_settings']" *ngIf="isSuperAdmin">Personalinställningar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'pos_settings']" *ngIf="isSuperAdmin">POS</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'resource_settings']" *ngIf="isSuperAdmin">Resurser</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'softpay_settings']" *ngIf="isSuperAdmin">Softpay Aktivering</a>
        <button mat-menu-item [matMenuTriggerFor]="venue" *ngIf="isSuperAdmin">Venue</button>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'voucher_settings']" *ngIf="isSuperAdmin">Voucher</a>
        <button mat-menu-item [matMenuTriggerFor]="debugMenu" *ngIf="isSuperAdmin">Debugging</button>
      </mat-menu>

      <mat-menu #sales="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'admission_settings']" *ngIf="!isVault">Biljettinställningar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'receipt_settings']" *ngIf="!isVault">Kvittoinställningar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'menu_settings']">Menyinställningar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'order_tags']" *ngIf="!isVault">Order-taggar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'pin_codes']" *ngIf="!isVault">PIN-koder</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'discount_settings']" *ngIf="!isVault">Rabatter</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'discount_programs']" *ngIf="!isVault">Rabattprogram</a>
      </mat-menu>

      <mat-menu #economy="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'payment_methods']" *ngIf="!isVault">Betalmetoder</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'icclasses']">IC-klasser</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'accounts']">Kontoplan/Z-rapport</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'cards']" *ngIf="!isVault">Kort</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'venue-customers']">Konton</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'loyalty_settings']" *ngIf="isSuperAdmin && !isVault">Lojalitetsprogram <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'sie_settings']" *ngIf="!isVault">SIE-inställningar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'venue_transactions']" *ngIf="!isVault">Cash in/out</a>
      </mat-menu>

      <mat-menu #hardware="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'devices']">Enheter</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'customer_displays']">Kunddisplayer</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'printers']">Skrivare</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'payment_terminals']">Terminaler</a>
      </mat-menu>

      <mat-menu #kitchen="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'bong_template']">Bongmall</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'kds_settings']">KDS</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'serving_settings']">Servering</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'booking']" *ngIf="isSuperAdmin">Bokning <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'slotting']">Slottider</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'workflow-editor']" *ngIf="isSuperAdmin">Workflow <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'forwarding']" *ngIf="isSuperAdmin">Forwarding <mat-icon>admin_panel_settings</mat-icon></a>
      </mat-menu>

      <mat-menu #hardware="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'devices']">Enheter</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'customer_displays']">Kunddisplayer</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'printers']">Skrivare</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'payment_terminals']">Terminaler</a>
      </mat-menu>

      <mat-menu #menuServices="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'booth_settings']" *ngIf="isSuperAdmin">Booth <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'foodcourt_settings']">Foodcourt</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'inhouse_settings']">Inhouse</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'kiosk_settings']" *ngIf="isSuperAdmin">Kioskläge <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'pickup_settings']">Pickup</a>
        <button mat-menu-item [matMenuTriggerFor]="roomservice" *ngIf="isSuperAdmin">Roomservice</button>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'roomservice_settings']" *ngIf="!isSuperAdmin">Roomservice</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'takeaway_settings']">Takeaway</a>
      </mat-menu>

      <mat-menu #debugMenu="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'filter-builder']">Filter builder</a>
        <a mat-menu-item [routerLink]="['/pos', venueId]" target="_blank">Breeze Fullscreen</a>
        <mat-divider></mat-divider>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'term-txs']">Term-TXs</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'event-list']">Old live events</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'venue-crons']">Venue crons</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'raw-config']">Configuration</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'skv']">Skatteverket</a>
      </mat-menu>

      <mat-menu #kitchen="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'bong_template']">Bongmall</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'kds_settings']">KDS</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'serving_settings']">Servering</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'booking']" *ngIf="isSuperAdmin">Bokning <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'slotting']">Slottider</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'workflow-editor']" *ngIf="isSuperAdmin">Workflow <mat-icon>admin_panel_settings</mat-icon></a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'forwarding']" *ngIf="isSuperAdmin">Forwarding <mat-icon>admin_panel_settings</mat-icon></a>
      </mat-menu>

      <mat-menu #venue="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'venue_settings']" *ngIf="isSuperAdmin">Venue inställningar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'branding_settings']" *ngIf="isSuperAdmin">Branding inställnignar</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'venue-info']" *ngIf="isSuperAdmin">Venue info</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'locale_settings']" *ngIf="isSuperAdmin">Lokalisering</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'opening-hours']" *ngIf="isSuperAdmin">Öppettider</a>
      </mat-menu>

      <div class="search-container" *ngIf="isSuperAdmin">
        <mat-icon class="search-icon">search</mat-icon>
        <input #searchInput matInput [matMenuTriggerFor]="searchMenu"
               (input)="search($event.target.value)"
               (focus)="handleFocus()"
               class="search-input">
      </div>

      <mat-menu #searchMenu="matMenu" class="search-results-menu">
        <ng-container *ngFor="let result of filteredPages">
          <mat-menu-item disabled style="font-weight: bold;">{{ result.title }}</mat-menu-item>
          <ng-container *ngFor="let match of result.matches">
            <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', result.routerLink]">
              {{ match.title }} {{ match.desc ? '- ' + match.desc : '' }}
            </a>
          </ng-container>
        </ng-container>
      </mat-menu>

      <mat-menu #roomservice="matMenu">
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'roomservice_settings']">Allmänt</a>
        <a mat-menu-item [routerLink]="['/venue', venueId, 'settings', 'roomservice_room_settings']" *ngIf="isSuperAdmin">Rumangivelse <mat-icon>admin_panel_settings</mat-icon></a>
      </mat-menu>

    </mat-toolbar>
  </p>
</div>
