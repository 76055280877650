import {Component, Inject, NgZone} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {VenueService} from "../../../services/venue.service";

interface DescriptionStyle {
  key: string;
  name: string;
  text: string;
}

@Component({
  selector: 'app-menu-magic-dialog',
  templateUrl: './menu-magic-dialog.component.html',
  styleUrls: ['./menu-magic-dialog.component.css']
})
export class MenuMagicDialogComponent {
  private venueId: number;
  name: string;
  descriptionStyles = null;
  selectedStyle: DescriptionStyle | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {venueId: number, name: string},
              public dialogRef: MatDialogRef<MenuMagicDialogComponent>, private state: MenuStateService, private ngZone: NgZone,
              private venueService: VenueService) {
    this.venueId = data.venueId;
    this.name = data.name;
  }

  private descriptionKeys = [
    'ingredients',
    'very_short_description',
    'short_description',
    'elegant_fancy_description',
    'modern_minimalist_description',
    'creative_playful_description',
    'traditional_descriptive_description',
    'healthy_nutritional_description'
  ];

  private descriptionTitles = new Map([
    ['ingredients', 'Ingredienslista'],
    ['very_short_description', 'Kort beskrivning'],
    ['short_description', 'Medium beskrivning'],
    ['elegant_fancy_description', 'Elegant och lyxig beskrivning'],
    ['modern_minimalist_description', 'Modern minimalistisk beskrivning'],
    ['creative_playful_description', 'Kreativ och lekfull beskrivning'],
    ['traditional_descriptive_description', 'Traditionell beskrivande text'],
    ['healthy_nutritional_description', 'Hälso- och näringsbeskrivning']
  ]);

  private createDescriptionStyles(res: Record<string, string>): DescriptionStyle[] {
    return this.descriptionKeys.map(key => ({
      key,
      name: this.descriptionTitles.get(key) || key,
      text: res[key] || ''
    }));
  };

  ngOnInit(): void {
    this.venueService.doMenuMagic(this.venueId, this.name).then( res => {
      this.descriptionStyles = this.createDescriptionStyles(res);
      console.log(res);
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    console.log('Selected Style:', this.selectedStyle);
    this.dialogRef.close(this.selectedStyle);
  }

}
