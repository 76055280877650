import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FireService} from "../../services/fire.service";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import {Order, OrderSummary} from "../../models/order";
import {MatSort, MatSortable} from "@angular/material/sort";
import {OrderService} from "../../services/order.service";
import {Subscription} from "rxjs";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit, OnDestroy {
  @Input() table: string;
  private orders: Order[];
  hideOffline = false;
  hideOld = true;
  venueId: number;
  selectedOrder: Order;
  summary: OrderSummary;
  selectedRow: Order | null = null;
  selectedJson: any;
  showJson: boolean = false;
  isSuperAdmin: boolean = false;

  displayedColumns = ["id", "table", "total_price", "state", "updated"];
  dataSource: MatTableDataSource<Order>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private orderObs: Subscription;

  constructor(private route: ActivatedRoute, private fire: FireService, private orderService: OrderService, private auth: AuthService) { }

  ngOnInit(): void {
    this.sort.sort(({ id: 'updated', start: 'desc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserve();
    });
    this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);

  }

  ngOnDestroy(): void {
    if (this.orderObs) {
      this.orderObs.unsubscribe();
    }
  }

  private beginObserve() {
    this.orderObs = this.fire.observeTableOrders(this.venueId, this.table).subscribe(orders => {
      this.orders = orders;
      this.refresh();
    });
  }

  private refresh() {
    this.dataSource = new MatTableDataSource( this.orders as Order[]);
    this.dataSource.sort = this.sort;
  }

  select(order: Order) {
    this.selectedRow = order;
    this.selectedJson = order;
    console.log(order);
    this.orderService.parseItems(order);
    this.summary = this.orderService.summarizeOrdersByTimeAndPhase([order], "", null);
    // this.selectedOrder = order;
  }
  trackById(item: Order): number{
    return item.order_number;
  }

  getUniqueTags(): string[] {
    if (!this.selectedJson?.items) return [];
    const allTags = this.selectedJson.items.flatMap(item => item.tags || []);
    return Array.from(new Set(allTags));
  }

  getOrderTags(): { tag: string; value: string }[] {
    if (!this.selectedJson?.order_tags) return [];
    try {
      return JSON.parse(this.selectedJson.order_tags);
    } catch (error) {
      return [];
    }
  }

}
