<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button routerLink="../../../fb-bots"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>{{pageTitle}}</span>
      <span class="spacer"></span>
      <!-- <app-wiki-info-link title="Lär dig mer" section="support" path="fb-bot"></app-wiki-info-link> -->
      <button mat-button (click)="save()">
        <mat-icon>save</mat-icon> Spara
      </button>
    </mat-toolbar>
  </p>
</div>

<app-data-form #dataform [schema]="dataFormSchema" [root]="botData" (save)="save()"></app-data-form>

<mat-divider></mat-divider>
<div *ngIf="newBot" class="info-text">
  Save fb-bot to unlock bot actions!
</div>

<div *ngIf="!newBot">
  <div class="action-buttons">
    <div style="padding-bottom: 21.5px;">
      <mat-card>
        <div>Hej <span ngNonBindable>{{user_first_name}}</span>, och välkommen till {{botData?.display_name}}. Tryck på
          kom igång nedan så hjälper vi dig vidare.</div>
      </mat-card>
    </div>

    <div style="padding-bottom: 21.5px;">
      <button mat-button (click)="executeBotAction(WHITELIST_DOMAINS)">Whitelist domain</button>
    </div>

    <div>
      <mat-form-field appearance="outline" class="set-form">
        <mat-label>Message</mat-label>
        <input matInput type="text" [(ngModel)]="welcomeMessage" placeholder="Welcome message">
      </mat-form-field>
      <button mat-button (click)="executeBotAction(SET_WELCOME_MESSAGE)" style="margin-left: 24px;">Set welcome
        message</button>
      <button mat-button (click)="executeBotAction(CONFIG_START_BUTTON)" style="margin-left: 24px;">Config a start
        button</button>
    </div>

    <div>
      <mat-form-field appearance="outline" class="set-form">
        <mat-label>Meny namn</mat-label>
        <input matInput type="text" [(ngModel)]="persistentMenuName" placeholder="Config persistent menu">
      </mat-form-field>
      <mat-radio-group class="radio" [(ngModel)]="persistentMenuType" style="margin-top: 8px;font-size: 16px;" color="primary">
        <mat-radio-button [value]="'type1b'">Shorter</mat-radio-button>
        <mat-radio-button [value]="'type1b_no_wait'">Shorter (no waitress)</mat-radio-button>
        <mat-radio-button [value]="'type_voucher'">Voucher</mat-radio-button>
      </mat-radio-group>
      <button mat-button (click)="executeBotAction(CONFIG_PERSISTENT_MENU, persistentMenuName, persistentMenuType)"
        style="margin-left: 24px;">Config persistent menu</button>
    </div>

    <div>{{actionResponseMessage}}</div>
  </div>
</div>

<!-- <app-wiki-display section="intern-support-wiki" path="fb-bot-editor" [style]="'wiki-doc'"></app-wiki-display> -->
