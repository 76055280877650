import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription, combineLatest, first, switchMap } from 'rxjs';
import { QueryMenuSalesDataRequest, QueryMenuSalesDataResponse, QueryMenuSalesRow } from 'src/app/models/reporting';
import { FireService } from 'src/app/services/fire.service';
import { VenueService } from 'src/app/services/venue.service';
import { ArrayUtils, HackUtils, LocalFileUtils } from 'src/app/utils/utils';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import Utils from 'src/app/common/utils';

@Component({
  selector: 'app-report-menu-sales',
  templateUrl: './report-menu-sales.component.html',
  styleUrls: ['./report-menu-sales.component.css']
})
export class ReportMenuSalesComponent implements OnInit, AfterViewInit, OnDestroy {
  private paramSub: Subscription;
  private query: any;
  private response: QueryMenuSalesDataResponse;
  title = "Försäljningstoppen";

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe,
              private fire: FireService, private dialog: MatDialog, private router: Router) {
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ["name", "item_count", "item_amount", "ic", "item"];

  @Input() venueId: string;
  venueIdNum: number;
  receiptKey: string;
  searchStartDate: Date;
  searchEndDate: Date;
  filter: string;
  filterList: string[] = [];
  dataSource = new MatTableDataSource<QueryMenuSalesRow>([]);
  noData = false;
  initData = true;
  isMobile: boolean;

  public config: any;
  public dropdownData: any[] = [];
  public selectedItem: string;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.sort.sort({ id: 'item_count', start: 'desc' } as MatSortable);
  }

  ngOnInit(): void {
    this.isMobile = this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    this.sort.sort(({ id: 'item_count', start: 'desc' }) as MatSortable);
    this.paramSub = combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]
    ).pipe(
      switchMap(([param, query]) => {
        const q = query.get("q");
        this.venueId = param.get("venue_id");
        this.venueIdNum = Number(this.venueId);
        this.query = q;
        const startDate = query.get("start_date");
        const endDate = query.get("end_date");
        const ic = query.get("ic");
  
        if (startDate) {
          this.searchStartDate = Utils.dateKeyToDate(startDate);
        } else {
          this.searchStartDate = moment().subtract(1, "month").toDate();
        }
        
        if (endDate) {
          this.searchEndDate = Utils.dateKeyToDate(endDate);
        } else {
          this.searchEndDate = moment().toDate();
        }

        if (ic) {
          this.selectedItem = ic;
        } else {
          this.selectedItem = '';
        }
        return this.fire.observeVenueConfig(this.venueIdNum).pipe(first());
      })).subscribe(cfg => {
        this.config = cfg;
        this.populateDropdownData();
        this.fetch();
      });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  fetch() {
    const request = this.buildRequest();
    if (this.selectedItem) {
      request.ic = this.trimString(this.selectedItem);
    }
    const queryParams: Params = {};
    if (this.searchStartDate) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.selectedItem) {
      queryParams.ic = this.selectedItem;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
    this.venueService.queryMenuSalesData(this.venueIdNum, request)
      .then(r => {
        if (r != null && r.rows != null && r.rows.length > 0) {
          this.dataSource.data = r.rows as QueryMenuSalesRow[];
          this.noData = false;
          this.response = r;
          this.initData = false;
        } else {
          this.dataSource.data = [];
          this.noData = true;
          this.response = null;
        }
      })
      .catch(error => {
        SimpleDialogComponent.showErr(this.dialog, error);
        console.error("Error fetching menu sales data:", error);
        this.dataSource.data = [];
        this.noData = true;
        this.response = null;
      });
  }

  private trimString(str: string): string {
    return str.trim();
  }

  private populateDropdownData() {
    if (this.config && this.config.ic) {
      const prioKeys = ['food', 'drink'];
      const icList = ArrayUtils.flattenSortIndentICTree(this.config.ic, [], 0, "\u00A0", prioKeys);
      this.dropdownData = icList.filter(ic => ic.trim());
    }
  }

  onDropdownChange(event: any) {
    if (event && event.value !== undefined) {
      this.selectedItem = event.value.trim();
      console.log("Selected item:", this.selectedItem);
    } else {
      console.error("Selected item is undefined");
    }
  }

  compareItems(item1: string, item2: string): boolean {
    return item1 && item2 ? item1.trim() === item2.trim() : item1 === item2;
  }

  private buildRequest() {
    const request = new QueryMenuSalesDataRequest();
    request.venue_id = this.venueId;
    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    return request;
  }

  clearFilters() {
    this.searchStartDate = undefined;
    this.searchEndDate = undefined;
    this.filter = undefined;
    this.selectedItem = undefined;
    this.filterList = [];
  }

  async export(format: string) {
    const data = this.buildDataArrayWithHeader();
    console.log(data);
    const config = await this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).toPromise();
    const dates = LocalFileUtils.createDateFileName(this.response.params.start_date, this.response.params.end_date);
    const filename = `top_sales_report_${config.venue.name.replace(/\\s/g, "_")}_${dates}.csv`;
    LocalFileUtils.createFileWithBOM(data, filename);
  }

  select(row: any) {
    console.log(row);
  }

  get canExport(): boolean {
    return this.response && this.response.rows && this.response.rows.length > 0;
  }

  private buildDataArrayWithHeader() {
    const header = [{ col: "name", title: "Artikel" }, { col: "item_count", title: "Antal sålda" }, { col: "item_amount", title: "Summa (inkl moms)" }, { col: "ic", title: "IC-klass" }];
    console.log("Respons: ", this.response.rows);
    return LocalFileUtils.compileDataIntoCSV(header, this.response.rows);
  }

  public searchByIC(ic: string) {
    const url = `/venue/${this.venueId}/reports/menu-sales`;
    this.navigateToReport(ic, url, 'ic');
  }

  public viewDailySales(item: string) {
    const url = `/venue/${this.venueId}/reports/daily`;
    this.navigateToReport(item, url, 'item');
  }

  public navigateToReport(value: string, url: string, searchType: string = 'item') {
    const queryParams: Params = {};
      if (this.searchStartDate != null) {
        queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
      }
      if (this.searchEndDate != null) {
        queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
      }
      if (this.selectedItem && searchType === 'item') {
        queryParams.ic = this.selectedItem;
      }

      if (value && searchType) {
        queryParams[searchType === 'item' ? 'item' : 'ic'] = value;
      }
      
    this.router.navigate([url], {queryParams, queryParamsHandling: 'merge'});
  }

}
