<div class="order-container">
  <div class="generic-master">
    <table mat-table [dataSource]="dataSource" matSort style="padding-bottom: 24px">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="table">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Table </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.table}} </td>
      </ng-container>

      <ng-container matColumnDef="total_price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.total_price}} </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.state}} </td>
      </ng-container>

      <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.updated.toDate() |date:'medium'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; trackBy: trackById;" (click)="select(row)" [ngClass]="{'selected': selectedRow?.order_number === row?.order_number}"></tr>
    </table>
  </div>
  <div *ngIf="summary" class="order-detail-container">
    <div *ngIf="summary">
      <div *ngIf="selectedJson && isSuperAdmin">
        <div class="info-container">
          <div><strong>Order#: </strong> {{ selectedJson?.order_number }}</div>
          <div><strong>Bord: </strong>{{ selectedJson?.table }}</div>
          <div><strong>DT: </strong>{{ selectedJson?.deliver_type }}</div>
          <div><strong>Area: </strong>{{ selectedJson?.table_area }}</div>
        </div>
        <div style="border-bottom: 1px solid #ccc; width: 100%; margin: 4px 0;"></div>
        <div aria-label="Item tags">
          <div class="chip-button-container">
            <button *ngFor="let tag of getOrderTags()" class="chip-button" style="background-color: #f5a623">{{ tag.tag }} {{ tag.value}}</button>
          </div>
        </div>
        <div aria-label="Item tags">
          <div class="chip-button-container">
            <button *ngFor="let tag of getUniqueTags()" class="chip-button">{{ tag }}</button>
          </div>
        </div>
      </div>
      <div *ngFor="let row of summary.rows">
        <div *ngIf="row.title" class="summary-title">{{row.title.text}}</div>
        <div *ngIf="row.divider" class="summary-divider"><div class="summary-divider-line"><div></div></div><div>{{row.divider.text}}</div><div class="summary-divider-line"><div></div></div></div>
        <div *ngIf="row.item" class="summary-item">
          <div style="margin-right: 16px;font-weight: bold;">{{row.item.count()}}</div>
          <div>
            <div>{{row.item.name()}}</div>
            <div *ngIf="row.item.customized()" class="tweak">{{row.item.customized()}}</div>
            <div *ngIf="row.item.comment()" class="tweak">{{row.item.comment()}}</div>
          </div>
          <div style="margin-left: auto;" [ngStyle]="{'color':row.item.priceColor(),'text-decoration': row.item.textDecoration()}">{{row.item.priceFormatted()}}</div>
        </div>
      </div>
      <div class="fp-footer">
        <div class="total">{{summary.price.priceFormatted()}}</div>
        <div class="vat">moms: {{summary.price.vatFormatted()}}</div>
      </div>
      <div class="selected-order-json" *ngIf="isSuperAdmin">
        <button mat-flat-button (click)="showJson = !showJson" color="primary" style="margin: 6px;">
          {{ showJson ? "Dölj" : "Visa mer" }}
        </button>
        <div *ngIf="showJson">
          <ngx-json-viewer [json]="selectedJson" [hoverPreviewEnabled]="true"></ngx-json-viewer>
        </div>
      </div>
    </div>
  </div>
</div>
