<div class="prepaid-container">

<!--  <div class="search-fields-root">-->
<!--    <div class="search-fields">-->
<!--      <mat-form-field appearance="outline">-->
<!--        <mat-label>Day or start date</mat-label>-->
<!--        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">-->
<!--        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #startPicker></mat-datepicker>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field appearance="outline">-->
<!--        <mat-label>End date</mat-label>-->
<!--        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">-->
<!--        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #endPicker></mat-datepicker>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field appearance="outline">-->
<!--        <input matInput [(ngModel)]="filter" placeholder="Filter">-->
<!--        <button matSuffix mat-icon-button aria-label="Clear" (click)="addFilter()">-->
<!--          <mat-icon>add_circle_outline</mat-icon>-->
<!--        </button>-->
<!--      </mat-form-field>-->
<!--      <button mat-flat-button class="search-button" (click)="fetchReceipts()" color="primary">SEARCH</button>-->

<!--      <button mat-stroked-button class="search-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>-->
<!--      <mat-menu #menuHandleSection="matMenu">-->
<!--        <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>-->
<!--        <mat-divider></mat-divider>-->
<!--        <button mat-menu-item [matMenuTriggerFor]="menuExport" *ngIf="fullCount">Exportera {{fullCount}} kvitton</button>-->
<!--      </mat-menu>-->
<!--      <mat-menu #menuExport="matMenu">-->
<!--        &lt;!&ndash;        <button mat-menu-item (click)="export('csv')">CSV-format</button>&ndash;&gt;-->
<!--        &lt;!&ndash;        <mat-divider></mat-divider>&ndash;&gt;-->
<!--        <button mat-menu-item (click)="export('hogia')" *ngIf="fullCount">Fakturaunderlag för Hogia</button>-->
<!--      </mat-menu>-->

<!--    </div>-->
<!--  </div>-->

  <div style="padding-bottom: 16px">
    <mat-toolbar>
      <span>Rabatt- / Presentkort</span>
      <span class="spacer"></span>
<!--      <app-wiki-info-link title="Lär dig mer" section="support" path="device"></app-wiki-info-link>-->
      <button mat-button (click)="exportCSV()">
        <mat-icon>download</mat-icon> Exportera saldolista (csv)
      </button>
      <button mat-button (click)="refresh()">
        <mat-icon>refresh</mat-icon> Uppdatera
      </button>
      <button mat-button [disabled]="!areAnySelected()" (click)="voidPrepaidCards()" *ngIf="hasPerm('admins')">
        <mat-icon>delete</mat-icon>Töm valda {{ getSelectedCount() }}</button>
    </mat-toolbar>
  </div>
  <div class="generic-mastdet">
    <div class="generic-master">
      <div class="search-fields-root">
        <div class="search-fields">
          <mat-form-field appearance="outline">
            <mat-label>Filter</mat-label>
            <input matInput [(ngModel)]="filter" (keyup)="applyFilter(filter)" placeholder="Filter" #input>
            <button matSuffix mat-icon-button aria-label="Clear" (click)="clearFilters()">
              <mat-icon>clear</mat-icon>
            </button>
          </mat-form-field>
            <mat-slide-toggle [(ngModel)]="hideEmpty" (change)="cardFilterChanged()" style="margin-left: 16px;" color="primary">Dölj tomma</mat-slide-toggle>
            <mat-slide-toggle [(ngModel)]="hideValid" (change)="cardFilterChanged()" style="margin-left: 16px;" color="primary">Dölj giltliga</mat-slide-toggle>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" matSortActive="created" matSortDirection="desc" matSortDisableClear="true">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          color="primary"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          color="primary"
                          (change)="$event ? selection.toggle(row) : null"
                          [disabled]="row.balance_cent === 0"
                          [checked]="isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="identity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Kort #</th>
          <td mat-cell *matCellDef="let element">{{ element.identity }}</td>
        </ng-container>

        <ng-container matColumnDef="card_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Kort</th>
          <td mat-cell *matCellDef="let element"> {{ element.card_name }} </td>
        </ng-container>

        <ng-container matColumnDef="card_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
          <td mat-cell *matCellDef="let element"> {{ element.card_id }} </td>
        </ng-container>

        <ng-container matColumnDef="balance_cent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SEK</th>
          <td mat-cell *matCellDef="let element"> {{ element.balance_cent | moneySuff: 'cent': 'kr'}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
          <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
        </ng-container>

        <ng-container matColumnDef="discount_program_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Rabatt</th>
          <td mat-cell *matCellDef="let element"> {{ element.discount_program_name }} </td>
        </ng-container>

        <ng-container matColumnDef="valid_until_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Giltigt till</th>
          <td mat-cell *matCellDef="let element">{{ element.valid_until_date |date:'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
          <td mat-cell *matCellDef="let element">{{ element.created |date:'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="method">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> method</th>
          <td mat-cell *matCellDef="let element"> {{ element.method }} </td>
        </ng-container>

        <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Uppdaterad</th>
          <td mat-cell *matCellDef="let element"> {{ element.updated |date:'medium' }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [class.selected-row]="selectedRowKey === row.key"></tr>
      </table>

    </div>
    <div class="generic-detail" *ngIf="selectedRowKey">
      <div class="lr-detail-container">
        <div class="toolbar">
          <span><mat-icon>credit_card</mat-icon></span>
          <span style="font-weight: bold;">{{cardName}}</span>
          <button mat-button (click)="selectedRowKey = null">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <app-prepaid-details [venueId]="venueId" [key]="selectedRowKey" (refresh)="refresh()"></app-prepaid-details>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="overlay">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
</div>
