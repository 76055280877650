export const pageBrandingSettings = {
  title: { sv: "Branding inställningar" },
  page_settings: {
    root_path: "branding",
  },
  desc: { sv: "Inställningar för brand" },
  properties: [
    { key: "theme", title: { sv: "Bastema i collins" }, desc: { sv: "Välj vilket tema som skall användas i Collins" }, type: "enum", optional: false,
      values: [
        { key: "light", title: { sv: "Ljust" } },
        { key: "dark", title: { sv: "Mörkt" } },
      ]
    },
    { key: "font", title: { sv: "Typsnitt" }, desc: { sv: "Typsnitt för brödtext" }, type: "enum", optional: false,
      values: [
        { key: "outfit", title: { sv: "Outfit" }},
        { key: "inter", title: { sv: "Inter" }},
        { key: "source-serif", title: { sv: "Source Serif" }},
      ]
    },
    { key: "primary_color", title: { sv: "Primärfärg" }, desc: { sv: "Anges på formatet #225577" }, type: "string", optional: true },
    { key: "contrast_color", title: { sv: "Kontrastfärg" }, desc: { sv: "Anges på formatet #225577" }, type: "string", optional: true },
    { key: "custom_font", title: { sv: "Egen font" }, desc: { sv: "Ange fulla sökvägen till typsnittet" }, type: "string", optional: true },
    { key: "logo_file", title: { sv: "Logo" }, desc: { sv: "Dynamisk bildlänk, hämtas i bildbanken" }, type: "string", optional: true },
    { key: "logo_badge_file", title: { sv: "Logo badge" }, desc: { sv: "Dynamisk bildlänk, hämtas i bildbanken" }, type: "string", optional: true },
    { key: "background_file", title: { sv: "Bakgrund" }, desc: { sv: "Dynamisk bildlänk, hämtas i bildbanken" }, type: "string", optional: true },
  ]
};
