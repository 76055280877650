<div class="generic-mastdet">
  <div class="generic-master">
    <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" matSortActive="pay_date" matSortDirection="desc" matSortDisableClear="true">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        color="primary"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row"  (click)="$event.stopPropagation(); toggleSelection(row)">
          <mat-checkbox (click)="$event.stopPropagation()"
                        color="primary"
                        (change)="$event ? selection.toggle(row) : null"
                        [disabled]="row.balance_cent === 0"
                        [checked]="isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial Number</th>
        <td mat-cell *matCellDef="let element">{{ element.serial_number }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount</th>
        <td mat-cell *matCellDef="let element"> {{ element.amount | moneySuff : null : "kr"}} </td>
      </ng-container>

      <ng-container matColumnDef="sales">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sales</th>
        <td mat-cell *matCellDef="let element"> {{ element.sales | moneySuff : null : "kr"}} </td>
      </ng-container>

      <ng-container matColumnDef="tip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip</th>
        <td mat-cell *matCellDef="let element"> {{ element.tip | moneySuff : null : "kr"}} </td>
      </ng-container>

      <ng-container matColumnDef="vat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> vat</th>
        <td mat-cell *matCellDef="let element"> {{ element.vat | number : '1.2' }} </td>
      </ng-container>

      <ng-container matColumnDef="staff_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Personal</th>
        <td mat-cell *matCellDef="let element"> {{ element.staff_name }} </td>
      </ng-container>

      <ng-container matColumnDef="table">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>table</th>
        <td mat-cell *matCellDef="let element">{{ element.table }}</td>
      </ng-container>

      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> method</th>
        <td mat-cell *matCellDef="let element"> {{ element.method }} </td>
      </ng-container>

      <ng-container matColumnDef="pay_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
        <td mat-cell
            *matCellDef="let element"> {{ element.pay_date |date:'medium' }} </td>
      </ng-container>

      <ng-container matColumnDef="booking_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lev.datum</th>
        <td mat-cell
            *matCellDef="let element"> {{ element.booking_date |date:'medium' }} </td>
      </ng-container>

      <ng-container [matColumnDef]="customColumn.key" *ngFor="let customColumn of customColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{customColumn.title}}</th>
        <td mat-cell *matCellDef="let element"> {{ element[customColumn.key] }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [class.selected-row]="selectedRow === row.receipt_id"></tr>
    </table>
  </div>

</div>
